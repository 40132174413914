import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import TrustedIcon from '../../assests/images/trusted.png';
import CommonModal from './CommonModal.js';
import one from '../../assests/images/one.png';
import two from '../../assests/images/two.png';
import arrow from '../../assests/icons/arrow.png';
import language from '../../assests/images/language.png';

import './Demo.scss';

export default function LandingModal({
  open, buttonClick,
}) {
  const heading = "Let's Verify Your Identity";
  const subHeading = 'The following steps have to be completed, to verify your identity';

  return (
    <CommonModal open={open} heading={heading} subHeading={subHeading}>
      <div id="landing_modal__ordered_list">
        <p id="landing_modal__list_item">
          <img id="landing_modal__number_bullet" src={one} alt="one" />
          ID Card Capture
        </p>
        <p id="landing_modal__list_item">
          <img id="landing_modal__number_bullet" src={two} alt="two" />
          Selfie Capture
        </p>
      </div>
      <Button
        variant="contained"
        onClick={buttonClick}
        id="landing_modal__get_started"
      >
        Get Started
        <img src={arrow} alt="arrow" id="landing_modal__get_started_arrow" />
      </Button>
      <div id="landing_modal__footer">
        <div id="landing_modal__footer_item">
          <img
            src={language}
            alt="language"
            id="landing_modal__footer_language_icon"
          />
          <p>English</p>
        </div>
        <div id="landing_modal__footer_item">
          <img
            src={TrustedIcon}
            alt="Trust"
            id="landing_modal__footer_powered_by_icon"
          />
          <p>Powered by HyperVerge</p>
        </div>
      </div>
    </CommonModal>
  );
}

LandingModal.propTypes = {
  open: PropTypes.bool.isRequired,
  buttonClick: PropTypes.func.isRequired,
};
