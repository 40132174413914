import React from 'react';
import PropTypes from 'prop-types';

function ActionOption({ icon, label }) {
  return (
    <div style={{ display: 'flex' }}>
      <img className="actions_icon" src={icon} alt="" />
      <span>{label}</span>
    </div>
  );
}

ActionOption.propTypes = {
  icon: PropTypes.node.isRequired,
  label: PropTypes.string.isRequired,
};

export default ActionOption;
