import React from 'react';
import PropTypes from 'prop-types';

function Title({ text }) {
  if (!text) return null;

  return <p data-hj-suppress className="generic_card_v2__title">{text}</p>;
}

Title.propTypes = {
  text: PropTypes.string.isRequired,
};

export default Title;
