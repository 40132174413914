import axios from 'axios';
import { get, isArray, isObject } from 'lodash';
import * as Sentry from '@sentry/react';
import { executeStringFunc } from '../ReviewTagsCard/utils';

const reviewRequest = async (url, body, config) => {
  try {
    const { data } = await axios.post(url, body, config);
    return data;
  } catch (error) {
    Sentry.captureException(error);
    throw new Error(
      JSON.stringify({
        statusCode: get(error, 'response.status', 500),
        errorMessage: get(error, 'response.data.result.error', 'Server Error'),
      }),
    );
  }
};

const validateTagsConfig = (tagConfig, state) => {
  const validatedConfig = [];
  try {
    Object.entries(tagConfig).forEach(([moduleId, moduleReviewTags]) => {
      Object.entries(moduleReviewTags).forEach(([requestId, { tagGroups }]) => {
        tagGroups.forEach(({
          url, selectedValue, metaData, validation,
        }) => {
          if (validation && isArray(validation)) {
            validation.forEach(({ condition, errorMessage }) => {
              if (executeStringFunc({
                value: selectedValue, state, moduleId, requestId,
              }, condition)) {
                throw new Error(errorMessage);
              }
            });
          }
          if (url && selectedValue) {
            validatedConfig.push({
              url,
              metaData,
              selectedValue,
            });
          }
        });
      });
    });
  } catch (error) {
    Sentry.captureException(error);
    return { error };
  }
  return { validatedConfig };
};

export const buildRequestsForReview = (
  reviewTagsConfig,
  hitsReviewTagConfig,
  appState,
) => {
  const {
    validatedConfig: validatedReviewConfig,
    error: reviewTagsError,
  } = validateTagsConfig(reviewTagsConfig, appState);
  if (reviewTagsError) {
    return { error: reviewTagsError };
  }
  const {
    validatedConfig: validatedHitsReviewConfig,
    error: hitsReviewTagsError,
  } = validateTagsConfig(hitsReviewTagConfig, appState);
  if (hitsReviewTagsError) {
    return { error: hitsReviewTagsError };
  }

  // Transform hits value from object to array of objects
  const transformedHitsValidatedReviewConfig = validatedHitsReviewConfig.map(
    ({ selectedValue, ...rest }) => {
      let updatedSelectedVal = selectedValue;
      if (isObject(selectedValue)) {
        updatedSelectedVal = Object
          .entries(selectedValue).map(([key, value]) => ({ hitId: key, value }));
      }
      return {
        selectedValue: updatedSelectedVal,
        ...rest,
      };
    },
  );

  return {
    requests: [...validatedReviewConfig, ...transformedHitsValidatedReviewConfig],
  };
};

export const makeRequestsForReview = async (requests, commonHeaders = {}) => {
  const successResponseList = [];
  const failureResponseList = [];
  const results = await Promise.allSettled(
    requests.map(({ url, metaData, selectedValue }) => reviewRequest(
      url,
      {
        ...metaData,
        value: selectedValue,
      },
      {
        headers: commonHeaders,
      },
    )),
  );
  results.forEach(({ status: state, value, reason }) => {
    if (state === 'fulfilled') {
      const successMessage = get(value, 'result.message');
      successResponseList.push({ message: successMessage });
    } else {
      const { message } = reason;
      const { errorMessage } = JSON.parse(message);
      failureResponseList.push({ message: errorMessage });
    }
  });
  return { successResponseList, failureResponseList };
};
