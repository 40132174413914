import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import * as Sentry from '@sentry/react';
import useShowErrorAlert from '../../utils/lib';
import AlertCode from '../../constants/alertCodes';
import errorCode from '../../constants/errorCode';

function TermAndConditionsClickwrap({ userEmail }) {
  const showErrorAlert = useShowErrorAlert();
  const handleClickwrapAgreement = async () => {
    try {
      await axios({
        method: 'PUT',
        url: `${process.env.REACT_APP_SERVER_URL}/api/v1/user/nda`,
        headers: {},
        data: {
          agreed: true,
        },
      });
    } catch (error) {
      Sentry.captureException(`${errorCode.NDA_SAVE_ERROR} - ${error}`, {
        extra: {
          errorMessage: AlertCode.SET_NDA_AGREEMENT,
        },
      });
      showErrorAlert({ error, message: AlertCode.SET_NDA_AGREEMENT });
    }
  };

  React.useLayoutEffect(() => {
    if (!process.env.REACT_APP_DISABLE_CLICKWRAP || (process.env.REACT_APP_DISABLE_CLICKWRAP && process.env.REACT_APP_DISABLE_CLICKWRAP !== 'true')) {
      window.docuSignClick.Clickwrap.render({
        environment: 'https://www.docusign.net',
        accountId: process.env.REACT_APP_DOCUSIGN_ACCOUNT_ID,
        clickwrapId: process.env.REACT_APP_DOCUSIGN_CLICKWRAP_ID,
        clientUserId: userEmail,
        documentData: {
          email: userEmail,
          date: `${new Date()}`,
        },
        onAgreed() {
          handleClickwrapAgreement(userEmail);
        },
      }, '#ds-clickwrap');
    }
  }, []);

  return <div id="ds-clickwrap" />;
}

export default TermAndConditionsClickwrap;

TermAndConditionsClickwrap.propTypes = {
  userEmail: PropTypes.string.isRequired,
};
