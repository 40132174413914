/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';

import Generator from './Generator';

function Body({ bodyLayout, availableData }) {
  return (
    <div className="card_type_five__content__body">
      <Generator bodyItem={bodyLayout} availableData={availableData} />
    </div>
  );
}

Body.propTypes = {
  bodyLayout: PropTypes.object.isRequired,
  availableData: PropTypes.object.isRequired,
};

export default Body;
