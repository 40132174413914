/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
import { useEffect } from 'react';
import Hotjar from '@hotjar/browser';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { updateFreshChatInitialised, updateHotjarInitialized } from '../reducers/initialize';

const freshChatBlacklistRoutes = ['/demo', '/verify', '/record', '/view'];

const useFCInitScript = () => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.user.auth);
  const location = useLocation();

  function initFreshChat() {
    if (window.fcWidget) {
      window.fcWidget.init({
        token: process.env.REACT_APP_FRESHCHAT_TOKEN,
        host: 'https://wchat.in.freshchat.com',
        siteId: process.env.REACT_APP_FRESHCHAT_SITE_ID || 'kyc_dashboard',
      });
      if (freshChatBlacklistRoutes.includes(location.pathname)) {
        window.fcWidget.hide();
      }
    }
  }

  function initialize(i, t) {
    let e;
    if (i.getElementById(t)) {
      initFreshChat();
    } else {
      e = i.createElement('script');
      e.id = t;
      e.async = !0;
      e.src = 'https://wchat.freshchat.com/js/widget.js';
      e.onload = () => {
        initFreshChat();
        dispatch(updateFreshChatInitialised());
      };
      i.head.appendChild(e);
    }
  }

  function initiateCall() {
    initialize(document, 'freshchat-js-sdk');
  }
  useEffect(() => {
    if (window.fcWidget) {
      dispatch(updateFreshChatInitialised());
    } else if (window.addEventListener) {
      window.addEventListener('DOMContentLoaded', initiateCall(), !1);
    } else {
      window.attachEvent('load', initiateCall, !1);
    }
  }, [auth]);

  useEffect(() => {
    if (freshChatBlacklistRoutes.includes(location.pathname)) {
      window.fcWidget?.hide();
    } else {
      window.fcWidget?.show();
    }
  }, [location]);
};

const useHJInitScript = () => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.user.auth);

  useEffect(() => {
    const hotjarSiteId = process.env.REACT_APP_HOTJAR_ID;
    const hotjarVersion = 6;
    Hotjar.init(hotjarSiteId, hotjarVersion);
    dispatch(updateHotjarInitialized());
  }, [auth]);
};

export { useFCInitScript, useHJInitScript };
