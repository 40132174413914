import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import LeftArrowIcon from '../../../../assests/icons/leftArrow08.svg';
import RightArrowIcon from '../../../../assests/icons/rightArrow.svg';
import ZoomInIcon from '../../../../assests/icons/zoomIn.svg';
import ZoomOutIcon from '../../../../assests/icons/zoomOut.svg';
import RotateLeftIcon from '../../../../assests/icons/rotateLeft.svg';
import RotateRightIcon from '../../../../assests/icons/rotateRight.svg';
import ImagePlaceholder from '../../../../assests/images/imagePlaceholder.png';
import { getImageRotationTransform, getImageScaleTransform } from '../imageUtils';

import '../Image.scss';

const defaultRotationAngleStep = 90;

function ImageViewer({
  id,
  images,
  defaultImageIndex,
  closeViewerHandler,
  imageMaxZoomScale,
  imageMinZoomScale,
  imageScaleStepSize,
}) {
  const viewerImageRef = useRef(null);
  const viewerImageScaleRef = useRef(null);
  const viewerImageContainerRef = useRef(null);
  const viewerControlRef = useRef(null);
  const [imageIndex, setImageIndex] = useState(defaultImageIndex);
  const [imageRotationAngle, setImageRotationAngle] = useState(0);
  const [imageScaleSize, setImageScaleSize] = useState(1);
  const [aspectRatio, setAspectRatio] = useState(null);

  const reset = () => {
    setImageScaleSize(1);
    setImageRotationAngle(0);
  };

  const nextImage = () => {
    if (imageIndex === images.length - 1) return;
    reset();
    setImageIndex(imageIndex + 1);
  };

  const previousImage = () => {
    if (imageIndex === 0) return;
    reset();
    setImageIndex(imageIndex - 1);
  };

  const rotateRight = () => {
    const rotateRightAngle = imageRotationAngle === 360
      ? 90
      : imageRotationAngle + defaultRotationAngleStep;
    setImageRotationAngle(rotateRightAngle);
  };

  const rotateLeft = () => {
    const rotateLefttAngle = imageRotationAngle === -360
      ? -90
      : imageRotationAngle - defaultRotationAngleStep;
    setImageRotationAngle(rotateLefttAngle);
  };

  const zoomIn = () => {
    if (imageScaleSize >= imageMaxZoomScale) {
      return;
    }
    setImageScaleSize(imageScaleSize + imageScaleStepSize);
  };

  const zoomOut = () => {
    if (imageScaleSize <= imageMinZoomScale) {
      return;
    }
    setImageScaleSize(imageScaleSize - imageScaleStepSize);
  };

  const handleMouseClick = (event) => {
    if (
      viewerControlRef.current
      && event.target !== viewerControlRef.current
      && !viewerControlRef.current.contains(event.target)
      && viewerImageRef.current
      && event.target !== viewerImageRef.current
      && !viewerImageRef.current.contains(event.target)
    ) {
      closeViewerHandler();
    }
  };

  const onImageLoadError = (e) => {
    e.target.src = ImagePlaceholder;
  };
  const keyBoardDownClickListener = (e) => {
    const { key } = e;
    e.preventDefault();
    switch (key) {
      case 'ArrowRight':
        nextImage();
        break;
      case 'ArrowLeft':
        previousImage();
        break;
      case 'Escape':
        closeViewerHandler();
        break;
      default:
    }
  };

  useEffect(() => {
    document.removeEventListener('keydown', keyBoardDownClickListener);
    document.addEventListener('keydown', keyBoardDownClickListener);
    return () => {
      document.removeEventListener('keydown', keyBoardDownClickListener);
    };
    // When imageIndex changes, the listener still has the previous value of the imageIndex.
    // To update the value inside the listener, we reattach it
  }, [imageIndex]);

  useEffect(() => {
    document.addEventListener('mousedown', handleMouseClick);
    return () => {
      document.removeEventListener('mousedown', handleMouseClick);
    };
  }, []);

  // Try to simplify logic below
  useEffect(() => {
    const isVerticalImage = aspectRatio < 1;
    // Translate image if overflows container to avoid cutting off
    if (viewerImageScaleRef.current && viewerImageContainerRef.current) {
      viewerImageScaleRef.current.style.transform = getImageScaleTransform(
        viewerImageContainerRef,
        viewerImageScaleRef,
        imageRotationAngle,
        imageScaleSize,
        isVerticalImage,
      );
    }
    if (viewerImageRef.current) {
      const { transform, transformOrigin } = getImageRotationTransform(
        viewerImageRef,
        imageRotationAngle,
        isVerticalImage,
      );
      viewerImageRef.current.style.transformOrigin = transformOrigin;
      viewerImageRef.current.style.transform = transform;
    }
  }, [imageScaleSize, imageRotationAngle]);

  useEffect(() => {
    if (viewerImageRef.current) {
      setAspectRatio(
        viewerImageRef.current.width / viewerImageRef.current.height,
      );
    }
  }, [imageIndex]);

  if (!images || !images.length) return '';

  return (
    <div data-hj-suppress id={id} className="slideshow__container">
      <div
        className="slideshow__image__container"
        ref={viewerImageContainerRef}
      >
        <div className="slideshow__image__header">
          {images[imageIndex].label}
        </div>
        <div className="slideshow__image">
          <div className="slideshow__image__scale" ref={viewerImageScaleRef}>
            <img src={images[imageIndex].src} alt="" ref={viewerImageRef} onError={onImageLoadError} />
          </div>
        </div>
      </div>
      <div className="slideshow__control__container">
        <div className="slideshow__control" ref={viewerControlRef}>
          <div className="zoomIn" onClick={zoomIn} aria-hidden>
            <img src={ZoomInIcon} alt="" />
          </div>
          <div className="zoomOut" onClick={zoomOut} aria-hidden>
            <img src={ZoomOutIcon} alt="" />
          </div>
          <div className="rotateLeft" onClick={rotateLeft} aria-hidden>
            <img src={RotateLeftIcon} alt="" />
          </div>
          <div className="rotateRight" onClick={rotateRight} aria-hidden>
            <img src={RotateRightIcon} alt="" />
          </div>
          <div className="line" />
          <div className="reset" onClick={reset} aria-hidden>
            Reset
          </div>
          <div className="line" />
          <div
            className={`previousImage ${imageIndex === 0 ? 'disabled' : ''}`}
            onClick={previousImage}
            aria-hidden
          >
            <img src={LeftArrowIcon} alt="" />
          </div>
          <div className="imageIndex">
            {imageIndex + 1}
            /
            {images.length}
          </div>
          <div
            className={`nextImage ${
              imageIndex + 1 === images.length ? 'disabled' : ''
            }`}
            onClick={nextImage}
            aria-hidden
          >
            <img src={RightArrowIcon} alt="" />
          </div>
          <div className="line" />
          <div className="close" onClick={closeViewerHandler} aria-hidden>
            Close
          </div>
        </div>
      </div>
    </div>
  );
}

ImageViewer.propTypes = {
  id: PropTypes.string.isRequired,
  images: PropTypes.array.isRequired,
  defaultImageIndex: PropTypes.number.isRequired,
  closeViewerHandler: PropTypes.func.isRequired,
  imageMaxZoomScale: PropTypes.number.isRequired,
  imageMinZoomScale: PropTypes.number.isRequired,
  imageScaleStepSize: PropTypes.number.isRequired,
};

export default ImageViewer;
