import React from 'react';
import noModule from '../../assests/images/NoModules.svg';
import './Details.scss';

const EmptyRecord = () => (
  <div id="details__container">
    <div id="details__empty_container">
      <img src={noModule} alt="no-module" />
      <div id="details__empty_info">
        <p id="details__empty_info_main">Nothing to see here!</p>
        <p id="details__empty_info_sub">User has not completed any step in this journey</p>
      </div>
    </div>
  </div>
);

export default EmptyRecord;
