import { get } from 'lodash';
import React, { useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { Link, useNavigate } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { startEmailOtpAPI } from '../../../../api/mfa';
import useShowErrorAlert from '../../../../utils/lib';
import CustomButton from '../../../Common/Buttons/CustomButton';
import MFABody from '../Common/MFABody';
import MFAFooter from '../Common/MFAFooter';
import MFAHeader from '../Common/MFAHeader';

const recaptchaKey = process.env.REACT_APP_RECAPTCHA_KEY;

function EmailOtpMFA() {
  const [captcha, setCaptcha] = useState('');

  const navigate = useNavigate();
  const showErrorAlert = useShowErrorAlert();

  const handleCaptchaComplete = (value) => {
    setCaptcha(value);
  };

  const handleCaptchaExpired = () => {
    setCaptcha('');
  };

  const sendOtp = async () => {
    if (!captcha) return;
    try {
      await startEmailOtpAPI({ captcha });
      navigate('/mfa/email/otp/verify', { state: { isMFARegistered: true } });
    } catch (err) {
      const code = get(err, 'response.data.code');
      if (code === 'mfa/otp-rate-limit') {
        showErrorAlert({ message: 'Try again after sometime' });
        return;
      }
      if (code === 'mfa/invalid-token' || code === 'mfa/expired-token') {
        showErrorAlert({ message: 'MFA Session expired' });
        return;
      }
      showErrorAlert({ message: 'Server Error' });
      Sentry.captureException(`${code} - Error in send OTP`, {
        extra: {
          errorMessage: 'Error in send OTP',
        },
      });
    }
  };

  return (
    <div className="MFA_common_card__container">
      <MFAHeader />
      <MFABody>
        <p>We will be sending an OTP to your registered email</p>
        {/* Note to dev -  Repatcha will not refresh when React.strictMode. works in prod build */}
        <ReCAPTCHA
          data-hj-suppress
          sitekey={recaptchaKey}
          onChange={handleCaptchaComplete}
          onExpired={handleCaptchaExpired}
        />
        <p className="MFA_resend">
          <Link to="/mfa/options">
            Try another method
          </Link>
        </p>
      </MFABody>
      <MFAFooter>
        <CustomButton theme="purple" id="MFA_email_otp__button" onClick={sendOtp} disabled={!captcha}>
          Send OTP to email
        </CustomButton>
      </MFAFooter>
    </div>
  );
}

export default EmailOtpMFA;
