/* eslint-disable jsx-a11y/anchor-is-valid */
import { set } from 'lodash';
import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import { useDispatch, useSelector } from 'react-redux';
import * as Sentry from '@sentry/react';
import {
  updateWorkflowList,
} from '../../reducers/user';
import useShowErrorAlert, { useFormatAnalyticsData, useFormatPerformanceAnalyticsData } from '../../utils/lib';
import AlertCode from '../../constants/alertCodes';
import './Main.scss';
import arrow from '../../assests/icons/arrowSVG.svg';
import rudderstackEvents from '../../constants/rudderstackEventNames';
import getWorkflows from '../../api/workflow';
import DemoCard from './Home/DemoCard';
import VideoKycCard from './Home/VideoKycCard';
import { DOCUMENTATION_PORTAL_URL, PERFORMANCE_METRIC_EVENTS } from '../../config';
import authorisedProductsMapping from '../../constants/authorisedProductsMapping';
import errorCode from '../../constants/errorCode';
import { isNumber } from '../../utils/helpers';

export default function Home() {
  const dispatch = useDispatch();
  const formatAnalyticsData = useFormatAnalyticsData();
  const formatPerformanceAnalyticsData = useFormatPerformanceAnalyticsData();
  const showErrorAlert = useShowErrorAlert();

  const currentAppId = useSelector((state) => state.user.credState?.current?.appId);
  const docToken = useSelector((state) => state.onboard.docToken);
  const email = useSelector((state) => state.user.email);
  const clientId = useSelector((state) => state.user.clientId);
  const authorizedProducts = useSelector((state) => state.user.authorizedProducts);
  const [isRudderStackDataSent, setIsRudderStackDataSent] = useState(false);

  const fetchWorkflows = async () => {
    const worklowFetchStartTime = performance.now();
    const eventObj = {
      APIEndPoint: 'api/v1/workflow',
      metaData: {
        appId: currentAppId,
      },
    };
    try {
      const workflowList = await getWorkflows(currentAppId);
      set(eventObj, 'statusCode', 200);
      dispatch(updateWorkflowList(workflowList));
    } catch (error) {
      if (isNumber(error?.response?.statusCode)) set(eventObj, 'statusCode', error?.response?.statusCode);
      Sentry.captureException(`${errorCode.HOME_ERROR_FETCHING_WORKFLOWS} - ${error}`, {
        extra: {
          errorMessage: AlertCode.ERROR_FETCHING_WORKFLOWS,
        },
      });
      showErrorAlert({ error, message: AlertCode.ERROR_FETCHING_WORKFLOWS });
    }
    const processingTime = performance.now() - worklowFetchStartTime;
    set(eventObj, 'processingTime', processingTime);
    const eventName = PERFORMANCE_METRIC_EVENTS.FETCH_WORKFLOW;
    formatPerformanceAnalyticsData(eventObj, eventName);
  };

  useEffect(() => {
    if (currentAppId) fetchWorkflows();
  }, [currentAppId]);

  useEffect(() => {
    if (!isRudderStackDataSent) {
      formatAnalyticsData(email, clientId, rudderstackEvents.DASHBOARD_HOMEPAGE_LAUNCH, 'Home');
      setIsRudderStackDataSent(true);
    }
  });

  return (
    <div id="main__container__body__generic">
      <Grid container>
        <Grid item xs={12}>
          <h2 className="font-design-heading">Home</h2>
        </Grid>
        <Grid item xs={12}>
          <span className="font-design-sub-heading">
            Get started with HyperVerge
          </span>
        </Grid>
        <DemoCard />
        <Grid item xs={6}>
          <Grid container>
            <div className="modal__Rectangle-52">
              <Grid item xs={6}>
                <span className="modal__heading">
                  Integrate SDK
                </span>
              </Grid>
              <Grid item xs={12} className="modal__sub_description_container">
                <span className="modal__sub_description">
                  Integrate our battle-tested web and mobile SDKs and get started right away
                </span>
              </Grid>
              <Grid item xs={12} className="modal__link_container">
                <Link
                  color="inherit"
                  onClick={() => {
                    formatAnalyticsData(email, clientId, rudderstackEvents.DASHBOARD_HOME_DOCUMENTATION_CLICK, 'Drawer');
                    window.open(`${DOCUMENTATION_PORTAL_URL}/?jwt=${docToken}`);
                  }}
                  className="modal__link"
                  id="modal__link_documentation"
                >
                  Browse the Documentation
                  <img src={arrow} alt="arrow" className="modal__link_arrow" />
                </Link>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </Grid>
      <div id="main__container__body__new_main">
        {
          (authorizedProducts && authorizedProducts.includes(authorisedProductsMapping.VKYCPortal))
            ? <VideoKycCard />
            : <></>
        }
      </div>
    </div>
  );
}
