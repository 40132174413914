import { Spinner } from 'clm-components';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';
import { redirectFunction } from '../components/Onboarding/authHelper';
import { updateIsAuthenticated } from '../reducers/user';
import { userAuthenticateAPI } from '../api/auth';

import './Container.scss';

function NonAuthRoutesWrapper() {
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const queryParams = new URLSearchParams(window.location.search);

  const isUserAuthenticated = async () => {
    try {
      await userAuthenticateAPI();
      return true;
    } catch (error) {
      return false;
    }
  };

  useEffect(() => {
    const init = async () => {
      const isAuthenticated = await isUserAuthenticated();
      dispatch(updateIsAuthenticated(isAuthenticated));
      if (isAuthenticated) {
        redirectFunction(dispatch, navigate, isAuthenticated, queryParams);
      } else {
        setIsLoading(false);
      }
    };

    init();
  }, []);

  if (isLoading) {
    return (
      <div id="non_auth_routes_wrapper__container">
        <Spinner size="big" />
      </div>
    );
  }
  return <Outlet />;
}

export default NonAuthRoutesWrapper;
