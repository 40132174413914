import React from 'react';
import PropTypes from 'prop-types';
import { ToolTip } from 'clm-components';

function AddToolTipOnDisable({ children, isDisabled, tooltipMessage }) {
  return (
    <>
      {isDisabled ? (
        <ToolTip
          title={<span>{tooltipMessage}</span>}
          placement="right-end"
        >
          {children}
        </ToolTip>
      ) : (
        <>
          {children}
        </>
      )}
    </>
  );
}

AddToolTipOnDisable.defaultProps = {
  tooltipMessage: 'coming soon',
};

AddToolTipOnDisable.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  isDisabled: PropTypes.bool.isRequired,
  tooltipMessage: PropTypes.string,
};

export default AddToolTipOnDisable;
