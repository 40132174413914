import React, { useState } from 'react';
import PropTypes from 'prop-types';
import CustomButton from '../../../Common/Buttons/CustomButton';

function ReviewTag({
  value, isSelected, label, onReviewTagClick, isInteractive,
}) {
  const [hasTagBeenClicked, setHasTagBeenClicked] = useState(false);

  const onTagClick = async () => {
    if (!isInteractive) return;

    setHasTagBeenClicked(true);
    await onReviewTagClick(value);
    setHasTagBeenClicked(false);
  };

  return (
    <CustomButton
      onClick={onTagClick}
      disabled={hasTagBeenClicked}
      className="review_tags_card__tag_group__tags__tag_button"
      isSelected={isSelected}
    >
      {label}
    </CustomButton>
  );
}

ReviewTag.propTypes = {
  onReviewTagClick: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  isSelected: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  isInteractive: PropTypes.bool.isRequired,
};

export default ReviewTag;
