import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { CommonTable } from 'clm-components';
import { useSelector } from 'react-redux';
import EmptyState from './EmptyState';
import { constants } from '../../../config';
import { genTableData, genTableHeaders } from './AuditTableUtils';

function DataTable({
  handleRowClick, handlePageChange, tableColumns, isLoading, data, page, totalElements,
}) {
  const rowsPerPage = constants.maxRecordsInTable;
  // eslint-disable-next-line max-len
  const transactionMetadataColumnsConfig = useSelector((state) => state.s3Config?.transactionMetadataColumnsConfig?.configData);
  const dashboardDataTableColumns = useSelector(
    (state) => state.data?.dashboardDataTableColumns,
  );
  const [tableData, setTableData] = useState(genTableData(
    data, transactionMetadataColumnsConfig, dashboardDataTableColumns,
  ));
  const updatedTableColumns = genTableHeaders(tableColumns);

  useEffect(() => {
    setTableData(genTableData(
      data, transactionMetadataColumnsConfig, dashboardDataTableColumns,
    ));
  }, [data, transactionMetadataColumnsConfig, dashboardDataTableColumns]);

  return (
    <>
      <CommonTable
        data-hj-suppress
        id="application__data_table__table"
        tableHeader={updatedTableColumns}
        tableData={tableData}
        isLoading={isLoading}
        pageNumber={page}
        pageLength={rowsPerPage}
        totalRows={totalElements}
        handlePageChange={handlePageChange}
        emptyStateComponent={<EmptyState />}
        onRowClick={handleRowClick}
      />
    </>
  );
}

export default DataTable;

DataTable.propTypes = {
  handlePageChange: PropTypes.func.isRequired,
  handleRowClick: PropTypes.func.isRequired,
  tableColumns: PropTypes.arrayOf(PropTypes.any).isRequired,
  isLoading: PropTypes.bool.isRequired,
  data: PropTypes.arrayOf(PropTypes.any).isRequired,
  page: PropTypes.number.isRequired,
  totalElements: PropTypes.number.isRequired,
};
