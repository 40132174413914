import React from 'react';
import PropTypes from 'prop-types';

import getIconForSubType from '../../LeftPanel/utils';

function CommonHeader({ moduleName, subType }) {
  return (
    <div id="details__header__container">
      <div id="details__header__image">
        {React.cloneElement(getIconForSubType(subType))}
      </div>
      <div id="details__header__title">{moduleName}</div>
    </div>
  );
}

CommonHeader.propTypes = {
  moduleName: PropTypes.string.isRequired,
  subType: PropTypes.string.isRequired,
};

export default CommonHeader;
