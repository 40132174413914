/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { CommonTablePaginate as CommonTable } from 'clm-components';
import { useShowCustomAlert } from '../../../utils/lib';

import './Credential.scss';
import { tableDataGen, tableHeaders } from './credentialTableUtils';

function CredentialTable({ data, isLoading }) {
  const showCustomAlert = useShowCustomAlert();

  const updateAlert = (payload) => {
    showCustomAlert(payload);
  };

  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    if (data && data.length) {
      setTableData(tableDataGen(data, updateAlert));
    }
  }, [data]);

  return (
    <>
      <CommonTable
        tableHeader={tableHeaders}
        tableData={tableData}
        isLoading={isLoading}
        loadingData={{
          rowSize: 2,
          colSize: 3,
        }}
        id="credential__table"
        emptyStateMessage="No credentials available"
      />
    </>
  );
}

CredentialTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default CredentialTable;
