import React from 'react';
import PropTypes from 'prop-types';
import './Filter.scss';
import EditFilterButton from './EditFilterButton';
import CustomPopupContainer from '../Popup/CustomPopupContainer';

function EditFilter({
  filterLabel, onClose, details, onFilterEdit,
}) {
  const onFilterAdd = (editedDetails) => {
    onFilterEdit({
      ...editedDetails,
    });
  };

  return (
    <CustomPopupContainer
      popupTrigger={(
        <EditFilterButton
          label={filterLabel}
          onClose={onClose}
          isCancellable={details.isCancellable}
        />
      )}
      popupComponent={details.componentRenderer(
        {
          onFilterAdd,
          details,
        },
      )}
    />
  );
}

EditFilter.propTypes = {
  filterLabel: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]).isRequired,
  details: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  onFilterEdit: PropTypes.func.isRequired,
};

export default EditFilter;
