import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import CarrotDownIcon from '../../../assests/icons/carrotDown.svg';
import AddToolTipOnDisable from '../AddToolTipOnDisable';

import './Inputs.scss';

function CommonSelect({
  id,
  options,
  label,
  isSelected,
  handleChange,
  icon,
  initValue,
  displayComponentRenderer,
  isDropdown,
  disabled,
}) {
  const [selectedLabel, setSelectedLabel] = useState(label);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const selectRef = useRef('');

  const handleClick = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleClose = () => {
    setIsMenuOpen(false);
  };

  const handleSelect = (value, itemLabel, isDisabled) => {
    if (isDisabled) return;
    handleChange(value);
    if (!isDropdown) setSelectedLabel(itemLabel);
    handleClose();
  };

  useEffect(() => {
    if (initValue) {
      const { label: initLabel } = options.find(({ value }) => value === initValue);
      handleSelect(initValue, initLabel);
    }
  }, []);

  const handleMouseClick = (event) => {
    if (event.target !== selectRef.current
      && !selectRef.current.contains(event.target)) {
      setIsMenuOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleMouseClick);
    return () => {
      document.removeEventListener('mousedown', handleMouseClick);
    };
  }, []);

  return (
    <div id={id} className="common_select_container" ref={selectRef}>
      <div
        aria-hidden="true"
        onClick={handleClick}
        className={`${isMenuOpen && 'active'}  ${isSelected && 'selected'} common_select_button ${disabled && 'disable-div'}`}
      >
        {displayComponentRenderer ? displayComponentRenderer(selectedLabel) : selectedLabel}
        <img style={{ marginLeft: '10px' }} src={icon || CarrotDownIcon} alt="" />
      </div>
      {isMenuOpen && (
      <div className="common_select_menu">
        {options.map(({
          value, label: itemLabel, componentRenderer, disable,
        }) => (
          <>
            <AddToolTipOnDisable isDisabled={disable}>
              <div
                className={`${disable ? 'disable' : ''}`}
                onClick={() => handleSelect(value, itemLabel, disable)}
                key={value}
                aria-hidden
              >
                {componentRenderer ? componentRenderer() : itemLabel}
              </div>
            </AddToolTipOnDisable>
          </>
        ))}
      </div>
      )}
    </div>
  );
}

CommonSelect.defaultProps = {
  id: '',
  label: 'Select',
  isSelected: false,
  icon: undefined,
  initValue: null,
  displayComponentRenderer: null,
  isDropdown: false,
  disabled: false,
};

CommonSelect.propTypes = {
  id: PropTypes.string,
  options: PropTypes.array.isRequired,
  handleChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  isSelected: PropTypes.bool,
  icon: PropTypes.instanceOf(Element),
  initValue: PropTypes.string,
  displayComponentRenderer: PropTypes.func,
  isDropdown: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default CommonSelect;
