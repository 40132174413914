/* eslint-disable react/jsx-key */
import React from 'react';
import PropTypes from 'prop-types';
import amlSource from '../../../../constants/amlSources';

function AMLSourceDetailsPanel({
  index, item, value,
}) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >

      {item.map((hits) => (

        <div id="details__tab__details__body__amlDetail_container">
          <table id="details__tab__details__body__amlDetail_container__table">
            <thead>
              <tr id="details__tab__details__body__amlDetail_container__tr">
                <td data-hj-suppress id="details__tab__details__body__amlDetail_container__tableHeadingSources">
                  <a href={hits.url} target="_blank" rel="noopener noreferrer">{hits.name}</a>
                </td>
                <td aria-hidden id="details__tab__details__body__amlDetail_container__tableHeadingSources" />
                <td aria-hidden id="details__tab__details__body__amlDetail_container__tableHeadingSources" />

              </tr>
            </thead>
          </table>
          <>
            <table id="details__tab__details__body__amlDetail_container__table">
              {Object.keys(hits) && Object.keys(hits).length > 0 ? Object.keys(hits).map((key) => (
                <>
                  {key !== 'list' && key !== 'url' ? (
                    <tr id="details__tab__details__body__amlDetail_container__tr">
                      <td id="details__tab__details__body__amlDetail_container__t">{amlSource(key)}</td>
                      {Array.isArray(hits[key]) && hits[key].length > 0 && (hits[key][0].startsWith('https')
                    || hits[key][0].startsWith('http'))
                        ? (
                          <td data-hj-suppress id="details__tab__details__body__amlDetail_container__t">
                            {hits[key].map((url) => (
                              <a href={url} target="_blank" rel="noopener noreferrer">
                                {amlSource(url)}
                                {' ,'}
                              </a>
                            ))}
                          </td>

                        )
                        : <td data-hj-suppress id="details__tab__details__body__amlDetail_container__t">{Array.isArray(hits[key]) ? hits[key][0] : hits[key]}</td>}
                    </tr>
                  ) : ''}
                </>
              )) : ''}
            </table>
          </>
        </div>

      ))}

    </div>
  );
}

AMLSourceDetailsPanel.propTypes = {
  item: PropTypes.object.isRequired,
  value: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
};

export default AMLSourceDetailsPanel;
