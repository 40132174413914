import React from 'react';
import PropTypes from 'prop-types';

import './BUSelection.scss';
import { ReactComponent as ChevronDownIcon } from '../../assests/icons/chevronDown.svg';
import { ReactComponent as ChevronUpIcon } from '../../assests/icons/chevronUp.svg';
import { ReactComponent as ArrowRightIcon } from '../../assests/icons/rightArrow.svg';
import { ReactComponent as DotIcon } from '../../assests/icons/dot.svg';
import Avatar from './Avatar';

function ToggleDropdown({
  title, subtitle, options, isCollapsed, onTitleClick, onItemClick, showArrow, styleOverrides,
}) {
  return (
    <div className="BU-selection-toggle-dropdown" style={styleOverrides}>
      <div aria-hidden className={`BU-selection-toggle-dropdown__header ${!isCollapsed ? 'BU-selection-toggle-dropdown__header_expanded' : ''}`} onClick={onTitleClick}>
        <div className="BU-selection-toggle-dropdown__header-content">
          <Avatar name={title} />
          <div className="BU-selection-toggle-dropdown__header-title">
            {title}
          </div>
          {subtitle && (
            <div className="BU-selection-toggle-dropdown__header-subtitle">
              <DotIcon className="BU-selection-toggle-dropdown__header-subtitle_dot-icon" />
              {subtitle}
            </div>
          )}
        </div>
        {showArrow && (
          <div className="BU-selection-toggle-dropdown__header-arrow">
            {isCollapsed ? <ChevronDownIcon /> : <ChevronUpIcon />}
          </div>
        )}
      </div>
      {!isCollapsed && (
        <div className="BU-selection-toggle-dropdown__options-list">
          {options.map((option) => (
            <div aria-hidden className="BU-selection-toggle-dropdown__options-list-item" key={option} onClick={(event) => onItemClick(event, option)}>
              <div>
                {option}
              </div>
              <div className="BU-selection-toggle-dropdown__options-list-item__arrow-right-icon-container">
                <ArrowRightIcon className="BU-selection-toggle-dropdown__options-list-item__arrow-right-icon" />
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

ToggleDropdown.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  showArrow: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.string),
  isCollapsed: PropTypes.bool.isRequired,
  onTitleClick: PropTypes.func,
  onItemClick: PropTypes.func.isRequired,
  styleOverrides: PropTypes.object,
};

ToggleDropdown.defaultProps = {
  options: [],
  subtitle: '',
  showArrow: true,
  onTitleClick: () => {},
  styleOverrides: {},
};

export default ToggleDropdown;
