import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { first, get } from 'lodash';
import ImageViewerOpenWrapper from '../../../../../Common/Image/ImageViewer/ImageViewerOpenWrapper';
import PDFViewerOpenWrapper from '../../../../../Common/PDF/PDFViewer/PDFViewerOpenWrapper';
import MediaWrapper from '../../../MediaWrapper/MediaWrapper';
import seperatePDFsAndImages from '../../../MediaWrapper/utils';

function Media({ item }) {
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [isPDFViewerOpen, setIsPDFViewerOpen] = useState(false);

  const mediaLabel = get(item, 'endpointModule.media.label');

  const requestMediaConfig = get(item, 'endpointModule.media.requestMediaConfig', {});
  const mediaFileFromRequestToShow = item.fileUrls.find(
    ({ type }) => type === requestMediaConfig.fileName,
  ) || get(item, 'fileUrls[0]', null);

  const responseMediaConfig = get(item, 'endpointModule.media.responseMediaConfig', {});
  const mediaFromResponseToShow = get(item.extractedData, responseMediaConfig.path);

  let mediaSrcToShow;

  if (mediaFileFromRequestToShow) {
    mediaSrcToShow = get(mediaFileFromRequestToShow, 'url');
  } else if (mediaFromResponseToShow) {
    mediaSrcToShow = mediaFromResponseToShow;
  }

  const { images: viewerImages, pdfs } = seperatePDFsAndImages([
    { src: mediaSrcToShow, label: mediaLabel },
  ]);

  const handleImageClick = () => {
    setIsViewerOpen(true);
  };

  const handleViewerClose = () => {
    setIsViewerOpen(false);
  };

  const handlePDFClick = () => {
    setIsPDFViewerOpen(true);
  };

  const handlePDFViewerClose = () => {
    setIsPDFViewerOpen(false);
  };

  return (
    <>
      <ImageViewerOpenWrapper
        images={viewerImages}
        open={isViewerOpen}
        closeViewerHandler={handleViewerClose}
      />
      <PDFViewerOpenWrapper
        open={isPDFViewerOpen}
        pdf={first(pdfs) || {}}
        closeViewerHandler={handlePDFViewerClose}
      />
      <div className="generic_common_card__tab__body__image__container">
        <h1>{mediaLabel}</h1>
        {mediaSrcToShow ? (
          <MediaWrapper
            className="generic_common_card__tab__body__image"
            src={mediaSrcToShow}
            onImageClick={handleImageClick}
            onPDFClick={handlePDFClick}
          />
        ) : <div className="generic_common_card__tab__body__image" />}
      </div>
    </>
  );
}

Media.propTypes = {
  item: PropTypes.object.isRequired,
};

export default Media;
