import React from 'react';

import PropTypes from 'prop-types';
import { startCase } from 'lodash';
import getIconForSubType from '../../LeftPanel/utils';
import FlagItem from './FlagItem';

function FlagsV1({ flags, findIsActive, onModuleClick }) {
  const getFlagTextForSubModules = (subModule) => Object.entries(subModule).map((
    [side, { flag: subModuleFlag }], i,
  ) => `${startCase(side)} : ${subModuleFlag} ${i < Object.keys(subModule).length - 1 ? ' | ' : ''} `);

  return Object.entries(flags).map(([moduleId, {
    moduleName, flag, subType, subModule,
  }]) => (
    <div data-hj-suppress key={moduleId} id="right_panel__review_card__flags__flag" className={findIsActive(moduleId)} onClick={() => onModuleClick(moduleId)} aria-hidden>
      {React.cloneElement(getIconForSubType(subType))}

      {
        subModule ? (
          <FlagItem
            moduleName={moduleName}
            flag={getFlagTextForSubModules(subModule)}
          />
        )
          : (
            <FlagItem moduleName={moduleName} flag={flag} />
          )
        }
    </div>
  ));
}

FlagsV1.propTypes = {
  flags: PropTypes.object.isRequired,
  findIsActive: PropTypes.func.isRequired,
  onModuleClick: PropTypes.func.isRequired,
};

export default FlagsV1;
