import React, { useState } from 'react';

import PropTypes from 'prop-types';

import { ReactComponent as ArrowDownIcon } from '../../../../../../../assests/icons/arrowDown.svg';
import Title from '../Title';

function Accordion({
  children,
  id,
  elementKey,
  componentStyles,
  title,
  contentStyles,
  maxContentHeight,
  gridRow,
  gridCol,
}) {
  const [isCollapsed, setIsCollapsed] = useState(true);

  const toggleCollapseState = (event) => {
    event.preventDefault();
    event.stopPropagation();

    setIsCollapsed((prev) => !prev);
  };

  const getContentStyles = () => ({
    ...contentStyles,
    maxHeight: maxContentHeight,
  });

  const customStyles = {
    ...componentStyles,
    ...(gridRow && gridCol ? { gridArea: `${gridRow} / ${gridCol}` } : {}),
  };

  if (!children) return null;

  return (
    <div data-hj-suppress style={customStyles} id={id} key={elementKey} className={`generic_card_v2__collapsible_container ${isCollapsed ? '__collapsed' : ''}`}>
      <div aria-hidden onClick={toggleCollapseState} className={`generic_card_v2__collapsible_header ${isCollapsed ? '__collapsed' : ''}`}>
        <Title text={title} />
        <ArrowDownIcon className={`generic_card_v2__collapsible_icon ${isCollapsed ? '' : '__expanded'}`} />
      </div>
      <div style={getContentStyles()} className={`generic_card_v2__collapsible_content ${isCollapsed ? '' : '__expanded'}`}>
        {children}
      </div>
    </div>
  );
}

Accordion.defaultProps = {
  componentStyles: {},
  contentStyles: {},
  maxContentHeight: '',
  gridRow: undefined,
  gridCol: undefined,
};

Accordion.propTypes = {
  children: PropTypes.array.isRequired,
  id: PropTypes.string.isRequired,
  elementKey: PropTypes.string.isRequired,
  componentStyles: PropTypes.object,
  title: PropTypes.string.isRequired,
  contentStyles: PropTypes.object,
  maxContentHeight: PropTypes.string,
  gridRow: PropTypes.string,
  gridCol: PropTypes.string,
};

export default Accordion;
