const demoWorkflowPermissions = {
  requiredPermissions: {
    viewPermissions: ['homePage.demoWorkflow.view'],
    interactPermissions: ['homePage.demoWorkflow.interact'],
  },
};

const homePagePermissions = {
  demoWorkflow: demoWorkflowPermissions,
};

export default homePagePermissions;
