import React, { useState } from 'react';
import PropTypes from 'prop-types';

import './Buttons.scss';

const disabledOnClick = () => {};

const getButtonClassName = (disabled, isActive, isSelected, customClassName, theme) => {
  let className = `custom_button ${theme}`;
  if (disabled) className += ' disabled';
  if (isActive) className += ' active';
  if (isSelected) className += ' selected';
  if (customClassName) className += ` ${customClassName}`;
  return className;
};

function CustomButton({
  id,
  className,
  theme,
  disabled,
  isActive,
  isSelected,
  children,
  IconLeft,
  IconRight,
  onClick,
  toolTipText,
}) {
  const [showText, setShowText] = useState(false);

  const hoverButton = () => setShowText(true);
  const unHoverButton = () => setShowText(false);

  return (
    <div className="custom-button-container">
      <button
        id={id}
        type="button"
        className={getButtonClassName(disabled, isActive, isSelected, className, theme)}
        onClick={disabled ? disabledOnClick : onClick}
        onMouseOver={disabled ? hoverButton : undefined}
        onFocus={disabled ? hoverButton : undefined}
        onMouseLeave={disabled ? unHoverButton : undefined}
        disabled={disabled}
      >
        {IconLeft && <IconLeft className="icon_left" />}
        {children}
        {IconRight && <IconRight className="icon_right" />}
      </button>
      { (disabled && toolTipText) && (
      <p className={`hover-text ${showText ? 'show-text' : 'hide-text'}`}>
        {toolTipText}
      </p>
      )}
    </div>
  );
}

CustomButton.defaultProps = {
  id: '',
  className: '',
  theme: 'white',
  disabled: false,
  isActive: false,
  isSelected: false,
  IconLeft: null,
  IconRight: null,
  onClick: disabledOnClick,
  toolTipText: '',
};

CustomButton.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  theme: PropTypes.string,
  disabled: PropTypes.bool,
  isActive: PropTypes.bool,
  isSelected: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  IconLeft: PropTypes.element,
  IconRight: PropTypes.element,
  onClick: PropTypes.func,
  toolTipText: PropTypes.string,
};

export default CustomButton;
