import axios from 'axios';

const axiosRequest = axios.create({
  baseURL: `${process.env.REACT_APP_SERVER_URL}/api/v1`,
  withCredentials: true,
});

const axiosRequestV2 = axios.create({
  baseURL: `${process.env.REACT_APP_SERVER_URL}/api/v2`,
  withCredentials: true,
});

const fetchClientUsageApi = (startDate, endDate, appIdList) => axiosRequest.post('usage', { startDate, endDate, appIdList });

export const getUniqueTransaction = (startDate, appIdList) => axiosRequest.post('usage/transactions/unique', { appIdList, startDate });

export const getModuleCount = (clientId) => axiosRequest.post('usage/module/count', { clientId });

export const getClientInfo = (clientId) => axiosRequest.get(`user/company?clientId=${clientId}`);

export const fetchClientLogs = (startDate, appIdList) => axiosRequestV2.post('usage/logs/', { monthStartDate: startDate, appIdList });

export const fetchLogsForUniqueTransaction = (startDate, appIdList) => axiosRequestV2.post('usage/transaction/logs', { monthStartDate: startDate, appIdList });

export const getModuleCost = (startDate, endDate, usageConfig, clientId) => axiosRequest.post('/billing/module', {
  startDate, endDate, usageConfig, clientId, splitAppIdUsage: 'yes',
});

export const getPlatformCost = (startDate, clientId) => axiosRequest.post('/billing/platform', {
  startDate, clientId, splitAppIdUsage: 'yes',
});

export const getPlatformBreakup = async ({
  appIdList, monthStartDate, monthEndDate, workflowId,
}) => {
  const res = await axiosRequest.post(
    'usage/platform/breakup',
    {
      appIdList,
      workflowId,
      monthEndDate,
      monthStartDate,
    },
  );
  return res?.data?.result;
};

export default fetchClientUsageApi;
