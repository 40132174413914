import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { capitalize } from 'lodash';
import getIconForSubType from '../../LeftPanel/utils';
import translateCodeAndMessageToFlag from './utils';
import useS3Config from '../../../../utils/hooks/s3Config';
import FlagItem from './FlagItem';

function FlagsV2({
  v2Flags,
  findIsActive,
  onModuleClick,
}) {
  const [flagConfig, setFlagConfig] = useState();
  const getFlagConfig = useS3Config('flagConfig');

  const getFlagText = (moduleName, flagsEntry, originalUrl) => flagsEntry
    ?.map(({ subSource, values }) => {
      const source = subSource ? capitalize(subSource) : moduleName;
      const message = Object.keys(values)
        .map((key) => translateCodeAndMessageToFlag(key, values[key], originalUrl, flagConfig)).filter((msg) => !!msg).join(', ');
      return message ? `${source}: ${message}` : '';
    })?.join('; ');

  useEffect(() => {
    const fetchFlagConfigs = async () => {
      setFlagConfig(await getFlagConfig());
    };
    fetchFlagConfigs();
  }, []);

  useEffect(() => {}, [flagConfig]);

  return Object.entries(v2Flags).map(([moduleId, {
    moduleName,
    moduleSubType,
    originalUrl,
    flags: flagsEntry,
  }]) => {
    const flagText = getFlagText(moduleName, flagsEntry, originalUrl);
    if (flagText) {
      return (
        <div data-hj-suppress key={moduleId} id="right_panel__review_card__flags__flag" className={findIsActive(moduleId)} onClick={() => onModuleClick(moduleId)} aria-hidden>
          {React.cloneElement(getIconForSubType(moduleSubType))}
          <FlagItem flag={flagText} />
        </div>
      );
    }

    return <></>;
  });
}

FlagsV2.propTypes = {
  v2Flags: PropTypes.object.isRequired,
  findIsActive: PropTypes.func.isRequired,
  onModuleClick: PropTypes.func.isRequired,
};

export default FlagsV2;
