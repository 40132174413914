const applicationStatusLabelMap = {
  needs_review: 'Needs Review',
  started: 'Started',
  manually_declined: 'Manually Declined',
  manually_approved: 'Manually Approved',
  auto_approved: 'Auto Approved',
  auto_declined: 'Auto Declined',
  user_cancelled: 'User Cancelled',
  error: 'Error',
  kyc_in_progress: 'Kyc In Progress',
};

module.exports = { applicationStatusLabelMap };
