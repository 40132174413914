import React from 'react';
import { Navigate } from 'react-router-dom';

const auditPageComponentPermissions = {
  requiredPermissions: {
    viewPermissions: ['auditPage.view'],
    interactPermissions: ['auditPage.interact'],
  },
  redirect: () => <Navigate to="/home" replace />,
};

const auditDownloadCSVPermissions = {
  requiredPermissions: {
    viewPermissions: ['auditPage.auditDownloadCSV.view'],
    interactPermissions: ['auditPage.auditDownloadCSV.interact'],
  },
};

const newApplicationPermissions = {
  requiredPermissions: {
    viewPermissions: ['auditPage.newApplication.view'],
    interactPermissions: ['auditPage.newApplication.interact'],
  },
};

const auditPagePermissions = {
  auditPage: auditPageComponentPermissions,
  auditDownloadCSV: auditDownloadCSVPermissions,
  newApplication: newApplicationPermissions,
};

export default auditPagePermissions;
