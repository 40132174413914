import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { startCase } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import TableColumns from './TableColumns';
import { updateColumns } from '../../../../reducers/data';
import { columnLabelMap, defaultColumns } from '../AuditColumnUtils';
import CustomPopupContainer from '../../../Common/Popup/CustomPopupContainer';
import EditColumnsButton from './EditColumnsButton';
import { findIndexAndMove } from './util';
import DownloadCSV from './DownloadCSV';
import { APPLICATION_LIST_TABLE_BLACKLISTED_COLUMNS, DASHBOARD_DATA_KEY } from '../../../../constants/transaction';

function TableActions({ searchValue }) {
  const [availableOptions, setAvailableOptions] = useState([]);
  const selectedColumns = useSelector((state) => state.data.selectedColumns);
  const applicationData = useSelector((state) => state.data.value);
  const dispatch = useDispatch();

  // eslint-disable-next-line max-len
  const transactionMetadataColumnsConfig = useSelector((state) => state.s3Config?.transactionMetadataColumnsConfig?.configData);
  const dashboardDataTableColumns = useSelector(
    (state) => state.data?.dashboardDataTableColumns,
  );

  const onOpitionSelect = (option) => {
    if (selectedColumns.some((item) => item.value === option.value)) {
      dispatch(updateColumns(selectedColumns.filter((item) => item.value !== option.value)));
    } else {
      dispatch(updateColumns([...selectedColumns, option]));
    }
  };

  const orderChange = (sourceValue, targetValue) => {
    const newArr = findIndexAndMove(Array.from(selectedColumns), sourceValue, targetValue);
    dispatch(updateColumns(newArr));
  };

  useEffect(() => {
    const options = applicationData && applicationData.length
      ? Object.keys(applicationData[0]).map((item) => {
        if (APPLICATION_LIST_TABLE_BLACKLISTED_COLUMNS.includes(item)) return false;
        return {
          label: columnLabelMap[item]
            ? columnLabelMap[item]
            : startCase(item),
          value: item,
          id: item,
        };
      }).filter(Boolean)
      : defaultColumns;
    const metadataOptions = transactionMetadataColumnsConfig?.tableColumns?.map((option) => ({ label: option.label, value: `transactionMetadata.${option.path}`, id: option.path }));
    const dashboardDataOptions = dashboardDataTableColumns?.map(({ key, label }) => (
      { label, value: `${DASHBOARD_DATA_KEY}.${key}`, id: `${DASHBOARD_DATA_KEY}.${key}` }));

    if (metadataOptions?.length > 0) {
      options.push(...metadataOptions);
    }
    if (dashboardDataOptions.length > 0) {
      options.push(...dashboardDataOptions);
    }
    setAvailableOptions(options);
  }, [applicationData, transactionMetadataColumnsConfig, dashboardDataTableColumns]);

  return (
    <>
      <div id="audit__actions_container_dropdown">
        <DownloadCSV searchValue={searchValue} />
        <CustomPopupContainer
          popupPosition="left"
          popupTrigger={(
            <EditColumnsButton />
          )}
          popupComponent={(
            <TableColumns
              availableOptions={availableOptions}
              selectedOptions={selectedColumns}
              onSelect={onOpitionSelect}
              orderChange={orderChange}
            />
          )}
        />
      </div>
    </>
  );
}

TableActions.propTypes = {
  searchValue: PropTypes.string.isRequired,
};

export default TableActions;
