import React from 'react';

import PropTypes from 'prop-types';
import sdkErrorIcon from '../../../../assests/icons/sdkErrorIcon.svg';

function FailureReason({ failureReason }) {
  return (
    <div id="right_panel__review_card__failure_reason_container">
      <h4>Failure Reason:</h4>
      <div id="right_panel__review_card__failure_reason__text_container">
        <img src={sdkErrorIcon} alt="message" />
        {failureReason}
      </div>
    </div>
  );
}

FailureReason.propTypes = {
  failureReason: PropTypes.string.isRequired,
};

export default FailureReason;
