import React from 'react';
import { Box } from '@mui/system';
import PropTypes from 'prop-types';
import './Demo.scss';
import { StyledModal, Backdrop } from '../Common/StyledModal';

const boxStyle = {
  width: 400,
  height: 600,
  bgcolor: 'background.paper',
  padding: 2,
  paddingBottom: 1,
  outline: 'none',
};

function CommonModal({
  children, open, heading, subHeading,
}) {
  return (
    <StyledModal
      open={open}
      components={
        { Backdrop }
      }
      disableAutoFocus
    >
      <Box id="common_modal__container" sx={boxStyle}>
        <h2 id="common_modal__heading">
          {heading}
        </h2>
        <p id="common_modal__description">
          {subHeading}
        </p>
        {children}
      </Box>
    </StyledModal>
  );
}

export default CommonModal;

CommonModal.propTypes = {
  open: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  heading: PropTypes.string.isRequired,
  subHeading: PropTypes.string.isRequired,
};
