import React from 'react';
import PropTypes from 'prop-types';
import { formatDateForApplicationTable } from '../../utils/dateFormats';

function CreatedAtAndStatusAml({
  createdAt,
}) {
  return (
    <div data-hj-suppress id="details__tab__header">
      <p>
        Created At:
        <span>{formatDateForApplicationTable(createdAt)}</span>
      </p>
    </div>
  );
}
CreatedAtAndStatusAml.defaultProps = {
  createdAt: '',
};

CreatedAtAndStatusAml.propTypes = {
  createdAt: PropTypes.string,
};
export default CreatedAtAndStatusAml;
