export const getImageScaleTransform = (
  containerRef,
  scaleRef,
  imageRotationAngle,
  imageScaleSize,
  isVerticalImage,
) => {
  // Translate image if overflows container to avoid cutting off
  const isImageRotatedHorizontally = imageRotationAngle === 90
    || imageRotationAngle === 270
    || imageRotationAngle === -90
    || imageRotationAngle === -270;
  const containerWidth = containerRef.current.offsetWidth;
  const scaleWidth = (isVerticalImage && isImageRotatedHorizontally
    ? scaleRef.current.offsetHeight
    : scaleRef.current.offsetWidth) * imageScaleSize;
  if (scaleWidth > containerWidth) {
    return `translateX(${
      (scaleWidth - containerWidth) / 2
    }px) scale(${imageScaleSize})`;
  }
  return `scale(${imageScaleSize})`;
};

export const getImageRotationTransform = (imageRef,
  imageRotationAngle,
  isVerticalImage) => {
  let transform;
  let transformOrigin;
  if (isVerticalImage) {
    // Handle vertical image cutting off when rotating 180deg
    const isImageRotatedHorizontally = imageRotationAngle === 180 || imageRotationAngle === -180;
    transformOrigin = isImageRotatedHorizontally ? 'top' : '50% 50% 0';
    transform = `${isImageRotatedHorizontally ? 'translate(0%, 100%)' : ''} rotate(${imageRotationAngle}deg)`;
  } else {
    // Handle horizontal image cutting off when rotating 90deg and 270deg
    const imageCurrentWidth = imageRef.current.width;
    const imageCurrentHeight = imageRef.current.height;
    if (imageRotationAngle === 90 || imageRotationAngle === -270) {
      transformOrigin = 'top left 0';
      transform = `translateX(${(imageCurrentWidth / 2 + imageCurrentHeight / 2)}px) rotate(${imageRotationAngle}deg)`;
    } else if (imageRotationAngle === 270 || imageRotationAngle === -90) {
      transformOrigin = 'top right 0';
      transform = `rotate(-90deg) translateY(-${(imageCurrentWidth / 2 + imageCurrentHeight / 2)}px)`;
    } else {
      transformOrigin = '50% 50% 0';
      transform = `rotate(${imageRotationAngle}deg)`;
    }
  }
  return { transform, transformOrigin };
};
