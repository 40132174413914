import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, isNumber, isString } from 'lodash';
import TableCell from './TableCell';
import TableHeader from './TableHeader';

function Table({
  title, header, maxHeight, tableRows,
}) {
  const tableRef = useRef();
  const tableStyles = {
    ...(isString(maxHeight) ? { maxHeight } : {}),
  };

  function calculateTableHeight(maxRows) {
    if (!tableRef.current) return;

    const table = tableRef.current;
    const { children } = table;
    const rowsInTable = children.length;
    let height = 0;
    if (rowsInTable > maxRows) {
      for (let i = 0; i < maxRows; i += 1) {
        height += table.children[i].clientHeight;
      }
      table.style.height = `${height}px`;
    }
  }

  useEffect(() => {
    if (tableRef.current && isNumber(maxHeight)) {
      calculateTableHeight(maxHeight);
    }
  });

  return (
    <div className="card_table__container">
      {title}
      <div className="card_table" style={tableStyles} ref={tableRef}>
        <TableHeader header={header} />
        {tableRows.map(({ key, label, value }) => (
          <div key={key} className={`card_table__row ${isEmpty(header) ? 'even' : 'odd'}`}>
            <TableCell className="card_table__row__key" value={label || key} />
            <TableCell className="card_table__row__value" value={value} />
          </div>
        ))}
      </div>
    </div>
  );
}

Table.defaultProps = {
  maxHeight: null,
  tableRows: [],
  header: '',
};

Table.propTypes = {
  title: PropTypes.element.isRequired,
  header: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  maxHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  tableRows: PropTypes.array,
};

export default Table;
