export const TRANSACTIONS_TABLE_SORT_ALLOWED_COLUMN_KEYS = ['updatedAt', 'createdAt'];

export const TRANSACTIONS_TABLE_TIME_COLUMN_KEYS = ['createdAt', 'eventTime', 'updatedAt'];

export const DEFAULT_SORT_DIRECTION = 'desc';

export const DASHBOARD_DATA_KEY = 'dashboardData';

export const APPLICATION_LIST_TABLE_BLACKLISTED_COLUMNS = [
  'appVersion', 'browser', 'device', 'eventTime', 'os', 'platform',
  'platformVersion', 'sdkVersion', 'extractedData', 'sdkErrorMessage', 'transactionMetadata',
  'idImageUrl', 'selfieImageUrl', DASHBOARD_DATA_KEY,
];
