import React from 'react';

import PropTypes from 'prop-types';

function Grid({ children, gridColumnsSplit, componentStyles }) {
  const customStyles = {
    ...componentStyles,
    gridTemplateColumns: gridColumnsSplit,
  };

  if (!children) return null;

  return <div data-hj-suppress style={customStyles} className="generic_card_v2__grid">{children}</div>;
}

Grid.propTypes = {
  children: PropTypes.array.isRequired,
  gridColumnsSplit: PropTypes.string.isRequired,
  componentStyles: PropTypes.object.isRequired,
};

export default Grid;
