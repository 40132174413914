import React, { } from 'react';
import PropTypes from 'prop-types';
import LeftArrowIcon from '../../../../assests/icons/leftArrow08.svg';
import './Comment.scss';
import CommentItem from './CommentItem';

function CommentList({ setViewMode, comments }) {
  const reversedComment = [...comments].reverse();
  return (
    <div id="comment_list_container">
      <button type="button" id="comment_list_heading" onClick={() => setViewMode(false)}>
        <img src={LeftArrowIcon} alt="" />
        <p>Comments</p>
      </button>
      <div id="comment_list_box">
        {reversedComment.map(
          (comment) => (
            <CommentItem
              key={comment.createdAt}
              userId={comment.userId}
              message={comment.comment}
              time={comment.createdAt}
            />
          ),
        )}
      </div>
    </div>
  );
}

CommentList.propTypes = {
  setViewMode: PropTypes.func.isRequired,
  comments: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default CommentList;
