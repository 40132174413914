import React, { useState } from 'react';
import { PermissionWrapper } from 'storybook-ui-components';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import { useSelector } from 'react-redux';
import DemoModal from './DemoModal';
import { useFormatAnalyticsData } from '../../../utils/lib';
import rudderstackEvents from '../../../constants/rudderstackEventNames';
import getPermission from '../../../Permissions/mapping';
import useGetUserPermissions from '../../../Permissions/hooks';
import arrow from '../../../assests/icons/arrowSVG.svg';

function DemoCard() {
  const formatAnalyticsData = useFormatAnalyticsData();
  const [openPopup, setOpenPopup] = useState(false);

  const email = useSelector((state) => state.user.email);
  const clientId = useSelector((state) => state.user.clientId);
  const freshchatInitialized = useSelector((state) => state.initialize.freshchatInitialized);

  const showPopupWindow = () => {
    formatAnalyticsData(email, clientId, rudderstackEvents.DASHBOARD_HOME_GETLINK_CLICK, 'Home');
    setOpenPopup(true);
    if (freshchatInitialized) {
      window.fcWidget.hide();
    }
  };

  const closePopupWindow = () => {
    setOpenPopup(false);
  };
  return (
    <>
      <DemoModal open={openPopup} closePopupWindow={closePopupWindow} />
      <Grid item xs={6}>
        <Grid container>
          <div className="modal__Rectangle-51">
            <Grid item xs={12}>
              <span className="modal__heading">
                Experience the user journey
              </span>
            </Grid>
            <Grid item xs={12} className="modal__sub_description_container">
              <span className="modal__sub_description">
                Try out our ready-to-use workflow on web or mobile in minutes
              </span>
            </Grid>
            <Grid item xs={12} className="modal__link_container">
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <Link
                color="inherit"
                onClick={showPopupWindow}
                className="modal__link"
                id="modal__link_demo"
              >
                Try the demo
                <img src={arrow} alt="arrow" className="modal__link_arrow" />
              </Link>
            </Grid>
          </div>
        </Grid>
      </Grid>
    </>
  );
}

export default PermissionWrapper(DemoCard, useGetUserPermissions, getPermission('demoWorkflow'));
