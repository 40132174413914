/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const dataSlice = createSlice({
  name: 'onboard',
  initialState: {
    docToken: '',
    docUrl: '',
  },
  reducers: {
    updateDocToken: (state, action) => {
      state.docToken = action.payload;
    },
    updateDocUrl: (state, action) => {
      state.docUrl = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  updateDocToken, updateDocUrl,
} = dataSlice.actions;
export default dataSlice.reducer;
