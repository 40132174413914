import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import ReviewTagGroup from './ReviewTagGroup';

function ReviewTagsSection({ title, tagGroups, moduleId }) {
  if (isEmpty(tagGroups) || isEmpty(title)) {
    return null;
  }

  return (
    <div className="review_tags_card__section">
      <div className="review_tags_card__section__title">{title}</div>
      {tagGroups.map(
        ({
          key, title: tagGroupTitle, selectedValue, items, isInteractive, conditionData,
        }) => (
          <ReviewTagGroup
            key={key}
            tagGroupKey={key}
            title={tagGroupTitle}
            selectedValue={selectedValue}
            items={items}
            isInteractive={isInteractive}
            moduleId={moduleId}
            conditionData={conditionData}
          />
        ),
      )}
    </div>
  );
}

ReviewTagsSection.propTypes = {
  title: PropTypes.string.isRequired,
  tagGroups: PropTypes.array.isRequired,
  moduleId: PropTypes.string.isRequired,
};

export default ReviewTagsSection;
