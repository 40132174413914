import React from 'react';
import PropTypes from 'prop-types';
import TickIcon from '../../../../assests/icons/tick.svg';
import MoveIcon from '../../../../assests/icons/rearrange.svg';

function TableColumns({
  availableOptions, selectedOptions, onSelect, orderChange,
}) {
  const checkBoxClick = (event, option) => {
    event.stopPropagation();
    onSelect(option);
  };

  const onDrag = (e, option) => {
    e.dataTransfer.setData('data', option.value);
  };

  const onDragOver = (ev) => {
    ev.preventDefault();
  };

  const onDrop = (e, option) => {
    e.preventDefault();
    orderChange(e.dataTransfer.getData('data'), option.value);
  };

  return (
    <div id="audit__actions_container_table_columns">
      <div
        id="audit__actions_container_table_columns_checkbox_container"
      >
        {selectedOptions && selectedOptions.map((option) => (
          <div
            key={option.value}
            id="audit__actions_container_table_columns_checkbox_item"
            onClick={(e) => checkBoxClick(e, option)}
            draggable="true"
            onDragStart={(e) => onDrag(e, option)}
            onDragOver={onDragOver}
            onDrop={(e) => onDrop(e, option)}
            aria-hidden
          >
            <div
              className="active"
            >
              <img src={TickIcon} alt="" />
            </div>
            <span>
              {option.label}
            </span>
            <img src={MoveIcon} width={10} height={10} alt="" />
          </div>
        ))}
        {availableOptions && availableOptions.map((option) => (
          <>
            {!selectedOptions.some((item) => item.value === option.value) && (
            <button
              key={option.value}
              id="audit__actions_container_table_columns_checkbox_item"
              onClick={(e) => checkBoxClick(e, option)}
              type="button"
            >
              <div>
                <img src={TickIcon} alt="" />
              </div>
              <span>
                {option.label}
              </span>
            </button>
            )}
          </>
        ))}
      </div>
    </div>
  );
}

TableColumns.propTypes = {
  availableOptions: PropTypes.array.isRequired,
  selectedOptions: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired,
  orderChange: PropTypes.func.isRequired,
};

export default TableColumns;
