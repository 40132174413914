const auditButtonsPermissions = {
  requiredPermissions: {
    viewPermissions: ['applicationDetailsPage.auditButtons.view'],
    interactPermissions: ['applicationDetailsPage.auditButtons.interact'],
  },
};

const shareApplicationPermissions = {
  requiredPermissions: {
    viewPermissions: ['applicationDetailsPage.auditActions.shareApplication.view'],
    interactPermissions: ['applicationDetailsPage.auditActions.shareApplication.interact'],
  },
};

const commentsCardPermissions = {
  requiredPermissions: {
    viewPermissions: ['applicationDetailsPage.commentsCard.view'],
    interactPermissions: ['applicationDetailsPage.commentsCard.interact'],
  },
};

const applicationDetailsPagePermissions = {
  auditButtons: auditButtonsPermissions,
  shareApplication: shareApplicationPermissions,
  commentsCard: commentsCardPermissions,
};

export default applicationDetailsPagePermissions;
