import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import CustomPopupContainer from '../../../../../../Common/Popup/CustomPopupContainer';
import MenuButton from './MenuButton';
import MenuItems from './MenuItems';

function Menu({ items, availableData }) {
  if (isEmpty(items)) {
    return null;
  }
  return (
    <CustomPopupContainer
      popupTrigger={(
        <MenuButton />
    )}
      popupComponent={<MenuItems items={items} availableData={availableData} />}
      popupPosition="bottom-left"
    />
  );
}

Menu.defaultProps = {
  items: {},
};

Menu.propTypes = {
  items: PropTypes.array,
  availableData: PropTypes.object.isRequired,
};

export default Menu;
