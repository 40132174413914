import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import useS3Config from '../../../../utils/hooks/s3Config';

function CountryCell({ countryId }) {
  const [countryList, setCountryList] = useState([]);
  const getFilterConfig = useS3Config('filterConfig');

  useEffect(() => {
    // Optimize later, gets called for every table row
    async function loadCountryList() {
      const filterConfig = await getFilterConfig();
      setCountryList(get(filterConfig, 'countryOptions', []));
    }
    loadCountryList();
  }, []);

  return (
    <>
      {get(
        countryList.find(({ value }) => value === countryId),
        'label',
        countryId,
      )}
    </>
  );
}

CountryCell.propTypes = {
  countryId: PropTypes.string.isRequired,
};

export default CountryCell;
