import { get } from 'lodash';
import { DEFAULT_SORT_DIRECTION } from '../constants/transaction';

const sortDirectionSwitchMap = {
  asc: 'desc',
  desc: 'asc',
};

const switchSortDirection = (currentSortDirection) => get(
  sortDirectionSwitchMap, currentSortDirection, DEFAULT_SORT_DIRECTION,
);

export default switchSortDirection;
