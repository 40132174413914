import React from 'react';
import PropTypes from 'prop-types';

import CustomButton from '../../../../../../Common/Buttons/CustomButton';
import { ReactComponent as ArrowLeftIcon } from '../../../../../../../assests/icons/arrowLeft.svg';
import { ReactComponent as ArrowRightIcon } from '../../../../../../../assests/icons/rightArrow.svg';
import ordinalSuffix from '../../../../../../../utils/helpers';

function Navigation({ currentHitIndex, totalHits, setCurrentHitIndex }) {
  const navigateToPrevious = () => {
    if (currentHitIndex !== 0) { setCurrentHitIndex(currentHitIndex - 1); }
  };

  const navigateToNext = () => {
    if (currentHitIndex !== totalHits - 1) { setCurrentHitIndex(currentHitIndex + 1); }
  };

  return (
    <div className="card_type_five__footer__navigation">
      <p>
        Your Review of
        {' '}
        {ordinalSuffix(currentHitIndex + 1)}
        {' '}
        hit
      </p>
      <div className="card_type_five__footer__navigation__buttons">
        <CustomButton onClick={navigateToPrevious} disabled={currentHitIndex === 0}>
          <ArrowLeftIcon />
        </CustomButton>
        <p>
          {currentHitIndex + 1}
          {' '}
          of
          {' '}
          {totalHits}
          {' '}
          hits
        </p>
        <CustomButton onClick={navigateToNext} disabled={currentHitIndex === totalHits - 1}>
          <ArrowRightIcon />
        </CustomButton>
      </div>
    </div>
  );
}

Navigation.propTypes = {
  currentHitIndex: PropTypes.number.isRequired,
  totalHits: PropTypes.number.isRequired,
  setCurrentHitIndex: PropTypes.func.isRequired,
};

export default Navigation;
