import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { first, get } from 'lodash';
import ImageViewerOpenWrapper from '../../../../../../Common/Image/ImageViewer/ImageViewerOpenWrapper';
import PDFViewerOpenWrapper from '../../../../../../Common/PDF/PDFViewer/PDFViewerOpenWrapper';
import MediaWrapper from '../../../../MediaWrapper/MediaWrapper';
import seperatePDFsAndImages from '../../../../MediaWrapper/utils';

function Media({
  mediaLabel,
  mediaPath,
  id,
  elementKey,
  persistMedia,
  dataToUse,
  componentStyles,
  hideLabel,
  gridRow,
  gridCol,
  fileUrls,
  useMediaFromBucket,
  bucketMediaKey,
}) {
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [isPDFViewerOpen, setIsPDFViewerOpen] = useState(false);

  let mediaSrcToShow;

  if (useMediaFromBucket && bucketMediaKey) {
    const media = fileUrls.find((file) => file.type === bucketMediaKey);
    mediaSrcToShow = media?.url;
  } else if (mediaPath) mediaSrcToShow = get(dataToUse, mediaPath);

  const { images: viewerImages, pdfs } = seperatePDFsAndImages([
    { src: mediaSrcToShow, label: mediaLabel },
  ]);

  const handleImageClick = () => {
    setIsViewerOpen(true);
  };

  const handleViewerClose = () => {
    setIsViewerOpen(false);
  };

  const handlePDFClick = () => {
    setIsPDFViewerOpen(true);
  };

  const handlePDFViewerClose = () => {
    setIsPDFViewerOpen(false);
  };

  if (!mediaPath || !mediaSrcToShow) {
    if (!persistMedia) return null;
    mediaSrcToShow = 'default'; // Adding random string to show thumbnail if no image source is found but persistMedia is true
  }

  const customStyles = {
    ...componentStyles,
    ...(gridRow && gridCol ? { gridArea: `${gridRow} / ${gridCol}` } : {}),
  };

  return (
    <div data-hj-suppress style={customStyles} key={elementKey} id={id}>
      <ImageViewerOpenWrapper
        images={viewerImages}
        open={isViewerOpen}
        closeViewerHandler={handleViewerClose}
      />
      <PDFViewerOpenWrapper
        open={isPDFViewerOpen}
        pdf={first(pdfs) || {}}
        closeViewerHandler={handlePDFViewerClose}
      />
      <div className="generic_common_card__tab__body__image__container__v2">
        {mediaSrcToShow ? (
          <MediaWrapper
            className="generic_common_card__tab__body__image__v2"
            src={mediaSrcToShow}
            onImageClick={handleImageClick}
            onPDFClick={handlePDFClick}
            height={componentStyles.height}
            width="100%"
          />
        ) : (
          <div className="generic_common_card__tab__body__image__v2" />
        )}
      </div>
      {!hideLabel && (
      <div className="generic_card_v2__media__label">
        <p>{mediaLabel}</p>
      </div>
      )}
    </div>
  );
}

Media.defaultProps = {
  persistMedia: false,
  componentStyles: {},
  hideLabel: true,
  gridRow: undefined,
  gridCol: undefined,
  fileUrls: [],
  useMediaFromBucket: false,
  bucketMediaKey: '',
};

Media.propTypes = {
  mediaPath: PropTypes.string.isRequired,
  mediaLabel: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  dataToUse: PropTypes.object.isRequired,
  elementKey: PropTypes.string.isRequired,
  persistMedia: PropTypes.bool,
  componentStyles: PropTypes.bool,
  hideLabel: PropTypes.bool,
  gridRow: PropTypes.string,
  gridCol: PropTypes.string,
  fileUrls: PropTypes.array,
  useMediaFromBucket: PropTypes.bool,
  bucketMediaKey: PropTypes.string,
};

export default Media;
