import {
  first, get, isEmpty, keys,
} from 'lodash';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateCredState } from '../../../reducers/user';
import CommonSelect from '../Inputs/CommonSelect';

const label = 'Choose ClientId';

function ClientIdDropdown() {
  const credMapping = useSelector((state) => state.user.credState?.credMapping);
  const currentClientId = useSelector((state) => state.user.credState?.current?.clientId);
  const dispatch = useDispatch();

  if (isEmpty(credMapping) || keys(credMapping).length <= 1) {
    return null;
  }

  const handleClientIdChange = (value) => {
    const useCase = first(keys(get(credMapping, [value])));
    const appIdObject = first(get(credMapping, [value, useCase]));
    const { appId, type } = appIdObject || {};
    if (value !== currentClientId) {
      dispatch(updateCredState({
        clientId: value, useCase, appId, type,
      }));
    }
  };

  const clientIdOptions = keys(credMapping).map((key) => ({
    label: key,
    value: key,
  }));

  const displayComponentRenderer = () => (
    <>
      {currentClientId}
    </>
  );

  return (
    <CommonSelect
      options={clientIdOptions}
      label={label}
      handleChange={handleClientIdChange}
      initValue={currentClientId}
      displayComponentRenderer={displayComponentRenderer}
    />
  );
}

export default ClientIdDropdown;
