/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { getFileExtentionFromUrl } from '../../../../../utils/helpers';

import Image from './Image';
import PDF from './PDF';

function Media({
  src,
  imageProps,
  pdfProps,
  pathToData,
  availableData,
}) {
  const mediaSrc = src || get(availableData, pathToData, '');

  const fileExtension = getFileExtentionFromUrl(mediaSrc);

  if (fileExtension === 'pdf') {
    return <PDF src={mediaSrc} {...pdfProps} />;
  }

  return (
    <Image
      src={mediaSrc}
      {...imageProps}
    />
  );
}

Media.defaultProps = {
  src: '',
  imageProps: {},
  pdfProps: {},
};

Media.propTypes = {
  src: PropTypes.string,
  imageProps: PropTypes.object,
  pdfProps: PropTypes.object,
  pathToData: PropTypes.string.isRequired,
  availableData: PropTypes.object.isRequired,
};

export default Media;
