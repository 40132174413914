import React from 'react';
import PropTypes from 'prop-types';
import { join } from 'lodash';

function Column({
  maxHeight, flex, classes, children,
}) {
  return <div className={`column__container ${join(classes, ' ')}`} style={{ maxHeight, flex }}>{children}</div>;
}

Column.defaultProps = {
  maxHeight: null,
  flex: null,
  classes: [],
};

Column.propTypes = {
  maxHeight: PropTypes.string,
  flex: PropTypes.string,
  classes: PropTypes.array,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default Column;
