import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ToolTip } from 'clm-components';
import { get, isArray } from 'lodash';
import CommonSearchBar from '../Inputs/CommonSearchBar';
import './Filter.scss';

function FilterCard({
  onFilterAdd, availableFilterList, selectedFilterList, handleClose, setCurrentSelectedFilter,
}) {
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [filterList, setFilterList] = useState([]);

  useEffect(() => {
    if (availableFilterList) setFilterList(availableFilterList);
  }, [availableFilterList]);

  useEffect(() => () => {
    setCurrentSelectedFilter('');
  }, []);

  const handleBack = () => {
    setCurrentSelectedFilter('');
    setSelectedFilter(null);
  };

  const handleOnClick = (item, isDisabled) => {
    if (isDisabled) return;
    setCurrentSelectedFilter(item.label);
    setSelectedFilter(
      item.componentRenderer({
        onFilterAdd,
        handleBack,
        details: item,
        handleClose,
      }),
    );
  };

  const handleSearch = (value) => {
    const searchedFilterList = [];
    if (!value) {
      if (availableFilterList) setFilterList(availableFilterList);
      else setFilterList([]);
      return;
    }
    availableFilterList.forEach((group) => {
      const searchedFilterGroup = { ...group, filters: [] };
      group.filters.forEach((filter) => {
        if (filter.label.toLowerCase().includes(value.toLowerCase())) {
          searchedFilterGroup.filters.push(filter);
        }
      });
      if (searchedFilterGroup.filters.length > 0) searchedFilterList.push(searchedFilterGroup);
    });
    setFilterList(searchedFilterList);
  };

  if (selectedFilter) {
    // hack to close dob filter once date selected
    // since default behavior of date filter does not close after selection
    if (isArray(selectedFilterList) && selectedFilterList.includes(get(selectedFilter, 'props.details.key'))) {
      handleClose();
      setCurrentSelectedFilter('');
    } else {
      return selectedFilter;
    }
  }

  return (
    <div id="filter_card_container">
      <div id="filter_card_search_container">
        <CommonSearchBar handleSearch={handleSearch} placeholder="Search for Filters..." />
      </div>
      {filterList.length ? filterList.map((group) => (
        <div id="filter_card_group_container" key={group.id}>
          {group.name && <h2>{group.name}</h2>}
          <div id="filter_card_items_container">
            {group.filters.map((filter) => (
              <button
                type="button"
                id={filter.id}
                key={filter.id}
                className={`${selectedFilterList.includes(filter.key) || filter.isDisabled ? 'disabled' : ''}`}
                onClick={() => handleOnClick(
                  filter,
                  selectedFilterList.includes(filter.key)
                      || filter.isDisabled,
                )}
              >
                <ToolTip
                  title={
                    (
                      <>
                        <span>{filter.label}</span>
                      </>
                    )
                  }
                  placement="top-start"
                >
                  <span>{filter.label}</span>
                </ToolTip>
              </button>
            ))}
          </div>
        </div>
      )) : (
        <div id="filter_card_group_container" className="empty">
          <span>No filters found</span>
        </div>
      )}
    </div>
  );
}

FilterCard.propTypes = {
  onFilterAdd: PropTypes.func.isRequired,
  availableFilterList: PropTypes.array.isRequired,
  selectedFilterList: PropTypes.array.isRequired,
  handleClose: PropTypes.func.isRequired,
  setCurrentSelectedFilter: PropTypes.func.isRequired,
};

export default FilterCard;
