import React from 'react';
import { get } from 'lodash';
import { ReactComponent as IdCardIcon } from '../../../assests/icons/idValidation.svg';
import { ReactComponent as SelfieIcon } from '../../../assests/icons/selfieVerification.svg';
import { ReactComponent as FaceMatchIcon } from '../../../assests/icons/faceMatch.svg';
import { ReactComponent as FaceAuthIcon } from '../../../assests/icons/faceAuth.svg';
import { ReactComponent as DedupeIcon } from '../../../assests/icons/dedupe.svg';
import { ReactComponent as AmlIcon } from '../../../assests/icons/amlIcon.svg';
import { ReactComponent as VideoCardIcon } from '../../../assests/icons/videocard.svg';
import { ReactComponent as BarcodeIcon } from '../../../assests/icons/barcodeModule.svg';
import { ReactComponent as BankAccountIcon } from '../../../assests/icons/bankAccountVerification.svg';
import { ReactComponent as CkycIcon } from '../../../assests/icons/ckyc.svg';
import { ReactComponent as DigilockerIcon } from '../../../assests/icons/digilocker.svg';
import { ReactComponent as GeoIpIcon } from '../../../assests/icons/geoIp.svg';
import { ReactComponent as OKYCIcon } from '../../../assests/icons/okyc.svg';
import { ReactComponent as POAIcon } from '../../../assests/icons/POA.svg';
import { ReactComponent as SSNIcon } from '../../../assests/icons/ssnVerification.svg';
import { ReactComponent as TextMatchIcon } from '../../../assests/icons/textMatch.svg';

const DefaultIcon = IdCardIcon;

const typeToIconMapping = {
  type1: <SelfieIcon />,
  type2: <IdCardIcon />,
  type3: <IdCardIcon />,
  type4: <FaceMatchIcon />,
  type5: <AmlIcon />,
  videoCardType1: <VideoCardIcon />,
  videoCardType2: <VideoCardIcon />,
};

const subTypeIconMapping = {
  id_card_verification: <IdCardIcon />,
  id_card_validation: <IdCardIcon />,
  selfie_verification: <SelfieIcon />,
  selfie_validation: <SelfieIcon />,
  selfie_id_match_api: <FaceMatchIcon />,
  face_auth_api: <FaceAuthIcon />,
  dedupe_api: <DedupeIcon />,
  face_dedupe_api: <DedupeIcon />,
  aml_search_api: <AmlIcon />,
  barcode: <BarcodeIcon />,
  barcode_verification: <BarcodeIcon />,
  ind_penny_drop_api: <BankAccountIcon />,
  ind_ckyc_search_api: <CkycIcon />,
  ind_ckyc_download_api: <CkycIcon />,
  ind_ckyc_upload_api: <CkycIcon />,
  ind_digilocker_flow: <DigilockerIcon />,
  ip_geolocation_api: <GeoIpIcon />,
  ind_okyc_flow: <OKYCIcon />,
  poa_validation: <POAIcon />,
  usa_ssn_verification: <SSNIcon />,
  text_match_api: <TextMatchIcon />,
  ...typeToIconMapping,
};

const getIconForSubType = (subType) => get(subTypeIconMapping, subType, <DefaultIcon />);

export default getIconForSubType;
