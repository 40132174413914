import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import HitsContent from './HitsContent';
import Content from './Content';

function ContentWrapper({
  contentConfig, hitsConfig, moduleId,
}) {
  if (!isEmpty(hitsConfig)) {
    return (
      <HitsContent
        contentConfig={contentConfig}
        hitsConfig={hitsConfig}
        moduleId={moduleId}
      />
    );
  }

  return (
    <Content
      contentConfig={contentConfig}
      moduleId={moduleId}
    />
  );
}

ContentWrapper.propTypes = {
  contentConfig: PropTypes.object.isRequired,
  hitsConfig: PropTypes.object.isRequired,
  moduleId: PropTypes.string.isRequired,
};

export default ContentWrapper;
