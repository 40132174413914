import React from 'react';
import PropTypes from 'prop-types';
import EditColumnsIcon from '../../../../assests/icons/editColumns.png';

function EditColumnsButton({ handleClick }) {
  return (
    <button
      type="button"
      onClick={handleClick}
      className="table_action_item"
    >
      <img src={EditColumnsIcon} alt="csv" />
      Edit columns
    </button>
  );
}

EditColumnsButton.propTypes = {
  handleClick: PropTypes.func.isRequired,
};

export default EditColumnsButton;
