import React, { useEffect } from 'react';
import { Timeline } from '@mui/lab';

import PropTypes from 'prop-types';

import './TimelineView.scss';
import { useSelector } from 'react-redux';
import { get } from 'lodash';
import TimelineViewItem from './TimelineViewItem';
import { processTimelineData } from '../../RecordUtils';
import StatusTimelineViewItem from './StatusTimelineViewItem';
import { formatDateForTimelineHeader, formatDateForTimelineView } from '../../../../utils/dateFormats';

function TimelineView({ workflowModulesData, applicationStatus }) {
  const transactionData = useSelector(
    (state) => state.appDetails.transactionData,
  );
  const transactionStatusHistory = useSelector(
    (state) => state.appDetails.statusHistory,
  );
  const allRecords = useSelector((state) => state.appDetails.rawRecordData);
  const transactionCreatedAt = get(transactionData, 'eventTime');
  const transactionUpdatedAt = get(transactionData, 'updatedAt');

  const processedTimelineData = processTimelineData({
    allRecords,
    workflowModulesData,
    transactionCreatedAt,
    transactionUpdatedAt,
    applicationStatus,
    transactionStatusHistory,
  });

  useEffect(() => {
    const handleScroll = () => {
      const dateElement = document.getElementById('current-date');
      const rightPanel = document.querySelector('#record__right_panel');

      const top = document.querySelector('#record__content')?.scrollTop;
      if (!top) {
        dateElement.classList.add('hidden');
        rightPanel.classList.remove('timeline_shift');
        return;
      }
      const headers = document.querySelectorAll(
        '.application_details__timeline_view__date_container',
      );
      let activeId = headers[0]?.id;
      headers.forEach((header) => {
        const { y } = header?.getBoundingClientRect(0) || {};

        if (y && y <= 66) {
          activeId = header?.id;
        }
      });

      dateElement.innerHTML = activeId;
      dateElement.classList.remove('hidden');
      rightPanel.classList.add('timeline_shift');
    };

    document
      .querySelector('#record__content')
      ?.addEventListener('scroll', handleScroll);

    return () => {
      document
        .querySelector('#record__content')
        ?.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const getTimelineViewStartDate = () => processedTimelineData[0]?.date
   || formatDateForTimelineHeader(transactionCreatedAt);

  return (
    <div className="application_details__timeline_view__container">
      <Timeline>
        <div
          className="application_details__timeline_view__date_container"
          id={`${getTimelineViewStartDate()}`}
        >
          <p className="application_details__timeline_view__date">
            {getTimelineViewStartDate()}
          </p>
        </div>
        <StatusTimelineViewItem
          createdAt={transactionCreatedAt}
          time={formatDateForTimelineView(transactionCreatedAt)}
          status="started"
          end={processedTimelineData.length === 0}
        />
        {processedTimelineData
          && processedTimelineData.map(
            ({
              time, name, isSuccess, status, attempt, createdAt, date, flag, type, failureReason,
            }, index) => (
              <>
                {index !== 0 && processedTimelineData[index - 1]?.date
                  !== processedTimelineData[index]?.date ? (
                    <div
                      className="application_details__timeline_view__date_container"
                      id={`${date}`}
                    >
                      <p className="application_details__timeline_view__date">
                        {date}
                      </p>
                    </div>
                  ) : null}
                {
                  type === 'status' ? <StatusTimelineViewItem createdAt={createdAt} time={time} status={status} failureReason={failureReason} end={index === processedTimelineData.length - 1} /> : (
                    <TimelineViewItem
                      time={time}
                      name={name}
                      isSuccess={isSuccess}
                      status={status}
                      attempt={attempt}
                      createdAt={createdAt}
                      key={name + attempt}
                      flag={flag}
                      end={index === processedTimelineData.length - 1}
                    />
                  )
                }

              </>
            ),
          )}
      </Timeline>
    </div>
  );
}

TimelineView.propTypes = {
  workflowModulesData: PropTypes.array.isRequired,
  applicationStatus: PropTypes.string.isRequired,
};

export default TimelineView;
