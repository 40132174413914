/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const dataSlice = createSlice({
  name: 'focussedInputs',
  initialState: { AppInput: false },
  reducers: {
    updateFocussedInputs: (state, action) => {
      const { inputName, isFocussed } = action.payload;
      return { ...state, [inputName]: isFocussed };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  updateFocussedInputs,
} = dataSlice.actions;
export default dataSlice.reducer;
