import {
  first,
  last,
  replace,
  get,
  isObject,
  isArray,
  isNull,
  isUndefined,
} from 'lodash';
import * as Sentry from '@sentry/react';
import errorCode from '../../../../../constants/errorCode';

export const interpolateString = (string, data) => {
  const templateLiteralRegexp = /\${(.*?)}/g;
  try {
    let translatedString = `${string}`;
    const templateLiteralMatches = [...string.matchAll(/\${(.*?)}/g)];
    templateLiteralMatches.forEach((match) => {
      const stringToReplace = first(match);
      const pathToData = last(match);
      const dataInPath = get(data, pathToData);
      if (
        !isNull(dataInPath)
        && !isUndefined(dataInPath)
        && !isObject(dataInPath)
        && !isArray(dataInPath)
      ) {
        translatedString = replace(
          translatedString,
          stringToReplace,
          dataInPath,
        );
      } else {
        translatedString = replace(translatedString, stringToReplace, '');
      }
    });
    return translatedString;
  } catch (err) {
    Sentry.captureException(`${errorCode.UNHANDLED_ERROR} - ${err}`);
    return replace(string, templateLiteralRegexp, '');
  }
};

export default interpolateString;
