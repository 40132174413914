import React from 'react';
import { Navigate } from 'react-router-dom';

const credentialPageComponentPermissions = {
  requiredPermissions: {
    viewPermissions: ['credentialPage.view'],
    interactPermissions: ['credentialPage.interact'],
  },
  redirect: () => <Navigate to="/home" replace />,
};

const credentialPagePermissions = {
  credentialPage: credentialPageComponentPermissions,
};

export default credentialPagePermissions;
