import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { get, isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import ReviewTag from './ReviewTag';
import { updateReviewTagGroupSelectedValue } from '../../../../reducers/appDetails';
import { evaluateConditionData } from './utils';

function ReviewTagGroup({
  tagGroupKey, title, selectedValue, items, isInteractive, moduleId, conditionData,
}) {
  const dispatch = useDispatch();
  const appState = useSelector((state) => state.appDetails);
  const currentRequestId = get(appState, ['availableCardData', moduleId, 'recordData', 'requestId']);

  const onReviewTagClick = async (value) => {
    dispatch(updateReviewTagGroupSelectedValue({
      moduleId,
      requestId: currentRequestId,
      tagGroupKey,
      selectedValue: selectedValue === value ? null : value,
    }));
  };

  useEffect(() => {
    if (!isEmpty(conditionData)) {
      const {
        value,
        foundPassingCondition,
      } = evaluateConditionData({
        state: appState, moduleId, requestId: currentRequestId,
      }, conditionData);
      if (foundPassingCondition && value !== selectedValue) {
        dispatch(updateReviewTagGroupSelectedValue({
          moduleId,
          requestId: currentRequestId,
          tagGroupKey,
          selectedValue: value,
        }));
      }
    }
  }, [appState, conditionData, moduleId, selectedValue]);

  if (isEmpty(items)) {
    return null;
  }

  return (
    <div className="review_tags_card__tag_group">
      <div className="review_tags_card__tag_group__title">{title}</div>
      <div className="review_tags_card__tag_group__tags" aria-hidden>
        {items.map(({ key: reviewTagKey, label, value }) => (
          <ReviewTag
            key={reviewTagKey}
            value={value}
            isSelected={value === selectedValue}
            label={label}
            onReviewTagClick={onReviewTagClick}
            isInteractive={isInteractive}
          />
        ))}
      </div>
    </div>
  );
}

ReviewTagGroup.defaultProps = {
  isInteractive: true,
  conditionData: [],
};

ReviewTagGroup.propTypes = {
  tagGroupKey: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  selectedValue: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
  isInteractive: PropTypes.bool,
  moduleId: PropTypes.string.isRequired,
  conditionData: PropTypes.array,
};

export default ReviewTagGroup;
