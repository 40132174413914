import React from 'react';
import PropTypes from 'prop-types';

function SubPanelWrapper({
  children,
}) {
  return (
    <div id="right_panel__sub_panel_wrapper">
      {children}
    </div>
  );
}

SubPanelWrapper.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default SubPanelWrapper;
