import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateFilterData } from '../../../reducers/review';

function ProductDropDown() {
  const selectedProduct = useSelector((state) => state.review.filterData.product);
  const productList = useSelector((state) => state.review.filterData.productList);
  const dispatch = useDispatch();
  const handleChange = (event) => {
    const choice = event.target.value;
    dispatch(updateFilterData({
      product: choice,
    }));
  };

  return (
    <div>
      <FormControl
        id="product_dropdown"
        sx={{
          width: 150, mt: 1, backgroundColor: 'white', borderRadius: 30,
        }}
      >
        <InputLabel id="select-label">Product</InputLabel>
        <Select
          labelId="select-label"
          id="simple-select"
          value={selectedProduct}
          onChange={handleChange}
          label="Product"
          defaultValue="All"
          style={{
            borderRadius: 30,
          }}
        >
          {productList.map((val) => (
            <MenuItem key={val} id={val} value={val}>{val}</MenuItem>
          ))}
          <MenuItem key="All" value="All">All</MenuItem>
        </Select>
      </FormControl>
    </div>
  );
}

export default ProductDropDown;
