import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import DecisionButton from './DecisionButton';
import { updateHitsReviewTagGroupSelectedValue } from '../../../../../../../reducers/appDetails';
import { evaluateConditionData } from '../../../../../RightPanel/ReviewTagsCard/utils';

function DecisionButtonGroup({
  tagGroupKey,
  hitId,
  title,
  items,
  selectedValue,
  moduleId,
  requestId,
  conditionData,
}) {
  const dispatch = useDispatch();
  const appState = useSelector((state) => state.appDetails);

  const handleButtonClick = async (value) => {
    dispatch(updateHitsReviewTagGroupSelectedValue({
      moduleId,
      hitId,
      requestId,
      tagGroupKey,
      selectedValue: selectedValue === value ? null : value,
    }));
  };

  useEffect(() => {
    if (!isEmpty(conditionData)) {
      const {
        value,
        foundPassingCondition,
      } = evaluateConditionData({
        state: appState, moduleId, requestId, hitId,
      }, conditionData);
      if (foundPassingCondition && value !== selectedValue) {
        dispatch(updateHitsReviewTagGroupSelectedValue({
          moduleId,
          requestId,
          hitId,
          tagGroupKey,
          selectedValue: value,
        }));
      }
    }
  }, [appState, conditionData, moduleId, selectedValue]);

  return (
    <div className="card_type_five__footer__review__decision">
      <p>{title}</p>
      {items.map(({ key: buttonKey, label, value }) => (
        <DecisionButton
          key={buttonKey}
          label={label}
          value={value}
          isActive={selectedValue === value}
          handleButtonClick={handleButtonClick}
        />
      ))}
    </div>
  );
}

DecisionButtonGroup.defaultProps = {
  conditionData: [],
};

DecisionButtonGroup.propTypes = {
  tagGroupKey: PropTypes.string.isRequired,
  hitId: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
  selectedValue: PropTypes.string.isRequired,
  moduleId: PropTypes.string.isRequired,
  requestId: PropTypes.string.isRequired,
  conditionData: PropTypes.array,
};

export default DecisionButtonGroup;
