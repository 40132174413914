import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash.debounce';
import ApplicationPreview from './ApplicationPreview';

function TransactionIdCell({ data }) {
  const [showPreview, setShowPreview] = useState(false);

  const showPreviewDebounceHandeler = useMemo(
    () => debounce(() => setShowPreview(true), 500), [setShowPreview],
  );

  const cancelPreviewDebouncerHandeler = () => {
    setShowPreview(false);
    showPreviewDebounceHandeler.cancel();
  };

  return (
    <div>
      <span
        id="application__data_table__transaction_id"
        onMouseEnter={showPreviewDebounceHandeler}
        onMouseLeave={cancelPreviewDebouncerHandeler}
      >
        { showPreview
          ? (
            <ApplicationPreview
              createdAt={data.createdAt}
              status={data.status}
              transactionId={data.transactionId}
              count={data.idx}
              extractedData={data.extractedData}
            />
          )
          : null}
        {data.transactionId}
      </span>
    </div>
  );
}

TransactionIdCell.propTypes = {
  data: PropTypes.object.isRequired,
};

export default TransactionIdCell;
