export const columnLabelMap = {
  transactionId: 'Transaction ID',
  createdAt: 'Created',
  status: 'Status',
  workflowId: 'Workflow Name',
  device: 'Device',
  browser: 'Browser',
  platformVersion: 'Platform Version',
  platform: 'Platform',
  sdkVersion: 'SDK Version',
  appVersion: 'App Version',
  ipAddress: 'IP Address',
  country: 'Country',
  dob: 'Date of Birth',
  name: 'Name',
  updatedAt: 'Updated',
};

export const defaultColumns = [
  {
    id: 'name',
    value: 'name',
    label: 'Name',
  },
  {
    id: 'transactionId',
    value: 'transactionId',
    label: 'Transaction ID',
  },
  {
    id: 'createdAt',
    value: 'createdAt',
    label: 'Date of creation',
  },
  {
    id: 'status',
    value: 'status',
    label: 'Status',
  },
];
