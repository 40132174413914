const { get } = require('lodash');

const translateCodeAndMessageToFlag = (code, message, originalUrl, flagConfig) => {
  if (code === '200') return null;
  const urlFlags = get(flagConfig, ['flagsMapping', originalUrl], {});
  const codeTranslation = get(urlFlags, code, null);
  const messageTranslation = get(urlFlags, message, null);
  if (codeTranslation) return codeTranslation;
  if (messageTranslation) return messageTranslation;
  if (message) return message;
  return null;
};

export default translateCodeAndMessageToFlag;
