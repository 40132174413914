import * as Sentry from '@sentry/react';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';
import moment from 'moment';
import {
  getFilterConfigApi,
  getFlagConfigApi,
  getUsageConfigApi,
  getTransactionMetadataColumnsConfigApi,
} from '../../api/s3Apis';
import { updateConfig } from '../../reducers/s3Config';

// In seconds
const S3_CONFIG_CACHE_REFRESH_TIME = process.env.S3_CONFIG_CACHE_REFRESH_TIME || 15 * 60;

const s3ConfigKeyToApiMap = {
  filterConfig: getFilterConfigApi,
  usageConfig: getUsageConfigApi,
  flagConfig: getFlagConfigApi,
  transactionMetadataColumnsConfig: getTransactionMetadataColumnsConfigApi,
};

const getApiFuncForConfigKey = (configKey) => get(s3ConfigKeyToApiMap, configKey, () => ({}));

const useS3Config = (configKey) => {
  const dispatch = useDispatch();
  const s3Config = useSelector((state) => state.s3Config);

  const getConfig = async () => {
    try {
      const { lastUpdatedAt, configData } = get(s3Config, configKey, {});
      const lastUpdatedAtMomentObj = moment(lastUpdatedAt);
      const currentDate = moment(new Date());
      const timeDifference = moment
        .duration(currentDate.diff(lastUpdatedAtMomentObj))
        .asSeconds();
      if (!lastUpdatedAt || timeDifference > S3_CONFIG_CACHE_REFRESH_TIME) {
        const getConfigApiFunc = getApiFuncForConfigKey(configKey);
        const res = await getConfigApiFunc();
        const resData = get(res, 'data', {});
        dispatch(updateConfig({ configKey, configData: resData }));
        return resData;
      }
      return configData;
    } catch (error) {
      Sentry.captureException(`Error fetching ${configKey} config - ${error}`);
      return {};
    }
  };

  return getConfig;
};

export default useS3Config;
