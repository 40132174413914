import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import React from 'react';
import PropTypes from 'prop-types';
import { formatDateForDropdown } from '../../../utils/dateFormats';
import './Inputs.scss';
import CustomPopupContainer from '../Popup/CustomPopupContainer';
import DateButton from './DateButton';
import DatePicker from './DatePicker';

function DateDropdown({ startTime, endTime, handlePick }) {
  return (
    <CustomPopupContainer
      popupTrigger={<DateButton label={formatDateForDropdown(startTime, endTime)} />}
      popupComponent={(
        <DatePicker
          startTime={startTime}
          endTime={endTime}
          handlePick={handlePick}
        />
      )}
    />
  );
}

DateDropdown.propTypes = {
  startTime: PropTypes.string.isRequired,
  endTime: PropTypes.string.isRequired,
  handlePick: PropTypes.func.isRequired,
};

export default DateDropdown;
