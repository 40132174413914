import React, { useState } from 'react';
import { Document, Page } from 'react-pdf';
import PropTypes from 'prop-types';

import './PDF.scss';

function PDFRender({
  id,
  className,
  documentClassName,
  pageClassName,
  src,
  showControls,
  onClick,
  height,
  onDocumentLoadComplete,
}) {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  // Do nothing for now
  const onPassword = () => {};

  const changePage = (offset) => {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  };

  const previousPage = () => {
    if (pageNumber === 1) return;
    changePage(-1);
  };

  const nextPage = () => {
    if (pageNumber === numPages) return;
    changePage(1);
  };

  const onPageRenderSuccess = () => {
    onDocumentLoadComplete();
  };

  const onPageRenderError = () => {
    onDocumentLoadComplete();
  };

  const onPageLoadError = () => {
    onDocumentLoadComplete();
  };

  const onDocumentLoadSuccess = ({ numPages: nextNumPages }) => {
    setNumPages(nextNumPages);
  };

  const onDocumentLoadError = () => {
    onDocumentLoadComplete();
  };

  return (
    <div
      data-hj-suppress
      id={id}
      style={{ height }}
      className={`common_pdf_renderer__container ${className}`}
      onClick={onClick}
      aria-hidden
    >
      <Document
        file={src}
        className={`common_pdf_renderer__document ${documentClassName}`}
        onLoadSuccess={onDocumentLoadSuccess}
        onLoadError={onDocumentLoadError}
        onItemClick={onClick}
        noData=""
        loading=""
        error="Error loading PDF"
        onPassword={onPassword}
      >
        <Page
          pageNumber={pageNumber}
          className={`common_pdf_renderer__page ${pageClassName}`}
          onRenderSuccess={onPageRenderSuccess}
          onRenderError={onPageRenderError}
          onLoadError={onPageLoadError}
          loading=""
        />
        {showControls && (
          <div className="common_pdf_renderer__controls">
            <button type="button" onClick={previousPage}>
              ‹
            </button>
            <span>
              {pageNumber || (numPages ? 1 : '--')}
              {' '}
              of
              {' '}
              {numPages || '--'}
            </span>
            <button type="button" onClick={nextPage}>
              ›
            </button>
          </div>
        )}
      </Document>
    </div>
  );
}

PDFRender.defaultProps = {
  id: '',
  className: '',
  documentClassName: '',
  pageClassName: '',
  showControls: true,
  onClick: () => {},
  height: null,
  onDocumentLoadComplete: () => {},
};

PDFRender.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  documentClassName: PropTypes.string,
  pageClassName: PropTypes.string,
  src: PropTypes.string.isRequired,
  showControls: PropTypes.bool,
  onClick: PropTypes.func,
  height: PropTypes.string,
  onDocumentLoadComplete: PropTypes.func,
};

export default PDFRender;
