export const arrayMove = (arr, oldIndex, newIndex) => {
  if (newIndex >= arr.length) {
    let k = newIndex - arr.length + 1;
    while (k) {
      k -= 1;
      arr.push(undefined);
    }
  }
  arr.splice(newIndex, 0, arr.splice(oldIndex, 1)[0]);
  return arr;
};

export const findIndexAndMove = (arr, sourceValue, targetValue) => {
  const sourceIndex = arr.findIndex(
    (col) => col.value === sourceValue,
  );
  const targetIndex = arr.findIndex(
    (col) => col.value === targetValue,
  );
  arrayMove(arr, sourceIndex, targetIndex);
  return arr;
};
