import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import Link from '@mui/material/Link';
import VisibilityIcon from '@mui/icons-material/Visibility';
import TrustedIcon from '../../assests/images/trusted.png';
import CommonModal from './CommonModal.js';
import './Demo.scss';

export default function ResponseModal(
  {
    config,
    open,
    applicationPresent,
    isDemo,
  },
) {
  const { heading, subHeading, icon } = config;
  const [demoLink, setDemoLink] = useState('');
  const { search } = useLocation();

  const currentTransactionId = new URLSearchParams(search).get('id');
  const applicationLink = `/record?transactionId=${currentTransactionId}`;

  const newDemoLink = () => {
    const transactionId = `${(Math.random().toString(36).slice(2))}_DEMO`;
    const url = new URL(window.location.href);
    url.searchParams.set('id', transactionId);
    setDemoLink(url.href);
  };

  useEffect(() => {
    if (open) newDemoLink();
  }, [open]);

  return (
    <CommonModal open={open} heading={heading} subHeading={subHeading}>
      <div id="response_modal__body">
        <div id="response_modal__image_container">
          <img src={icon} alt="clapping hands" />
        </div>
        {isDemo && (
          <div id="response_modal__bottom_box">
            <div id="response_modal__visiblity_text">
              <VisibilityIcon id="response_modal__visiblity_icon" sx={{ fontSize: 'small' }} />
              only visible during testing
            </div>
            <br />
            <div>
              If you&apos;d like to retry the workflow please
              <Link href={demoLink} id="response_modal__retry_link">
                {' '}
                retry here
              </Link>
            </div>
            <br />
            {applicationPresent && !isMobile ? (
              <div>
                View this application
                <Link href={applicationLink} id="response_modal__retry_link" target="_blank">
                  {' '}
                  on the dashboard
                </Link>
              </div>
            ) : <></>}
          </div>
        )}
        <footer id="response_modal__footer">
          <img
            src={TrustedIcon}
            alt="Trust"
            style={{
              fontSize: 'small', marginRight: '5px',
            }}
          />
          {' '}
          <p>
            Powered by HyperVerge
          </p>
        </footer>
      </div>
    </CommonModal>
  );
}

ResponseModal.propTypes = {
  config: PropTypes.shape({
    heading: PropTypes.string.isRequired,
    subHeading: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
  }).isRequired,
  open: PropTypes.bool.isRequired,
  isDemo: PropTypes.bool.isRequired,
  applicationPresent: PropTypes.bool.isRequired,
};
