import Grid from '@mui/material/Grid';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { PermissionWrapper } from 'storybook-ui-components';
import { useDispatch, useSelector } from 'react-redux';
import { Popover } from '@mui/material';
import Box from '@mui/material/Box';
import { useLocation, useNavigate } from 'react-router-dom';
import { get } from 'lodash';
import * as Sentry from '@sentry/react';
import SearchBar from './SearchBar';
import DataTable from './DataTable';
import StatusDropdown from './StatusCodeDropdown';
import ProductDropDown from './ProductDropDown';
import AlertCode from '../../../constants/alertCodes';
import RefreshButton from '../../Common/RefreshButton';
import FilterByButton from '../../Common/FilterByButton';
import useShowErrorAlert from '../../../utils/lib';
import { constants } from '../../../config';
import { updateFilterData, updateData, updateTableMeta } from '../../../reducers/review';
import DateDropdown from '../../Common/Inputs/DateDropdown';
import useGetUserPermissions from '../../../Permissions/hooks';
import getPermission from '../../../Permissions/mapping';
import errorCode from '../../../constants/errorCode';

axios.defaults.withCredentials = true;

const boxStyle = {
  padding: 1,
  borderRadius: 2,
};

function Review() {
  const dispatch = useDispatch();
  const showErrorAlert = useShowErrorAlert();

  const reviewData = useSelector((state) => state.review.data);
  const reviewDataCount = useSelector((state) => state.review.dataCount);
  const reviewFilterData = useSelector((state) => state.review.filterData);
  const { product, statusCode, timePeriod } = reviewFilterData;
  const { startTime, endTime } = timePeriod;
  const currentAppId = useSelector((state) => state.user.credState?.current?.appId);
  const tableMeta = useSelector((state) => state.review.tableMeta);
  const { page, selectedColumns } = tableMeta;
  const location = useLocation();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const landingPage = location.state?.page;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const fetchProducts = async () => {
    let fetchedProductList;
    try {
      const dataRes = await axios({
        method: 'GET',
        url: `${process.env.REACT_APP_SERVER_URL}/api/v1/review/product`,
        headers: { appId: currentAppId },
      });
      fetchedProductList = get(dataRes, 'data.result', []);
      dispatch(updateFilterData({
        productList: fetchedProductList,
      }));
    } catch (error) {
      Sentry.captureException(`${errorCode.FETCH_PRODUCTS} - ${error}`, {
        extra: {
          errorMessage: AlertCode.FETCH_TABLE_DATA,
        },
      });
      dispatch(updateFilterData({
        productList: fetchedProductList,
      }));
      dispatch(updateData({}));
      showErrorAlert({ error, message: AlertCode.FETCH_TABLE_DATA });
    }
  };

  const fetchTableData = async ({ requestId }) => {
    try {
      const dataRes = await axios({
        method: 'POST',
        url: `${process.env.REACT_APP_SERVER_URL}/api/v1/review/filter`,
        headers: { appId: currentAppId },
        data: {
          requestId,
          endTime,
          statusCode,
          startTime,
          page,
          rows: constants.maxRecordsInTable,
          product,
        },
      });
      const data = get(dataRes, 'data.result.data', []);
      return { data };
    } catch (error) {
      Sentry.captureException(`${errorCode.FETCH_TABLE_DATA} - ${error}`, {
        extra: {
          errorMessage: AlertCode.FETCH_TABLE_DATA,
        },
      });
      return { error };
    }
  };

  const fetchRowCount = async ({ requestId }) => {
    try {
      const res = await axios({
        method: 'POST',
        url: `${process.env.REACT_APP_SERVER_URL}/api/v1/review/count`,
        headers: { appId: currentAppId },
        data: {
          requestId,
          endTime,
          statusCode,
          startTime,
          product,
        },
      });
      const count = get(res, 'data.result.count', []);
      return { count };
    } catch (error) {
      Sentry.captureException(`${errorCode.FETCH_ROW_COUNT} - ${error}`, {
        extra: {
          errorMessage: AlertCode.FETCH_ROW_COUNT,
        },
      });
      return { error };
    }
  };

  const handleDataChange = async ({ requestId }) => {
    setLoading(true);
    const [countRes, filterRes] = await Promise.all([
      await fetchRowCount({ requestId }),
      await fetchTableData({ requestId }),
    ]);
    const { count, error: countError } = countRes;
    if (countError) {
      dispatch(updateData({}));
      Sentry.captureException(`${errorCode.APPLICATION_COUNT_ERROR} - ${countError}`, {
        extra: {
          errorMessage: countError,
        },
      });
      return showErrorAlert({ error: countError, message: AlertCode.FETCH_ROW_COUNT });
    }
    const { data, error: dataError } = filterRes;
    if (dataError) {
      dispatch(updateData({}));
      Sentry.captureException(`${errorCode.APPLICATION_DATA_ERROR} - ${dataError}`, {
        extra: {
          errorMessage: dataError,
        },
      });
      return showErrorAlert({ error: dataError, message: AlertCode.FETCH_TABLE_DATA });
    }
    if (page * constants.maxRecordsInTable > count) {
      dispatch(updateTableMeta({
        page: 0,
      }));
    } else {
      dispatch(updateData({
        data, count,
      }));
    }
    return setLoading(false);
  };

  const handleRowClick = (row) => {
    navigate(`/view?requestId=${encodeURIComponent(row.requestId.data.id)}`, {
      state: {
        currentElement: row.requestId.data.rowId,
      },
    });
  };

  const handlePageChange = async (event, newPage) => {
    dispatch(updateTableMeta({
      page: newPage - 1,
    }));
  };

  const handleTimePeriodSelect = (startDate, endDate) => {
    dispatch(updateFilterData({ timePeriod: { startTime: startDate, endTime: endDate } }));
  };

  useEffect(() => {
    handleDataChange({});
  }, [currentAppId, product, statusCode, startTime, endTime, page]);

  useEffect(() => {
    if (landingPage) {
      dispatch(updateTableMeta({
        page: landingPage,
      }));
    }
  }, [landingPage]);

  useEffect(() => {
    fetchProducts();
  }, [currentAppId, startTime, endTime]);

  return (
    <div id="audit__data_container">
      <Grid container>
        <Grid item xs={12} id="audit__filter_container">
          <SearchBar
            handleDataChange={handleDataChange}
            text="Search By Request Id"
            searchType="requestId"
          />
          <DateDropdown
            handlePick={handleTimePeriodSelect}
            startTime={startTime}
            endTime={endTime}
          />
          <FilterByButton onClick={handleClick} />
          <p id="audit__element_count">
            {reviewDataCount}
            {' '}
            results
          </p>
          <RefreshButton onClick={() => handleDataChange({})} />
        </Grid>
        <Grid item xs={12}>
          <DataTable
            handlePageChange={handlePageChange}
            handleRowClick={handleRowClick}
            tableColumns={selectedColumns}
            isLoading={loading}
            page={page}
            data={reviewData}
            totalElements={reviewDataCount}
          />
        </Grid>
      </Grid>
      <div>
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <Box sx={boxStyle}>
            <ProductDropDown />
            <StatusDropdown />
          </Box>
        </Popover>
      </div>
    </div>
  );
}

export default PermissionWrapper(Review, useGetUserPermissions, getPermission('reviewPage'));
