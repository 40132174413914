import React from 'react';
import { useSelector } from 'react-redux';

function Environment() {
  const currentAppIdType = useSelector((state) => state.user.credState?.current?.type);
  if (!currentAppIdType) return '-';
  const formattedAppIdType = currentAppIdType === 'STAGING' ? 'Staging' : 'Production';
  return (
    <>
      {formattedAppIdType}
    </>
  );
}

export default Environment;
