import {
  get, groupBy, isEmpty,
} from 'lodash';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateCredState } from '../../../reducers/user';
import CommonSelect from '../Inputs/CommonSelect';

const STAGING_KEY = 'STAGING';
const PRODUCTION_KEY = 'PRODUCTION';
const label = 'Choose An AppId';

function AppIdDropdown() {
  const credMapping = useSelector((state) => state.user.credState?.credMapping);

  const dispatch = useDispatch();

  const currentClientId = useSelector((state) => state.user.credState?.current?.clientId);
  const currentUseCase = useSelector((state) => state.user.credState?.current?.useCase);
  const currentAppId = useSelector((state) => state.user.credState?.current?.appId);
  const currentType = useSelector((state) => state.user.credState?.current?.type);

  const currentAppIdList = get(credMapping, [currentClientId, currentUseCase], {});

  if (isEmpty(currentAppIdList)) {
    return null;
  }

  const typeGropedCreds = groupBy(currentAppIdList, ({ type }) => type);
  const prodCreds = get(typeGropedCreds, 'PRODUCTION', []);
  const stagingCreds = get(typeGropedCreds, 'STAGING', []);

  const appIdOptions = currentAppIdList.map(({ appId }) => ({
    label: appId,
    value: appId,
  }));

  const handleAppIdChange = (value) => {
    const { type } = get(credMapping, [currentClientId, currentUseCase, value], {});
    dispatch(updateCredState({ appId: value, type }));
  };

  const toggleHandler = (key, value, isDisabled) => {
    if (isDisabled) return;
    const { appId, type } = value || {};
    dispatch(updateCredState({
      appId, type,
    }));
  };

  const displayComponentRenderer = () => (
    <>
      <>
        in |
        {' '}
      </>
      {currentAppId}
    </>
  );

  if (prodCreds.length > 1 || stagingCreds.length > 1) {
    return (
      <CommonSelect
        options={appIdOptions}
        label={label}
        handleChange={handleAppIdChange}
        initValue={currentAppId}
        displayComponentRenderer={displayComponentRenderer}
      />
    );
  }

  const productionAppId = prodCreds.length > 0 ? prodCreds[0] : null;
  const stagingAppId = stagingCreds.length > 0 ? stagingCreds[0] : null;

  const options = {
    [STAGING_KEY]: {
      isDisabled: !stagingAppId,
      value: stagingAppId,
      label: 'Staging',
    },
    [PRODUCTION_KEY]: {
      isDisabled: !productionAppId,
      value: productionAppId,
      label: 'Production',
    },
  };
  return (
    <div className="appid__toggle_container">
      {Object.entries(options).map(([key, { value, isDisabled, label: credLabel }]) => (
        <button
          key={key}
          type="button"
          onClick={() => toggleHandler(key, value, isDisabled)}
          className={`${currentType === key ? 'active' : ''} ${isDisabled ? 'disabled' : ''}`}
          title={isDisabled && key === PRODUCTION_KEY ? 'You do not have access to Production. Please contact HyperVerge support.' : ''}
        >
          {credLabel}
        </button>
      ))}
    </div>
  );
}

export default AppIdDropdown;
