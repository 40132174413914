import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Flags from './Flags';
import Header from './Header';
import SdkError from './SdkError';
import { APPLICATION_STATUS_MAP } from '../../../../constants/applicationStatus';
import FailureReason from './FailureReason';

function ReviewCard({
  transactionData, selectedModule, handleModuleChange,
}) {
  const failureReason = useSelector((state) => state.appDetails.failureReason);
  const flagData = useSelector((state) => state.appDetails.flagData);
  const v2FlagData = useSelector((state) => state.appDetails.v2FlagData);
  const { status, sdkError } = transactionData;

  const getReviewCardComponent = () => {
    if (status === APPLICATION_STATUS_MAP.ERROR) {
      return <SdkError errorMsg={sdkError} />;
    }

    if (failureReason) {
      return <FailureReason failureReason={failureReason} />;
    }

    return (
      <Flags
        flags={flagData}
        v2Flags={v2FlagData}
        selectedModule={selectedModule}
        handleModuleChange={handleModuleChange}
      />
    );
  };
  return (
    <div id="right_panel__review_card__container">
      <Header status={status} />
      {getReviewCardComponent()}
    </div>
  );
}

ReviewCard.propTypes = {
  transactionData: PropTypes.object.isRequired,
  selectedModule: PropTypes.string.isRequired,
  handleModuleChange: PropTypes.func.isRequired,
};

export default ReviewCard;
