/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { startOfDay, endOfDay, addDays } from 'date-fns';

const initTableColumns = [
  {
    id: 'requestId',
    value: 'requestId',
    label: 'Request Id',
    minWidth: 100,
  },
  {
    id: 'originalUrl',
    value: 'originalUrl',
    label: 'EndPoint',
    minWidth: 100,
  },
  {
    id: 'product',
    value: 'product',
    label: 'Product',
    minWidth: 100,
  },
  {
    id: 'statusCode',
    value: 'statusCode',
    label: 'Status Code',
    minWidth: 100,
  },
  {
    id: 'responseTime',
    value: 'responseTime',
    label: 'Response Time',
    minWidth: 100,
  },
];

const initData = {
  data: [],
  count: 0,
};

const initTimePeriod = {
  // format: 2022-04-11T18:30:00.000Z
  startTime: startOfDay(addDays(new Date(), -14)),
  endTime: endOfDay(new Date()),
};

const initFilterData = {
  product: '',
  productList: [],
  statusCode: '',
  timePeriod: initTimePeriod,
};

const initTableMeta = {
  page: 0,
  selectedColumns: initTableColumns,
};

export const dataSlice = createSlice({
  name: 'review',
  initialState: {
    data: initData.data,
    dataCount: initData.count,
    filterData: initFilterData,
    tableMeta: initTableMeta,
  },
  reducers: {
    updateData: (state, action) => {
      const { data, count } = action.payload;
      state.data = data || initData.data;
      state.dataCount = count || initData.count;
    },
    updateFilterData: (state, action) => {
      Object.entries(action.payload).forEach(([key, value]) => {
        state.filterData[key] = value || initFilterData[key];
      });
    },
    updateTableMeta: (state, action) => {
      Object.entries(action.payload).forEach(([key, value]) => {
        state.tableMeta[key] = value || initTableMeta[key];
      });
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  updateData, updateFilterData, updateTableMeta,
} = dataSlice.actions;
export default dataSlice.reducer;
