import React from 'react';
import * as Sentry from '@sentry/react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { persistor } from '../store';
import errorCode from '../constants/errorCode';

const logout = async () => {
  try {
    await persistor.purge();
    await axios({
      method: 'GET',
      url: `${process.env.REACT_APP_SERVER_URL}/api/v1/user/logout`,
    });
  } catch (error) {
    Sentry.captureException(`${errorCode.UNHANDLED_ERROR} - ${error}`);
    console.log(error);
  }
  window.location = window.location.origin;
};

class ErrorBoundary extends React.Component {
  static getDerivedStateFromError() {
    return { hasError: true };
  }

  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, errorInfo) {
    console.log(error, errorInfo);
    Sentry.captureException(`${errorCode.UNHANDLED_ERROR} - ${error}`);
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;
    if (hasError) {
      logout();
      return <h2>Something went wrong. Please wait while were refresh</h2>;
    }
    return children;
  }
}
ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ErrorBoundary;
