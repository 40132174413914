/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import ImagePlaceholder from '../../../assests/images/imagePlaceholder.png';

import './Image.scss';

const defaultZoomScale = 3;

function ImageZoom({
  id, className, src, alt, height, zoomScale, onClick,
}) {
  const [zoom, setZoom] = useState(false);
  const [transformOrigin, setTransformOrigin] = useState('');
  const imageContainerRef = useRef(null);

  const handleMouseOver = () => {
    setZoom(true);
  };

  const handleMouseOut = () => {
    setZoom(false);
  };

  const handleMouseMovement = (e) => {
    const {
      left: offsetLeft,
      top: offsetTop,
    } = imageContainerRef.current.getBoundingClientRect();
    const mouseX = (e.pageX - offsetLeft);
    const mouseY = (e.pageY - offsetTop);
    setTransformOrigin(`${mouseX}px ${mouseY}px`);
  };

  const onImageLoadError = (e) => {
    e.target.src = ImagePlaceholder;
  };

  return (
    <div
      data-hj-suppress
      id={id}
      className={`${className} common_zoom__container`}
      style={{
        height,
      }}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
      onMouseMove={handleMouseMovement}
      ref={imageContainerRef}
      onClick={onClick}
      aria-hidden
    >
      <img
        src={src}
        alt={alt}
        style={{
          transform: zoom ? `scale(${zoomScale})` : 'scale(1.0)', transformOrigin, height,
        }}
        onError={onImageLoadError}
      />
    </div>
  );
}

ImageZoom.defaultProps = {
  id: '',
  className: '',
  alt: '',
  height: null,
  zoomScale: defaultZoomScale,
  onClick: () => {},
};

ImageZoom.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  src: PropTypes.string.isRequired,
  alt: PropTypes.string,
  height: PropTypes.string,
  zoomScale: PropTypes.number,
  onClick: PropTypes.func,
};

export default ImageZoom;
