import React from 'react';
import PropTypes from 'prop-types';
import ImageViewer from './ImageViewer';

const defaultMaxZoomScale = 1.5;
const defaultMinZoomScale = 1;
const defaultScaleStepSize = 0.25;

function ImageViewerOpenWrapper({
  open,
  id,
  images,
  defaultImageIndex,
  closeViewerHandler,
  imageMaxZoomScale,
  imageMinZoomScale,
  imageScaleStepSize,
}) {
  if (!open) {
    return '';
  }
  return (
    <ImageViewer
      id={id}
      images={images}
      defaultImageIndex={defaultImageIndex}
      closeViewerHandler={closeViewerHandler}
      imageMaxZoomScale={imageMaxZoomScale}
      imageMinZoomScale={imageMinZoomScale}
      imageScaleStepSize={imageScaleStepSize}
    />
  );
}

ImageViewerOpenWrapper.defaultProps = {
  id: '',
  images: [],
  defaultImageIndex: 0,
  imageMaxZoomScale: defaultMaxZoomScale,
  imageMinZoomScale: defaultMinZoomScale,
  imageScaleStepSize: defaultScaleStepSize,
};

ImageViewerOpenWrapper.propTypes = {
  open: PropTypes.bool.isRequired,
  id: PropTypes.string,
  images: PropTypes.array,
  defaultImageIndex: PropTypes.number,
  closeViewerHandler: PropTypes.func.isRequired,
  imageMaxZoomScale: PropTypes.number,
  imageMinZoomScale: PropTypes.number,
  imageScaleStepSize: PropTypes.number,
};

export default ImageViewerOpenWrapper;
