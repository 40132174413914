import { get } from 'lodash';
import React from 'react';
import DynamicCard from '../RecordDetails/Cards/DynamicCard/Card';
import DbChecks from '../RecordDetails/Details/DbChecks';
import Default from '../RecordDetails/Details/Default';
import FaceMatch from '../RecordDetails/Details/FaceMatch';
import IdVerification from '../RecordDetails/Details/IdVerification';
import SelfieVerification from '../RecordDetails/Details/SelfieVerification';
import AMLChecks from '../RecordDetails/Details/AMLChecks';
import VideoCard from '../RecordDetails/Details/VideoCard';
import formatData from '../../../utils/formatDataForPanel';
import MultiTableCard from '../RecordDetails/Details/MultiTableCard';
import VideoCardV2 from '../RecordDetails/Details/VideoCardV2';
import GenericCardTypeV1 from '../RecordDetails/Cards/GenericCards/GenericCardTypeV1/GenericCardTypeV1';
import GenericCardTypeV2 from '../RecordDetails/Cards/GenericCards/GenericCardTypeV2';

const cardTypeToComponentMapping = {
  type1: <SelfieVerification />,
  type2: <IdVerification />,
  type3: <DbChecks />,
  type4: <FaceMatch />,
  type5: <AMLChecks />,
  dynamicCard: <DynamicCard />,
  videoCardType1: <VideoCard />,
  videoCardType2: <VideoCardV2 />,
  multiTableCard: <MultiTableCard />,
  genericCardTypeV1: <GenericCardTypeV1 />,
  genericCardV2: <GenericCardTypeV2 />,
};

const getComponentForCardType = (cardType) => get(cardTypeToComponentMapping,
  cardType, <Default />);

const nonFormattableCardTypes = ['', 'default', 'dynamicCard', 'videoCardType1', 'videoCardType2', 'multiTableCard', 'genericCardTypeV1', 'genericCardV2'];

export const formatRecordsForCardTypes = (type, data) => {
  if (nonFormattableCardTypes.includes(type)) return data;
  return formatData(data);
};

export default getComponentForCardType;
