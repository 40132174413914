import React from 'react';
import PropTypes from 'prop-types';
import HorizontalLine from '../../../../../../Common/Utils/HorizontalLine';
import Navigation from './Navigation';
import Review from './Review';

function Footer({
  currentHitIndex,
  totalHits,
  setCurrentHitIndex,
  availableData,
  moduleId,
}) {
  if (totalHits === 0) {
    return null;
  }

  return (
    <div className="card_type_five__footer__container">
      <Navigation
        currentHitIndex={currentHitIndex}
        totalHits={totalHits}
        setCurrentHitIndex={setCurrentHitIndex}
      />
      <HorizontalLine marginTop="16px" marginBottom="16px" />
      <Review
        availableData={availableData}
        moduleId={moduleId}
      />
    </div>
  );
}

Footer.propTypes = {
  currentHitIndex: PropTypes.number.isRequired,
  totalHits: PropTypes.number.isRequired,
  setCurrentHitIndex: PropTypes.func.isRequired,
  availableData: PropTypes.object.isRequired,
  moduleId: PropTypes.string.isRequired,
};

export default Footer;
