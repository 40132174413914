import React from 'react';
import PropTypes from 'prop-types';
import { uniqueId } from 'lodash';

import '../../Onboarding/DefaultOnboard.scss';
import PasswordValidationRow from './PasswordValidationRow';

const passwordValidationTextMap = {
  isLengthValid: '8 - 20 characters',
  isLowerCaseValid: 'At least one lowercase character',
  isUpperCaseValid: 'At least one uppercase character',
  isNumericValid: 'At least one numeric character',
  isSpecialCharValid: "Alphanumeric character such as - ' @#$%^z&*() '",
};

const PasswordModalTrigger = ({ className, passwordValidationObject }) => (
  <div className={className}>
    <h2>Password must have</h2>
    {
    Object.keys(passwordValidationTextMap).map((key) => (
      <PasswordValidationRow
        key={uniqueId()}
        isValid={passwordValidationObject[key]}
        text={passwordValidationTextMap[key]}
      />
    ))
}
  </div>
);

PasswordModalTrigger.propTypes = {
  passwordValidationObject: PropTypes.object.isRequired,
  className: PropTypes.string.isRequired,
};

export default React.memo(PasswordModalTrigger);
