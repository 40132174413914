import React from 'react';
import PropTypes from 'prop-types';
import { TextField, capitalize } from '@mui/material';
import './NewApplication.scss';

const ApplicationInput = ({ input, handleInputChange }) => (
  <>
    <p id="new_application_modal__body_container__input_label">
      Enter
      {' '}
      { input }
    </p>
    <TextField
      data-hj-suppress
      id="new_application_modal__body_container__input"
      size="small"
      color="secondary"
      placeholder={capitalize(input)}
      onChange={(event) => handleInputChange(event, input)}
    />
  </>
);

ApplicationInput.propTypes = {
  input: PropTypes.string.isRequired,
  handleInputChange: PropTypes.func.isRequired,
};

export default ApplicationInput;
