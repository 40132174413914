import React from 'react';
import PropTypes from 'prop-types';

import WorkflowCell from '../../../Main/Application/WorkflowCell';

function Workflow({ value }) {
  if (value === '-') return value;
  return (
    <WorkflowCell workflowId={value} shouldShowVersioning />
  );
}

Workflow.propTypes = {
  value: PropTypes.string.isRequired,
};

export default Workflow;
