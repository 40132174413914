/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import DemoModal from '../Home/DemoModal';
import emptyApplication from '../../../assests/images/emptyApplication.png';
import arrow from '../../../assests/icons/arrowSVG.svg';
import './Audit.scss';
import { useFormatAnalyticsData } from '../../../utils/lib';
import rudderstackEvents from '../../../constants/rudderstackEventNames';

export default function EmptyState() {
  const [openPopup, setOpenPopup] = useState(false);
  const email = useSelector((state) => state.user.email);
  const clientId = useSelector((state) => state.user.clientId);
  const formatAnalyticsData = useFormatAnalyticsData();

  return (
    <>
      <DemoModal open={openPopup} closePopupWindow={() => setOpenPopup(false)} />
      <Grid container id="application__empty_state__container">
        <img src={emptyApplication} alt="folder" />
        <p id="application__empty_state__info_text">Application from customers come here</p>
        {window.location.origin !== 'https://dashboard.hyperverge.co/'
          ? (
            <Link
              onClick={() => {
                formatAnalyticsData(email, clientId, rudderstackEvents.DASHBOARD_APP_NEW_APP_EMPTY_STATE_CLICK, 'Home');

                setOpenPopup(true);
              }}
              id="application__empty_state__demo_text"
            >
              Create a new application
              <img src={arrow} alt="arrow" id="application__empty_state__demo_arrow" />
            </Link>
          )
          : null}
      </Grid>
    </>
  );
}
