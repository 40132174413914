import React from 'react';
import PropTypes from 'prop-types';
import PDFViewer from './PDFViewer';

function PDFViewerOpenWrapper({
  id,
  open,
  pdf,
  closeViewerHandler,
}) {
  if (!open) {
    return '';
  }

  return (
    <PDFViewer
      id={id}
      pdf={pdf}
      closeViewerHandler={closeViewerHandler}
    />
  );
}

PDFViewerOpenWrapper.defaultProps = {
  id: '',
  pdf: {},
};

PDFViewerOpenWrapper.propTypes = {
  id: PropTypes.string,
  open: PropTypes.bool.isRequired,
  pdf: PropTypes.object,
  closeViewerHandler: PropTypes.func.isRequired,
};

export default PDFViewerOpenWrapper;
