/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const s3ConfigSlice = createSlice({
  name: 's3Config',
  initialState,
  reducers: {
    updateConfig: (state, action) => {
      const { configKey, configData } = action.payload;
      const lastUpdatedAt = new Date().toISOString();
      return { ...state, [configKey]: { lastUpdatedAt, configData } };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  updateConfig,
} = s3ConfigSlice.actions;
export default s3ConfigSlice.reducer;
