import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';
import ForwardButtonIcon from '../../../assests/icons/forward_button.svg';
import BackwardButtonIcon from '../../../assests/icons/backward_button.svg';
import PlayButtonIcon from '../../../assests/icons/play_button.svg';
import PauseButtonIcon from '../../../assests/icons/pause_button.svg';
import PrettoSlider from './PrettoSlider';

import './Video.scss';

const VideoPlayerOpenWrapper = ({
  open, videos, defaultVideoIndex, closeViewerHandler,
}) => {
  const videoPlayerRef = useRef();
  const [playbackRate, setPlaybackRate] = useState(1);
  const [videoState, setVideoState] = useState({
    playing: true,
    muted: false,
    volume: 0.5,
    played: 0,
    seeking: false,
    Buffer: true,
  });

  const playPauseHandler = (event) => {
    event.stopPropagation();
    setVideoState({ ...videoState, playing: !videoState.playing });
  };

  const rewindHandler = (event) => {
    event.stopPropagation();
    videoPlayerRef.current.seekTo(videoPlayerRef.current.getCurrentTime() - 10);
  };

  const fastFowardHandler = (event) => {
    event.stopPropagation();
    videoPlayerRef.current.seekTo(videoPlayerRef.current.getCurrentTime() + 10);
  };

  const getTime = (seconds) => {
    if (seconds === null || seconds === undefined || String(seconds) === 'Infinity') return '00:00';
    return new Date(seconds * 1000).toISOString().slice(14, 19);
  };

  const progressHandler = (state) => {
    if (!videoState.seeking) {
      setVideoState({ ...videoState, ...state });
    }
  };

  const seekMouseUpHandler = (event, evalue) => {
    event.stopPropagation();
    setVideoState({ ...videoState, seeking: false });
    videoPlayerRef.current.seekTo(evalue / 100);
  };

  const seekHandler = (event, evalue) => {
    event.stopPropagation();
    setVideoState({ ...videoState, played: parseFloat(evalue) / 100, seeking: true });
    seekMouseUpHandler(event, evalue);
  };

  const changePlaybackRate = (event) => {
    event.stopPropagation();
    setPlaybackRate((prev) => (prev === 1 ? 2 : 1));
  };

  if (!open) {
    return '';
  }

  return (
    <div data-hj-suppress aria-hidden onClick={closeViewerHandler} id="video_player__container">
      <div id="video_player__player">
        <ReactPlayer
          playing={videoState.playing}
          ref={videoPlayerRef}
          url={videos[defaultVideoIndex].src}
          height="100%"
          width="100%"
          loop
          onProgress={progressHandler}
          playbackRate={playbackRate}
          // This is currently being done due to lack of metadata on the videos with webm format due
          // Due to this lack of information, the duration comes out to be Infinity
          // We're tricking the player into loading the entire video so we know the exact duration
          // This is a hack which needs to be fixed in future.
          onDuration={(duration) => {
            if (duration === Infinity) {
              videoPlayerRef?.current?.seekTo(10000000);
              setTimeout(() => {
                videoPlayerRef?.current?.seekTo(0);
              }, 1000);
            }
          }}
        />
      </div>

      <div id="video_player__controls">
        <PrettoSlider
          onChange={seekHandler}
          onChangeCommitted={seekMouseUpHandler}
          min={0}
          max={100}
          value={videoState.played * 100}
        />
        <div id="video_details_controls_div">
          <p id="video_details_controls_div_info">
            {getTime(videoPlayerRef?.current?.getCurrentTime())}
            {' '}
            /
            {' '}
            {getTime(videoPlayerRef?.current?.getSecondsLoaded())}
          </p>
          <div id="video_details_controls_div_parent">
            <div aria-hidden onClick={rewindHandler} id="video_details_controls_div_icon">
              <img src={BackwardButtonIcon} alt="Rewind Button" />
            </div>
            {videoState.playing ? (
              <div aria-hidden onClick={playPauseHandler} id="video_details_controls_div_icon">
                <img src={PauseButtonIcon} alt="Pause Button" />
              </div>
            ) : (
              <div aria-hidden onClick={playPauseHandler} id="video_details_controls_div_icon">
                <img src={PlayButtonIcon} alt="Play Button" />
              </div>
            )}
            <div aria-hidden onClick={fastFowardHandler} id="video_details_controls_div_icon">
              <img src={ForwardButtonIcon} alt="Forward Button" />
            </div>
          </div>
          <div id="video_details_controls_div_icon_playback">
            <p aria-hidden onClick={changePlaybackRate} id="video_details_controls_div_icon_playback_speed">
              {playbackRate}
              x
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

VideoPlayerOpenWrapper.defaultProps = {
  open: false,
  videos: [],
  defaultVideoIndex: 0,
};

VideoPlayerOpenWrapper.propTypes = {
  open: PropTypes.bool,
  videos: PropTypes.array,
  defaultVideoIndex: PropTypes.number,
  closeViewerHandler: PropTypes.func.isRequired,
};

export default VideoPlayerOpenWrapper;
