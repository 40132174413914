import React from 'react';
import PropTypes from 'prop-types';

import './RightPanel.scss';

function RightPanel({
  children,
}) {
  return (
    <>
      <div id="record__right_panel">
        {children}
      </div>
    </>
  );
}

RightPanel.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default RightPanel;
