import React from 'react';
import PropTypes from 'prop-types';
import Text from './Text';
import { interpolateString } from './utils';

function TemplateText({
  size, classes, label, availableData,
}) {
  const translatedLabel = interpolateString(label, availableData);
  return <Text label={translatedLabel} classes={classes} size={size} />;
}

TemplateText.defaultProps = {
  size: 'h1',
  classes: [],
  availableData: {},
};

TemplateText.propTypes = {
  size: PropTypes.string,
  classes: PropTypes.array,
  availableData: PropTypes.object,
  label: PropTypes.string.isRequired,
};

export default TemplateText;
