const screenNames = {
  APPLICATIONS: 'Applications',
  RECORD: 'Application Record',
  LOGIN: 'Login',
  HOME: 'Home',
  WORKFLOWS: 'Workflows',
  DRAWER: 'Drawer',
  CREDENTIAL: 'Credentials',
  USAGE: 'Usage',
};

export default screenNames;
