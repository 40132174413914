import React from 'react';
import { get, isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import ReviewTagsSection from './ReviewTagsSection';

function ReviewTagsCard() {
  const reviewTagsConfig = useSelector((state) => state.appDetails.reviewTags);
  const availableCardData = useSelector((state) => state.appDetails.availableCardData);

  if (isEmpty(reviewTagsConfig)) {
    return null;
  }

  return (
    <div className="review_tags_card__container">
      <div className="review_tags_card__header">Select your flags</div>
      <div className="review_tags_card__body">
        {Object.entries(reviewTagsConfig).map(([moduleId, moduleReviewTags]) => {
          const requestId = get(availableCardData, [moduleId, 'recordData', 'requestId']);
          const { key, title, tagGroups } = moduleReviewTags[requestId] || {};
          return (
            <ReviewTagsSection key={key} title={title} tagGroups={tagGroups} moduleId={moduleId} />
          );
        })}
      </div>
    </div>
  );
}

export default ReviewTagsCard;
