import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { startOfDay, endOfDay } from 'date-fns';
import './Cards.scss';
import DatePicker from '../Inputs/DatePicker';

function DatePickerCard({
  onFilterAdd,
  handleClose,
  details,
  hideCustomRanges,
}) {
  const [selectedDateRange, setSelectedDateRange] = useState({
    startTime: startOfDay(new Date()),
    endTime: endOfDay(new Date()),
  });

  const handlePick = (startTime, endTime) => {
    setSelectedDateRange({ startTime, endTime });
    onFilterAdd({ ...details, selectedDateRange: { startTime, endTime } });
  };

  useEffect(() => {
    if (details.selectedDateRange) setSelectedDateRange(details.selectedDateRange);
  }, []);

  return (
    <DatePicker
      handleClose={handleClose}
      handlePick={handlePick}
      startTime={selectedDateRange.startTime}
      endTime={selectedDateRange.endTime}
      hideCustomRanges={hideCustomRanges}
    />
  );
}
DatePickerCard.defaultProps = {
  hideCustomRanges: false,
};

DatePickerCard.propTypes = {
  onFilterAdd: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  details: PropTypes.object.isRequired,
  hideCustomRanges: PropTypes.bool,
};

export default DatePickerCard;
