import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { updateSortBy } from '../../../../reducers/data';
import { DEFAULT_SORT_DIRECTION } from '../../../../constants/transaction';
import switchSortDirection from '../../../../utils/sort';

import SortIcon from '../../../../assests/icons/sorting.svg';
import './index.scss';

function SortHeaderCell({ text, column }) {
  const dispatch = useDispatch();
  const tableSortBy = useSelector((state) => state.data.sortBy);
  const { column: currentColumn, direction: currentSortDirection } = tableSortBy || {};

  const handleSort = () => {
    let updatedSortKeyDirection;

    // If current sort by column is same as the column to be updated, then switch the direction
    // Else add default direction
    if (column === currentColumn) {
      updatedSortKeyDirection = switchSortDirection(currentSortDirection);
    } else {
      updatedSortKeyDirection = DEFAULT_SORT_DIRECTION;
    }

    dispatch(
      updateSortBy({
        column,
        direction: updatedSortKeyDirection,
      }),
    );
  };

  return (
    <div className="sort_header">
      {text}
      <img src={SortIcon} alt="" onClick={handleSort} aria-hidden />
    </div>
  );
}

SortHeaderCell.propTypes = {
  text: PropTypes.string.isRequired,
  column: PropTypes.string.isRequired,
};

export default SortHeaderCell;
