import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { PlatformBillingTable } from 'storybook-ui-components';
import { useSelector } from 'react-redux';
import { keys } from 'lodash';
import Header from '../PricingInfo/Header';
import { transactionDataDownloadType } from '../../../containers/Common/Download/utils';
import CustomButton from '../../Common/Buttons/CustomButton';
import DownloadIcon from './DownloadIcon';

import '../Account.scss';
import getBillingBreakup from '../../../api/analytics';
import { getPlatformBreakup } from '../../../api/usage';
import { billingDateFormat, getAnalyticsBreakupDates } from './usageUtils';
import useShowErrorAlert from '../../../utils/lib';
import AlertCode from '../../../constants/alertCodes';

function PlatformBilling({
  fetchLogs, isLoading, currency, billingMonth, appId, appIdList, selectedDate,
}) {
  const showErrorAlert = useShowErrorAlert();
  const [showLoader, setShowLoader] = useState(false);
  const { data, totalCost, appIdWiseUsageData } = useSelector(
    (state) => state.pricing.transactionUsage,
  );

  const getBillingAnalyticsBreakup = async ({ workflowId }) => {
    const { monthStartDate, monthEndDate } = getAnalyticsBreakupDates(selectedDate);
    const analyticsBreakupResp = await getBillingBreakup({
      appIdList: appId !== 'all' ? [appId] : keys(appIdList),
      workflowId,
      monthStartDate,
      monthEndDate,
    });
    return analyticsBreakupResp;
  };

  const getBillingPlatformBreakup = async ({ workflowId }) => {
    const monthStartDate = selectedDate.format(billingDateFormat);
    const currentDateInUTC = moment().utc();
    let monthEndDate;
    if (selectedDate.month() === currentDateInUTC.month()) {
      monthEndDate = selectedDate
        .clone()
        .set('date', currentDateInUTC.date())
        .format(billingDateFormat);
    } else {
      monthEndDate = selectedDate
        .clone()
        .add('month', 1)
        .subtract('day', 1)
        .format(billingDateFormat);
    }
    const platformBreakupResp = await getPlatformBreakup({
      appIdList: appId !== 'all' ? [appId] : keys(appIdList),
      workflowId,
      monthStartDate,
      monthEndDate,
    });
    return platformBreakupResp;
  };

  const onRowClick = async (row) => {
    try {
      setShowLoader(true);
      const [analyticsResp, platformResp] = await Promise.all([
        getBillingAnalyticsBreakup({ workflowId: row.workflowId }),
        getBillingPlatformBreakup({ workflowId: row.workflowId }),
      ]);
      setShowLoader(false);
      return { analyticsResp, platformResp };
    } catch (err) {
      showErrorAlert({ err, message: AlertCode.PLATFORM_PRICING_DEATILS });
      setShowLoader(false);
      throw err;
    }
  };

  const tableData = appId === 'all' ? data : appIdWiseUsageData?.[appId]?.usageRows;
  const total = appId === 'all' ? totalCost : appIdWiseUsageData?.[appId]?.totalCost;

  const fetchLogsForPlatformBilling = () => fetchLogs(transactionDataDownloadType);

  return (
    <>
      <div id="usage__header_filters">
        <div>
          <Header headerText="Platform Billing" paragraphId="client_pricing_info__sub_heading" />
          <span className="platform-usage-subtext">
            Transactions completed in
            {' '}
            {billingMonth}
          </span>
        </div>
        <CustomButton
          IconLeft={DownloadIcon}
          onClick={fetchLogsForPlatformBilling}
        >
          Download Logs
        </CustomButton>
      </div>
      <PlatformBillingTable
        transactionData={tableData}
        total={total}
        isLoading={isLoading || showLoader}
        currency={currency}
        knowMoreSrcUrl="https://documentation.hyperverge.co/Dashboard/usage_page"
        selectedMonth={billingMonth}
        onRowClick={onRowClick}
      />
    </>
  );
}

PlatformBilling.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  fetchLogs: PropTypes.func.isRequired,
  currency: PropTypes.string.isRequired,
  appId: PropTypes.string.isRequired,
  billingMonth: PropTypes.string.isRequired,
  appIdList: PropTypes.array.isRequired,
  selectedDate: PropTypes.string.isRequired,
};

export default PlatformBilling;
