import axios from 'axios';

const axiosRequest = axios.create({
  baseURL: `${process.env.REACT_APP_SERVER_URL}/api/v1/audit`,
  withCredentials: true,
});

const downloadDataApi = (searchValue, status, startTime, endTime, metaData, appId) => axiosRequest.post('download', {
  searchValue,
  status,
  startTime,
  endTime,
  ...metaData,
}, {
  headers: { appId },
});

export default downloadDataApi;
