import { setCookie } from './cookie';

const vkycPortalUrls = {
  STAGING: process.env.REACT_APP_VKYC_PORTAL_STAGING_URL || window.location.origin,
  PRODUCTION: process.env.REACT_APP_VKYC_PORTAL_PROD_URL || window.location.origin,
};

const productRedirectFunctions = {
  VKYCPortal: (reactNavigateFunction, vkycEnvironmentToRedirectTo, selectedAppId) => {
    if (selectedAppId) {
      setCookie('hvOneSelectedAppId', selectedAppId, 365 * 24 * 60 * 60 * 1000);
    }

    const vkycPortalUrl = vkycPortalUrls[vkycEnvironmentToRedirectTo?.toUpperCase()];
    if (selectedAppId && vkycPortalUrl) {
      window.location = vkycPortalUrl;
    } else {
      reactNavigateFunction('/BU/selection');
    }
  },
  thomasUI: () => {
    const urlToNavigateTo = process.env.REACT_APP_THOMAS_UI_URL || window.location.origin;
    window.location = urlToNavigateTo;
  },
};

export default productRedirectFunctions;
