import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import AlertCode from '../../../constants/alertCodes';
import './Credential.scss';
import { useFormatAnalyticsData } from '../../../utils/lib';
import rudderstackEvents from '../../../constants/rudderstackEventNames';
import screenNames from '../../../constants/screenNames';

function AppKeyCell({
  column, value, updateAlert, isHoveredOn,
}) {
  const email = useSelector((state) => state.user.email);
  const clientId = useSelector((state) => state.user.clientId);
  const [isVisible, setIsVisible] = useState(false);
  const formatAnalyticsData = useFormatAnalyticsData();

  const toggleHideShow = () => {
    formatAnalyticsData(
      email, clientId, rudderstackEvents.DASHBOARD_CREDENTIAL_VIEW, screenNames.CREDENTIAL,
    );
    setIsVisible(!isVisible);
  };

  const secretKeyDiv = [...Array(6).keys()];
  const secretKeyColPerDiv = [...Array(3).keys()];

  const copyText = () => {
    formatAnalyticsData(
      email, clientId, rudderstackEvents.DASHBOARD_CREDENTIAL_COPY, screenNames.CREDENTIAL,
    );
    const payload = {
      message: AlertCode.COPIED_TO_CLIPBOARD,
    };
    updateAlert(payload);
  };

  if (!value) return '-';
  return (
    <div
      key={column}
      id="app_key_cell__container"
    >
      <div id="app_key_cell__text" data-hj-suppress>
        {isVisible ? <span>{value}</span>
          : (
            <span id="app_key_cell__hide_text">
              {secretKeyDiv.map((div) => (
                <span key={div}>
                  {secretKeyColPerDiv.map((col) => (<span key={col} />))}
                </span>
              ))}
            </span>
          )}
      </div>
      <button type="button" id="app_key_cell__view" onClick={toggleHideShow} className={!isHoveredOn && 'disabled'}>
        view
      </button>
      <CopyToClipboard
        text={value}
        onCopy={copyText}
        className={!isHoveredOn && 'disabled'}
      >
        <button type="button" id="app_key_cell__copy">
          copy
        </button>
      </CopyToClipboard>
    </div>
  );
}

AppKeyCell.propTypes = {
  column: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  updateAlert: PropTypes.func.isRequired,
  isHoveredOn: PropTypes.bool.isRequired,
};

export default AppKeyCell;
