import React from 'react';
import { Navigate } from 'react-router-dom';

const reviewPageComponentPermissions = {
  requiredPermissions: {
    viewPermissions: ['reviewPage.view'],
    interactPermissions: ['reviewPage.interact'],
  },
  redirect: () => <Navigate to="/home" replace />,
};

const reivewPagePermissions = {
  reviewPage: reviewPageComponentPermissions,
};

export default reivewPagePermissions;
