import React from 'react';
import { HeaderInfoCell } from 'clm-components';
import { get, includes, startCase } from 'lodash';
import showApplicationStatus from '../../../constants/showApplicationStatus';
import { formatDateForApplicationTable, formatDateForApplicationTableDob } from '../../../utils/dateFormats';
import TransactionIdCell from './TransactionIdCell';
import WorkflowCell from './WorkflowCell';
import CountryCell from './TableCells/CountryCell';
import SortHeaderCell from './TableHeaderCells/SortHeader';
import { DASHBOARD_DATA_KEY, TRANSACTIONS_TABLE_SORT_ALLOWED_COLUMN_KEYS, TRANSACTIONS_TABLE_TIME_COLUMN_KEYS } from '../../../constants/transaction';

export const genTableData = (
  data, transactionMetadataColumnsConfig = {}, dashboardDataTableColumns = [],
) => data.map(
  (item, idx) => {
    const tableData = {};
    Object.entries(item).forEach(([key, value]) => {
      if (key === 'status') {
        tableData[key] = {
          componentRenderer: () => (
            <span id={`application__status__${showApplicationStatus(value).style}`}>
              {showApplicationStatus(value).status}
            </span>
          ),
        };
      } else if (key === 'workflowId') {
        tableData[key] = {
          componentRenderer: () => <WorkflowCell workflowId={value} />,
        };
      } else if (includes(TRANSACTIONS_TABLE_TIME_COLUMN_KEYS, key)) {
        tableData[key] = {
          componentRenderer: () => formatDateForApplicationTable(value),
        };
      } else if (key === 'requestId') {
        tableData[key] = {
          data: { id: value, rowId: idx },
          label: value,
        };
      } else if (key === 'transactionId') {
        tableData[key] = {
          data: { id: value, rowId: idx },
          componentRenderer: () => (
            <TransactionIdCell data={{ ...item, idx }} />
          ),
        };
      } else if (key === 'dob') {
        tableData[key] = {
          componentRenderer: () => formatDateForApplicationTableDob(value),
        };
      } else if (key === 'country') {
        tableData[key] = {
          componentRenderer: () => <CountryCell countryId={value} />,
        };
      } else if (key === 'transactionMetadata') {
        transactionMetadataColumnsConfig?.tableColumns?.forEach((option) => {
          const { path } = option;
          tableData[path] = { label: startCase(get(value, path)) };
        });
      } else if (key === DASHBOARD_DATA_KEY) {
        // We add it as `${DASHBOARD_DATA_KEY}.${dashboardDataColumn.key}` instead of
        // dashboardDataColumn.key to distinguish default keys that we store for a transaction
        // For Example name/dob could be part of dashboardData
        // and is also s default key for a transaction
        dashboardDataTableColumns?.forEach((dashboardDataColumn) => {
          tableData[`${DASHBOARD_DATA_KEY}.${dashboardDataColumn?.key}`] = {
            label: get(
              value, dashboardDataColumn?.key,
            ),
          };
        });
      } else {
        tableData[key] = { label: value };
      }
    });
    return tableData;
  },
);

export const genTableHeaders = (tableColumns) => {
  const updatedTableColumns = {};
  tableColumns.forEach(({ id, label }) => {
    if (includes(TRANSACTIONS_TABLE_SORT_ALLOWED_COLUMN_KEYS, id)) {
      updatedTableColumns[id] = {
        componentRenderer: () => (
          <SortHeaderCell text={label} column={id} />),
      };
    } else {
      updatedTableColumns[id] = {
        componentRenderer: () => (
          <HeaderInfoCell text={label} />),
      };
    }
  });
  return updatedTableColumns;
};
