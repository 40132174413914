import React from 'react';
import PropTypes from 'prop-types';
import { lowerCase } from 'lodash';

function Header({ status }) {
  return (
    <div id="right_panel__review_card__status">
      <p>Status:</p>
      <span className={status}>{lowerCase(status)}</span>
    </div>
  );
}

Header.propTypes = {
  status: PropTypes.string.isRequired,
};

export default Header;
