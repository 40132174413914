import React, { useEffect, useMemo } from 'react';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { Spinner } from 'clm-components';
import * as Sentry from '@sentry/react';
import useShowErrorAlert from '../utils/lib';
import { updateIsAuthenticated } from '../reducers/user';
import AlertCode from '../constants/alertCodes';
import { getRedirectURLFromQueryParams } from '../utils/helpers';

import './Container.scss';
import errorCode from '../constants/errorCode';

function InternalLogin() {
  const dispatch = useDispatch();
  const showErrorAlert = useShowErrorAlert();
  const queryParams = useMemo(() => new URLSearchParams(window.location.search), []);

  const fetchGoogleLogin = async () => {
    try {
      let successRedirectUrl = `${window.location.origin}/home`;
      const redirectUrl = getRedirectURLFromQueryParams(queryParams);
      if (redirectUrl) {
        successRedirectUrl = `${window.location.origin}/redirect?url=${encodeURIComponent(redirectUrl)}`;
      }
      const userRes = await axios({
        method: 'GET',
        url: `${process.env.REACT_APP_SELF_STAGING_URL}/api/auth/internalLogin?successRedirectUrl=${successRedirectUrl}`,
      });
      dispatch(updateIsAuthenticated(true));
      window.location = userRes.data.redirectUrl;
    } catch (error) {
      Sentry.captureException(`${errorCode.INTERNAL_GOOGLE_LOGIN_ERROR} - ${error}`, {
        extra: {
          errorMessage: AlertCode.ERROR_FETCHING_GOOGLE_LOGIN_URL,
        },
      });
      showErrorAlert({ error, message: AlertCode.ERROR_FETCHING_GOOGLE_LOGIN_URL });
    }
  };

  useEffect(() => {
    fetchGoogleLogin();
  }, []);

  return (
    <div id="internal_login__container">
      <Spinner size="big" />
    </div>
  );
}

export default InternalLogin;
