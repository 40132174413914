import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import CommonHeader from '../CommonHeader';
import CustomTabs from '../../../../../Common/Utils/CustomTabs';
import Panel from './Panel';

import '../index.scss';

function GenericCardTypeV1({
  moduleId, moduleName, subType, records,
}) {
  const [attemptsArray, setAttemptsArray] = useState([]);
  const [selectedAttemptIndex, setSelectedAttemptIndex] = useState(0);

  useEffect(() => {
    setAttemptsArray(records);
    setSelectedAttemptIndex(0);
  }, [records]);

  return (
    <div className="generic_common_card__container">
      <CommonHeader moduleName={moduleName} subType={subType} />
      <CustomTabs
        className="generic_common_card__body_tabs"
        tabIndex={selectedAttemptIndex}
        setTabIndex={setSelectedAttemptIndex}
        tabArray={attemptsArray}
        tabIdPrefix={`generic_common_card_${moduleId}_tab`}
        ariaControlsPrefix={`generic_common_card_${moduleId}_tab_panel`}
      />
      {attemptsArray.map((item, index) => (
        <Panel
          key={item.id}
          selectedAttemptIndex={selectedAttemptIndex}
          index={index}
          item={item}
          moduleId={moduleId}
          createdAt={item.createdAt}
          status={item.status}
        />
      ))}
    </div>
  );
}

GenericCardTypeV1.propTypes = {
  moduleId: PropTypes.string.isRequired,
  moduleName: PropTypes.string.isRequired,
  subType: PropTypes.string.isRequired,
  records: PropTypes.arrayOf(PropTypes.any.isRequired).isRequired,
};

export default GenericCardTypeV1;
