import './DefaultOnboard.scss';
import React from 'react';
import { useSelector } from 'react-redux';
import logo from '../../assests/images/logoPlain.png';
import rocket from '../../assests/images/rocket.svg';

export default function ResetPasswordEmailSent() {
  const email = useSelector((state) => state.user.email);

  return (
    <div className="onboard-page">
      <img src={logo} alt="logo" className="hv-logo" />
      <div className="onboard-container">
        <h2 className="forget-pass-font">Reset Link Sent!</h2>
        <p className="recovery-mail-text">
          We&apos;ve sent the recovery mail to your email id:
        </p>
        <p className="email-text">{email}</p>
        <div className="column-display">
          <img className="rocket-icon" src={rocket} alt=">" />
        </div>
        <p className="footer-text">
          Please check your inbox
        </p>
      </div>
    </div>
  );
}
