import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_SELF_SERVE_BASE_URL}/api/mfa`,
  withCredentials: true,
});

export const getAvailableFactorsAPI = () => axiosInstance.get('/factors/available');

export const getRegisteredFactorsAPI = () => axiosInstance.get('/factors/registered');

export const startEmailOtpAPI = () => axiosInstance.post(
  '/start/email', {},
);

// IMPORTANT: If this is ever moved to hitting Portal BE instead of self-serve directly
// Need to pass user-agent string from portal BE to self-serve
// This is required for device level MFA
export const verifyEmailOtpAPI = ({ otp }) => axiosInstance.post(
  '/verify/email', { otp },
);
