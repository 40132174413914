import React from 'react';
import PropTypes from 'prop-types';
import CustomButton from '../../../../../../Common/Buttons/CustomButton';
import { ReactComponent as ThreeDotsIcon } from '../../../../../../../assests/icons/dots.svg';

function MenuButton({ handleClick }) {
  return (
    <CustomButton className="card_type_five__content__header__actions__menu" onClick={handleClick}>
      <ThreeDotsIcon />
    </CustomButton>
  );
}

MenuButton.propTypes = {
  handleClick: PropTypes.func.isRequired,
};
export default MenuButton;
