import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { get, startCase } from 'lodash';
import { useSelector } from 'react-redux';

function WorkflowCell({ workflowId, shouldShowVersioning }) {
  const [cellLabel, setCellLabel] = useState('');
  const workflowList = useSelector((state) => state.user.workflowList);

  useEffect(() => {
    if (workflowList && workflowList.length) {
      const workflow = workflowList.find(({ id }) => workflowId === id);
      let label = get(workflow, 'name', startCase(workflowId));
      if (shouldShowVersioning && label) {
        const versionNumber = get(workflow, 'version', 'v1');
        label += ` (${versionNumber})`;
      }
      setCellLabel(label);
    }
  }, [workflowId, workflowList, shouldShowVersioning]);

  return (
    <>{cellLabel}</>
  );
}

WorkflowCell.defaultProps = {
  shouldShowVersioning: false,
};

WorkflowCell.propTypes = {
  workflowId: PropTypes.string.isRequired,
  shouldShowVersioning: PropTypes.bool,
};

export default WorkflowCell;
