import React, { useState } from 'react';
import MissingTabHand from '../../../../../assests/icons/missingTabHand.svg';

function MissingRecordComponent() {
  const [hovered, setHovered] = useState(false);

  return (
    <div
      className={`missing-card-container ${hovered ? 'apply-missing-effect' : ''}`}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <div className="top-content">
        <img src={MissingTabHand} alt="loading" />
        <p>Looking for more data?</p>
        <span>
          To keep things running efficiently for you,
          we only display the more recent attempts information.
        </span>
      </div>
      <div className="bottom-content">
        <span>For any concerns - feel free to write to HyperVerge support.</span>
      </div>
    </div>
  );
}

export default MissingRecordComponent;
