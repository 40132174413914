import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateFilterData } from '../../../reducers/review';

function StatusDropdown() {
  const selectedStatus = useSelector((state) => state.review.filterData.statusCode);
  const dispatch = useDispatch();

  const handleChange = (event) => {
    const choice = event.target.value;
    dispatch(updateFilterData({
      statusCode: choice,
    }));
  };

  return (
    <FormControl
      id="status_code_dropdown"
      sx={{
        width: 150, mt: 1, backgroundColor: 'white', borderRadius: 30,
      }}
    >
      <InputLabel id="select-label">Code</InputLabel>
      <Select
        labelId="select-label"
        id="simple-select"
        value={selectedStatus}
        onChange={handleChange}
        label="Code"
        defaultValue=""
        style={{
          borderRadius: 30,
        }}
      >
        <MenuItem id="menu_item__success" value="200">200 - 299</MenuItem>
        <MenuItem id="menu_item__clientErrors" value="400">400 - 499</MenuItem>
        <MenuItem id="menu_item__serverErrors" value="500">500 - 599</MenuItem>
        <MenuItem id="menu_item__all" value="All">All</MenuItem>
      </Select>
    </FormControl>
  );
}

export default StatusDropdown;
