import axios from 'axios';

const axiosRequest = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL,
  withCredentials: true,
});

export const getCommentsApi = async (appId, transactionId) => {
  axiosRequest.defaults.headers.appId = appId;
  const comments = await axiosRequest.post('/api/v1/comment/get', { transactionId });
  return comments.data.result.comments;
};

export const addCommentApi = async (comment, userId, transactionId, appId) => {
  axiosRequest.defaults.headers.appId = appId;
  const commentRes = await axiosRequest.post('/api/v1/comment/add', {
    transactionId,
    comment,
  });
  return commentRes.data.result.comments;
};
