import React, { useState } from 'react';
import PropTypes from 'prop-types';
import CustomButton from '../../../../../../Common/Buttons/CustomButton';

function DecisionButton({
  label, value, isActive, handleButtonClick,
}) {
  const [hasButtonBeenClicked, setHasButtonBeenClicked] = useState(false);

  const onButtonClick = async () => {
    setHasButtonBeenClicked(true);
    await handleButtonClick(value);
    setHasButtonBeenClicked(false);
  };
  return (
    <CustomButton
      onClick={onButtonClick}
      className="card_type_five__footer__review__decision__button"
      isSelected={isActive}
      disabled={hasButtonBeenClicked}
    >
      {label}
    </CustomButton>
  );
}

DecisionButton.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
  handleButtonClick: PropTypes.func.isRequired,
};

export default DecisionButton;
