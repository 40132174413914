import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import SelfieverificationPanel from '../Panels/SelfieverificationPanel';
import '../../Details.scss';
import setImageTypeBasedOnConfig from './utils';
import CommonHeader from './CommonHeader';
import CustomTabs from '../../../Common/Utils/CustomTabs';

function SelfieVerification({ moduleName, subType, records }) {
  const [selfieDetailArray, setselfieDetailArray] = useState([]);
  const [value, setValue] = useState(0);
  useEffect(() => {
    setselfieDetailArray(setImageTypeBasedOnConfig(records));
    // set only the required keys
    // optional fields : conditions, persists, flags,
    setValue(0);
  }, [records]);
  return (
    <div id="details__container">
      <CommonHeader moduleName={moduleName} subType={subType} />
      <CustomTabs
        id="details__body_tabs"
        tabIndex={value}
        setTabIndex={setValue}
        tabArray={selfieDetailArray}
      />
      {selfieDetailArray.map((item, index) => (
        <SelfieverificationPanel
          key={item.id}
          value={value}
          index={index}
          item={item}
          createdAt={item.createdAt}
          status={item.apiStatus}
        />
      ))}
    </div>
  );
}

SelfieVerification.propTypes = {
  moduleName: PropTypes.string.isRequired,
  subType: PropTypes.string.isRequired,
  records: PropTypes.arrayOf(PropTypes.any.isRequired).isRequired,
};
export default SelfieVerification;
