import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Spinner } from 'clm-components';
import { get, startsWith } from 'lodash';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import * as Sentry from '@sentry/react';
import { DEPRECATED_DOCUMENTATION_PORTAL_URL, DOCUMENTATION_PORTAL_URL } from '../config';
import { updateDocToken } from '../reducers/onboardData';
import useShowErrorAlert from '../utils/lib';
import AlertCode from '../constants/alertCodes';
import { getRedirectURLFromQueryParams, reconstructRedirectURL } from '../utils/helpers';
import errorCode from '../constants/errorCode';

const DEFAULT_LOADER_SIZE = 'big';

function GenericRedirect({ redirectTo, navigateOptions, loaderOptions }) {
  const queryParams = useMemo(() => new URLSearchParams(window.location.search), []);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const showErrorAlert = useShowErrorAlert();

  const fetchDocTokenAndRedirect = async ({ redirectURL }) => {
    try {
      const res = await axios({
        method: 'GET',
        url: `${process.env.REACT_APP_SERVER_URL}/api/v1/onboard/docToken`,
      });
      const docToken = get(res, 'data.result', '');
      dispatch(updateDocToken(docToken));
      window.location = reconstructRedirectURL({
        redirectURL,
        additionalSearchParams: { jwt: docToken },
      });
    } catch (error) {
      Sentry.captureException(`${errorCode.REDIRECT_TOKEN_ERROR} - ${error}`, {
        extra: {
          errorMessage: AlertCode.FETCH_DOC_TOKEN,
        },
      });
      showErrorAlert({ error, message: AlertCode.FETCH_DOC_TOKEN });
      window.location = window.location.origin;
    }
  };

  useEffect(() => {
    const redirectURL = getRedirectURLFromQueryParams(queryParams);
    if (redirectURL) {
      if (
        startsWith(redirectURL, DOCUMENTATION_PORTAL_URL)
        // TODO: Needed only until fully deprecated
        || startsWith(redirectURL, DEPRECATED_DOCUMENTATION_PORTAL_URL)
      ) {
        fetchDocTokenAndRedirect({ redirectURL });
      } else {
        window.location = reconstructRedirectURL({ redirectURL });
      }
    } else {
      navigate(redirectTo, navigateOptions);
    }
  }, []);

  return (
    loaderOptions?.show ? (
      <div id="generic_redirect__container">
        <Spinner size={loaderOptions?.size || DEFAULT_LOADER_SIZE} />
      </div>
    ) : ''
  );
}

GenericRedirect.defaultProps = {
  redirectTo: '/',
  navigateOptions: {},
  loaderOptions: {
    show: true,
    size: DEFAULT_LOADER_SIZE,
  },
};

GenericRedirect.propTypes = {
  redirectTo: PropTypes.string,
  navigateOptions: PropTypes.object,
  loaderOptions: PropTypes.object,
};

export default GenericRedirect;
