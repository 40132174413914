import React from 'react';
import PropTypes from 'prop-types';
import carrotDownIcon from '../../../assests/icons/carrotDown.svg';

function DateButton({
  id, handleClick, isActive, label,
}) {
  return (
    <div id={id} className="date_dropdown">
      <button
        type="button"
        onClick={handleClick}
        className={`date_dropdown__button ${isActive ? 'active' : ''}`}
      >
        <span style={{ whiteSpace: 'nowrap' }}>{label}</span>
        <img src={carrotDownIcon} alt="" />
      </button>
    </div>
  );
}

DateButton.defaultProps = {
  id: '',
};

DateButton.propTypes = {
  id: PropTypes.string,
  handleClick: PropTypes.func.isRequired,
  isActive: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
};

export default DateButton;
