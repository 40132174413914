import './DefaultOnboard.scss';
import Grid from '@mui/material/Grid';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import logo from '../../assests/images/logoPlain.png';

export default function CreatePasswordSuccessful() {
  const navigate = useNavigate();
  const handleSubmit = async (event) => {
    event.preventDefault();
    navigate('/');
  };

  return (
    <Grid container>
      <div className="modal__rectangle_signup">
        <img src={logo} alt="logo" className="modal__hvLogo" />
        <h2 className="modal__create_account_heading">
          You’ve successfully reset your password
        </h2>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: -29 }} className="modal__email_text">
          <Button
            id="login__rectangle_loginButton"
            type="submit"
            variant="contained"
            sx={{ textTransform: 'none' }}
          >
            <table>
              <tbody>
                <tr>
                  <td>
                    <span className="modal__button_text">Go to Dashboard</span>
                  </td>
                </tr>
              </tbody>
            </table>
          </Button>
        </Box>
      </div>
    </Grid>
  );
}
