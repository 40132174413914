import { styled } from '@mui/system';
import Slider from '@mui/material/Slider';

const PrettoSlider = styled(Slider)`
  height: 20px;
  color: #554ef1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 4px;

  & .MuiSlider-thumb {
    display: none;
    height: 0;
    width: 0;
    background-color: #554ef1;
    border: 2px solid currentColor;
    margin-top: -3px;
    margin-left: -12px;

    &:focus,
    &:hover,
    &.Mui-active {
      box-shadow: inherit;
    }
  }

  & .MuiSlider-valueLabel {
    left: calc(-50% + 4px);
  }

  & .MuiSlider-track {
    height: 4px;
    border-radius: 2px;
  }

  & .MuiSlider-rail {
    height: 4px;
    border-radius: 2px;
  }
`;

export default PrettoSlider;
