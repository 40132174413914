import React, { } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { formatDateForApplicationTable } from '../../../../utils/dateFormats';
import './Comment.scss';

function CommentItem({ message, userId, time }) {
  const email = useSelector((s) => s.user.email);
  const selfComment = email === userId;

  return (
    <div data-hj-suppress id="comment_item_container">
      <p id="comment_item_creater_info" className={selfComment ? 'right' : ''}>
        {selfComment ? 'You' : userId }
        {' '}
        &#x25cf;
        {' '}
        {formatDateForApplicationTable(time)}
      </p>
      <div id="comment_item_message">{message}</div>
    </div>
  );
}

CommentItem.propTypes = {
  message: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  time: PropTypes.string.isRequired,
};

export default CommentItem;
