import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import MenuItem from '@mui/material/MenuItem';
import './Inputs.scss';
import CommonSearchBar from './CommonSearchBar';

function SingleSearchSelect({
  value, onValueChange, optionList, placeholder, optionValueKey, optionNameKey, emptyText,
}) {
  const [showMenu, setShowMenu] = useState(false);
  const [selectedValue, setSelectedValue] = useState('');
  const [filterList, setFilterList] = useState([]);
  const onSearchFocus = () => {
    setShowMenu(true);
  };

  const handleSearch = (searchValue) => {
    if (!searchValue || searchValue === '') {
      setFilterList(optionList);
    }
    const updatedList = optionList.reduce((acc, curr) => {
      if (curr.name && curr.name.toLowerCase().includes(searchValue.toLowerCase())) {
        return [...acc, curr];
      } if (curr.id.toLowerCase().includes(searchValue.toLowerCase())) {
        return [...acc, curr];
      }
      return acc;
    }, []);
    setFilterList(updatedList);
  };

  const onMenuItemClick = (id, name) => {
    onValueChange(id);
    setSelectedValue(name || id);
    setShowMenu(false);
  };

  useEffect(() => {
    setSelectedValue(value);
    setFilterList(optionList);
  }, []);

  return (
    <div>
      <div className="common_single_select_search_container">
        <CommonSearchBar
          handleSearch={handleSearch}
          placeholder={placeholder}
          initValue={selectedValue}
          onFocus={onSearchFocus}
          isIconVisible
          searchIconStyle={{ width: '12px', transform: 'scale(-1, 1)' }}
        />
      </div>
      {showMenu && (
      <div className="common_single_select_item_container">
        {filterList.length ? filterList.map((listElement) => (
          <MenuItem
            key={listElement[optionValueKey]}
            value={listElement[optionValueKey]}
            onClick={() => onMenuItemClick(listElement[optionValueKey], listElement[optionNameKey])}
            className="common_single_select_option"
          >
            {listElement[optionNameKey] || listElement[optionValueKey]}
          </MenuItem>
        )) : (
          <div className="common_single_select_empty_container">
            <span className="common_single_select_empty_container_text">{emptyText}</span>
          </div>
        )}
      </div>
      )}
    </div>
  );
}

SingleSearchSelect.defaultProps = {
  placeholder: 'Search',
  emptyText: 'No result found',
};

SingleSearchSelect.propTypes = {
  value: PropTypes.string.isRequired,
  onValueChange: PropTypes.func.isRequired,
  optionList: PropTypes.object.isRequired,
  placeholder: PropTypes.string,
  emptyText: PropTypes.string,
  optionValueKey: PropTypes.string.isRequired,
  optionNameKey: PropTypes.string.isRequired,
};

export default SingleSearchSelect;
