import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { downloadInProgress, finishDownload } from '../../../reducers/download';
import useShowErrorAlert, { useShowCustomAlert } from '../../../utils/lib';
import downloadApiMapping from './utils';

function DownloadFile() {
  const dispatch = useDispatch();
  const showAlert = useShowCustomAlert();
  const showErrorAlert = useShowErrorAlert();
  const downloadList = useSelector((state) => state.download);

  const downloadFile = async (type, apiData) => {
    dispatch(downloadInProgress(type));
    await downloadApiMapping[type](apiData, showAlert, showErrorAlert);
    dispatch(finishDownload(type));
  };

  useEffect(() => {
    if (downloadList && downloadList.length > 0) {
      downloadList.filter(({ inProgress }) => !inProgress).forEach(({ type, apiData }) => {
        downloadFile(type, apiData);
      });
    }
  }, [downloadList]);

  return <></>;
}

export default DownloadFile;
