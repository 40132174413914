import React from 'react';
import PropTypes from 'prop-types';
import { isNullOrUndefined } from '../../../../../../utils/helpers';

const TABLE_ROW_VALUE_EMPTY_FALLBACK = '-';

function TableRow({
  value, path, label, persist,
}) {
  if (isNullOrUndefined(value) && !persist) return <></>;

  return (
    <tr key={path}>
      <td className="left">
        {label}
      </td>
      <td data-hj-suppress className="right">
        {isNullOrUndefined(value) ? TABLE_ROW_VALUE_EMPTY_FALLBACK : `${value}`}
      </td>
    </tr>
  );
}

TableRow.defaultProps = {
  value: null,
  persist: false,
};

TableRow.propTypes = {
  value: PropTypes.object,
  path: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  persist: PropTypes.bool,
};

export default TableRow;
