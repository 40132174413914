import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import dataReducer from './reducers/data';
import reviewReducer from './reducers/review';
import userReducer from './reducers/user';
import alertReducer from './reducers/alert';
import onboardReducer from './reducers/onboardData';
import initializationReducer from './reducers/initialize';
import focussedInputsReducer from './reducers/focussedInputs';
import downloadReducer from './reducers/download';
import summaryReducer from './reducers/summary';
import s3ConfigReducer from './reducers/s3Config';
import appDetailsReducer from './reducers/appDetails';
import pricingReducer from './reducers/pricing';

const combinedReducer = combineReducers({
  data: dataReducer,
  review: reviewReducer,
  user: userReducer,
  pricing: pricingReducer,
  alert: alertReducer,
  appDetails: appDetailsReducer,
  onboard: onboardReducer,
  initialize: initializationReducer,
  focussedInputs: focussedInputsReducer,
  download: downloadReducer,
  s3Config: s3ConfigReducer,
  summary: summaryReducer,
});

const persistConfig = { key: 'root', storage, blacklist: ['initialize'] };

const rootReducer = (state, action) => {
  let newState = state;
  if (action.type === 'RESET') { // check for action type
    newState = undefined;
  }
  return combinedReducer(newState, action);
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false,
  }),
});
const persistor = persistStore(store);
// persistor.purge(); // Used to clear persist storage.

export { persistor };
export default store;
