import React from 'react';
import PropTypes from 'prop-types';
import TableActionsIcon from '../../../../assests/icons/tableActions.png';
import CarrotDown from '../../../../assests/icons/carrotDown.svg';

function TableActionsButton({ handleClick }) {
  return (
    <button type="button" id="audit__actions_container_button" onClick={handleClick}>
      <img src={TableActionsIcon} alt="" />
      <span>
        Table actions
      </span>
      <img src={CarrotDown} alt="" />
    </button>
  );
}

TableActionsButton.propTypes = {
  handleClick: PropTypes.func.isRequired,
};

export default TableActionsButton;
