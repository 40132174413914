import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import CommonSelectSearch from './CommonSelectSearch';
import './Inputs.scss';

function MultiSelect({
  id, availableOptions, selectedOptions, placeholder, handleSelect, handleRemove,
}) {
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [isFocussed, setIsFocussed] = useState(false);

  const searchRef = useRef('');

  const handleSearchClick = () => {
    setIsMenuOpen(true);
    setIsFocussed(true);
  };

  const handleSearchOnChange = (event) => {
    setSearchValue(event.target.value);
  };

  const onCloseOption = (value) => {
    handleRemove(value);
  };

  const handleSearchSelect = (value, itemLabel) => {
    handleSelect(value, itemLabel);
    setSearchValue('');
  };

  useEffect(() => {
    if (selectedOptions.length) {
      setFilteredOptions(
        availableOptions.filter(
          (option) => !selectedOptions.some((opt) => opt.value === option.value)
          && (searchValue
            ? option.label.toLowerCase().includes(searchValue.toLowerCase())
            : true),
        ),
      );
    } else {
      setFilteredOptions(
        availableOptions.filter(
          (option) => (searchValue
            ? option.label.toLowerCase().includes(searchValue.toLowerCase())
            : true),
        ),
      );
    }
  }, [selectedOptions, availableOptions]);

  useEffect(() => {
    if (!searchValue) {
      setFilteredOptions(
        availableOptions.filter(
          (option) => !selectedOptions.some((opt) => opt.value === option.value),
        ),
      );
    } else {
      setFilteredOptions(
        availableOptions.filter(
          (option) => !selectedOptions.some((opt) => opt.value === option.value)
        && option.label.toLowerCase()
          .includes(searchValue.toLowerCase()),
        ),
      );
    }
  }, [searchValue, availableOptions]);

  useEffect(() => {
  }, [filteredOptions]);

  const handleMouseClick = (event) => {
    if (event.target !== searchRef.current
      && !searchRef.current.contains(event.target)) {
      setIsMenuOpen(false);
      setIsFocussed(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleMouseClick);
    return () => {
      document.removeEventListener('mousedown', handleMouseClick);
    };
  }, []);

  return (
    <div id={id} className="multi_select_container">
      <button
        className="multi_select_search_box"
        ref={searchRef}
        type="button"
      >
        <CommonSelectSearch
          placeholder={placeholder}
          isParentFocussed={isFocussed}
          onClick={handleSearchClick}
          selectedOptions={selectedOptions}
          onCloseOption={onCloseOption}
          isIconVisible={false}
          value={searchValue}
          handleChange={handleSearchOnChange}
        />
        {isMenuOpen && (
        <div className="multi_select_option_box">
          {filteredOptions.length ? (
            filteredOptions.map(
              ({ id: optionId, value, label: itemLabel }) => (
                <button
                  onClick={() => handleSearchSelect(value, itemLabel)}
                  key={value}
                  type="button"
                  id={optionId}
                >
                  <span>{itemLabel}</span>
                </button>
              ),
            )
          ) : (
            <button type="button" className="empty">
              <span>No other options available</span>
            </button>
          )}
        </div>
        )}
      </button>
    </div>
  );
}

MultiSelect.defaultProps = {
  id: '',
  placeholder: 'Search',
};

MultiSelect.propTypes = {
  id: PropTypes.string,
  availableOptions: PropTypes.array.isRequired,
  selectedOptions: PropTypes.array.isRequired,
  placeholder: PropTypes.string,
  handleSelect: PropTypes.func.isRequired,
  handleRemove: PropTypes.func.isRequired,
};

export default MultiSelect;
