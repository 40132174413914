import React from 'react';
import PropTypes from 'prop-types';

import './Utils.scss';

function HorizontalLine({ marginTop, marginBottom }) {
  return (
    <div style={{ marginTop, marginBottom }} className="hor_line" />
  );
}

HorizontalLine.defaultProps = {
  marginTop: null,
  marginBottom: null,
};

HorizontalLine.propTypes = {
  marginTop: PropTypes.string,
  marginBottom: PropTypes.string,
};

export default HorizontalLine;
