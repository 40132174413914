import React from 'react';
import PropTypes from 'prop-types';
// import { formatDateForApplicationTable } from '../../../utils/dateFormats';

function SummaryHeader({ data }) {
  return (
    <div id="summary__header__container">
      <h1>Application Details</h1>
      <div id="summary__header__details">
        {data && data.map(({
          id, label, value, componentRenderer,
        }) => (
          <p id={id} key={id}>
            {label}
            :
            <span>
              {componentRenderer ? componentRenderer({ value }) : value}
            </span>
          </p>
        ))}
      </div>
    </div>
  );
}

SummaryHeader.defaultProps = {
  data: [],
};

SummaryHeader.propTypes = {
  data: PropTypes.array,
};

export default SummaryHeader;
