import React from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { get, isEmpty } from 'lodash';
import * as Sentry from '@sentry/react';
import useShowErrorAlert, {
  useShowCustomAlert,
} from '../../../../../../../utils/lib';
import errorCode from '../../../../../../../constants/errorCode';

function MenuItems({ items, availableData }) {
  const showErrorAlert = useShowErrorAlert();
  const showCustomAlert = useShowCustomAlert();

  const onClick = async (url, type, value) => {
    try {
      const { data } = await axios.post(url, {
        transactionId: get(availableData, 'transactionData.transactionId', ''),
        requestId: get(availableData, 'recordData.requestId', ''),
        value,
      });
      const successMessage = get(data, 'result.message', 'Success');
      showCustomAlert({ alertSeverity: 'success', message: successMessage });
      if (type === 'download') {
        const link = get(data, 'result.url');
        if (!isEmpty(link)) {
          window.open(link, '_blank');
        } else {
          showErrorAlert({ message: 'Server Error' });
        }
      }
    } catch (error) {
      const errorMessage = get(error, 'res.data.result.error', 'Server Error');
      showErrorAlert({ error, message: errorMessage });
      Sentry.captureException(`${errorCode.APPLICATION_DYNAMIC_CARD_MENU_BUTTON_API_ERROR} - ${error}`, {
        extra: {
          errorMessage,
        },
      });
    }
  };
  return (
    <div className="card_type_five__content__header__actions__menu__options">
      {items.map(({
        key, label, url, type, value,
      }) => (
        <div key={key} onClick={() => onClick(url, type, value)} aria-hidden>
          {label}
        </div>
      ))}
    </div>
  );
}

MenuItems.defaultProps = {
  items: [],
};

MenuItems.propTypes = {
  items: PropTypes.array,
  availableData: PropTypes.object.isRequired,
};

export default MenuItems;
