import { ToolTip } from 'clm-components';
import React from 'react';

import PropTypes from 'prop-types';

function FlagItem({ moduleName, flag }) {
  return (
    <>
      <ToolTip title={flag}>
        <p>
          {moduleName ? (
            <>
              {moduleName}
              :
            </>
          ) : ''}
          <span>
            {flag}
          </span>
        </p>
      </ToolTip>
    </>
  );
}

FlagItem.defaultProps = {
  moduleName: '',
};

FlagItem.propTypes = {
  moduleName: PropTypes.string,
  flag: PropTypes.string.isRequired,
};

export default FlagItem;
