import { every } from 'lodash';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import eyeOpen from '../../../assests/icons/eyeOpen.svg';
import eyeClose from '../../../assests/icons/eyeIconClosed.svg';
import PasswordModalTrigger from '../Popup/PasswordModalTrigger';
import { checkPasswordValidity } from '../../../utils/helpers';

function PasswordInput({
  id,
  className,
  handlePasswordChange,
  showEyeIcon,
  placeholder,
  autoComplete,
  shouldShownValidationModal,
  setPasswordValidity,
  modalClassName,
  onKeyDown,
}) {
  const [showPassword, setShowPassword] = useState(false);
  const [isValidPassword, setIsValidPassword] = useState({
    isLengthValid: false,
    isLowerCaseValid: false,
    isUpperCaseValid: false,
    isNumericValid: false,
    isSpecialCharValid: false,
  });

  const [showPasswordModal, setShowPasswordModal] = useState(false);

  const showModal = () => setShowPasswordModal(true);

  const hideModal = () => setShowPasswordModal(false);

  const togglePasswordIcon = () => setShowPassword((prev) => !prev);

  const changePassword = (event) => {
    const newPassword = event.target.value;
    if (showPasswordModal) {
      const validation = checkPasswordValidity(newPassword);
      setIsValidPassword(validation);
      const isValid = every(validation);
      setPasswordValidity(isValid);
    }
    handlePasswordChange(event);
  };

  return (
    <>
      <div className="relative-position">
        <input
          data-hj-suppress
          id={id}
          className={className}
          type={showPassword ? 'text' : 'password'}
          placeholder={placeholder}
          onChange={changePassword}
          onFocus={shouldShownValidationModal ? showModal : undefined}
          onBlur={shouldShownValidationModal ? hideModal : undefined}
          autoComplete={autoComplete}
          onKeyDown={onKeyDown}
        />
        {showEyeIcon && (
        <button className="eye-icon" onClick={togglePasswordIcon} type="button">
          <img src={showPassword ? eyeOpen : eyeClose} alt="e" />
        </button>
        )}
      </div>
      {showPasswordModal
      && (
      <PasswordModalTrigger
        className={modalClassName}
        passwordValidationObject={isValidPassword}
      />
      )}
    </>
  );
}

PasswordInput.defaultProps = {
  id: '',
  className: '',
  placeholder: 'Create a password',
  showEyeIcon: false,
  autoComplete: 'one-time-code',
  shouldShownValidationModal: false,
  setPasswordValidity: () => {},
  onKeyDown: () => {},
  modalClassName: 'password-modal',
};

PasswordInput.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  showEyeIcon: PropTypes.bool,
  handlePasswordChange: PropTypes.func.isRequired,
  autoComplete: PropTypes.string,
  shouldShownValidationModal: PropTypes.bool,
  setPasswordValidity: PropTypes.func,
  onKeyDown: PropTypes.func,
  modalClassName: PropTypes.string,
};

export default React.memo(PasswordInput);
