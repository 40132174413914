import React from 'react';
import PropTypes from 'prop-types';
import HorizontalLine from '../../../../Common/Utils/HorizontalLine';
import Title from './Title';

import './Index.scss';

function Section({
  title, maxHeight, availableData, children,
}) {
  return (
    <div className="card_section" style={{ maxHeight }}>
      <Title title={title} availableData={availableData} />
      <HorizontalLine marginBottom="16px" />
      <div className="card_section__body">{children}</div>
    </div>
  );
}

Section.defaultProps = {
  maxHeight: null,
};

Section.propTypes = {
  maxHeight: PropTypes.string,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]).isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  availableData: PropTypes.object.isRequired,
};

export default Section;
