import IconButton from '@mui/material/IconButton';
import InputBase from '@mui/material/InputBase';
import Paper from '@mui/material/Paper';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import SearchIcon from '../../../assests/icons/search.svg';
import './Audit.scss';
import { updateFocussedInputs } from '../../../reducers/focussedInputs';

function SearchBar({
  handleDataChange, text, searchType,
}) {
  const [searchValue, setSearchValue] = useState('');
  const dispatch = useDispatch();

  const handleChange = (event) => {
    setSearchValue(event.target.value);
  };

  const handleSearchIconClick = () => {
    const data = {};
    data[searchType] = searchValue;
    handleDataChange(data);
    setSearchValue('');
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = {};
    data[searchType] = searchValue;
    handleDataChange(data);
  };

  useEffect(() => {
    const data = {};
    data[searchType] = searchValue;
    handleDataChange(data);
  }, [searchValue]);

  const handleFocus = (isFocussed) => {
    dispatch(updateFocussedInputs({ inputName: 'AppInput', isFocussed }));
  };

  return (
    <Paper
      component="form"
      onSubmit={handleSubmit}
      id="audit__searchBar__container"
    >
      <IconButton onClick={handleSearchIconClick}>
        <img src={SearchIcon} alt="search" id="audit__searchBar__search_icon" />
      </IconButton>
      <InputBase
        sx={{ flex: 1 }} // TODO: Figure out a way to do this with css.
        id="audit__searchBar__input"
        placeholder={text}
        value={searchValue}
        onChange={handleChange}
        onFocus={() => handleFocus(true)}
        onBlur={() => handleFocus(false)}
      />
    </Paper>
  );
}

SearchBar.defaultProps = {
  text: 'Search by Transaction ID',
  searchType: 'transactionId',
};

SearchBar.propTypes = {
  handleDataChange: PropTypes.func.isRequired,
  text: PropTypes.string,
  searchType: PropTypes.string,
};

export default SearchBar;
