import moment from 'moment';

export const billingStartDate = moment(process.env.REACT_APP_BILLING_START_DATE);
export const billingDateFormat = 'YYYY-MM-DD';
export const getMonthYearString = (date) => date.format('MMM \u{2018}YY');

export const initUsage = () => {
  const previousMonthStartDate = moment().date(1).subtract(1, 'month');
  const dropdownStartData = moment().date(1);
  const dates = [];
  while (billingStartDate <= dropdownStartData) {
    dates.push({
      label: getMonthYearString(billingStartDate),
      value: billingStartDate.clone(),
    });
    billingStartDate.add(1, 'month');
  }
  dates.reverse();
  return {
    previousMonthStartDate,
    dates,
  };
};

export const getAnalyticsBreakupDates = (billingMonth) => {
  const monthStartDateMoment = moment(billingMonth).startOf('month').utc();
  const monthEndDateMoment = moment(billingMonth).endOf('month').utc();
  return {
    monthStartDate: monthStartDateMoment.format('YYYY-MM-DD HH:mm:ss.SSS'),
    monthEndDate: monthEndDateMoment.format('YYYY-MM-DD HH:mm:ss.SSS'),
  };
};
