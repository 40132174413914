import React from 'react';
import PropTypes from 'prop-types';
import { get, isEmpty } from 'lodash';
import Table from './Table';
import Title from '../Title';

function TableDataContainer({
  title,
  maxHeight,
  whitelistedFields,
  pathToData,
  availableData,
}) {
  let tableHeader = null;
  let tableData = get(availableData, pathToData, []);

  if (!isEmpty(whitelistedFields)) {
    tableData = whitelistedFields.map((field) => {
      const tableItem = tableData.find(
        ({ key }) => key === field.key,
      );
      if (!tableItem) return false;
      if (field && field.isTitleRow) {
        tableHeader = field.label || tableItem.value;
        return false;
      }
      if (field && (tableItem.value || field.persist)) {
        return { ...tableItem, label: field.label };
      }
      return false;
    }).filter(Boolean);
  }

  if (isEmpty(tableData)) {
    return '';
  }

  return (
    <Table
      title={<Title title={title} availableData={availableData} />}
      header={tableHeader}
      maxHeight={maxHeight}
      tableRows={tableData}
      availableData={availableData}
    />
  );
}

TableDataContainer.defaultProps = {
  maxHeight: null,
  whitelistedFields: [],
};

TableDataContainer.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  maxHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  whitelistedFields: PropTypes.array,
  pathToData: PropTypes.string.isRequired,
  availableData: PropTypes.object.isRequired,
};

export default TableDataContainer;
