import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';

function Gap({ height, width }) {
  if (isEmpty(height) && isEmpty(width)) return null;

  return (
    <div style={{ paddingTop: height, paddingLeft: width }} />
  );
}

Gap.defaultProps = {
  height: null,
  width: null,
};

Gap.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
};

export default Gap;
