/* eslint-disable no-param-reassign */
import Hotjar from '@hotjar/browser';
import { createSlice } from '@reduxjs/toolkit';

export const initializationSlice = createSlice({
  name: 'initializations',
  initialState: {
    freshchatInitialized: false,
    hotjarInitialized: false,
    userInitialized: false,
    email: '',
    name: '',
    clientId: '',
  },
  reducers: {
    updateFreshChatInitialised: (state) => {
      state.freshchatInitialized = true;
      if (state.userInitialized) {
        window.fcWidget.user.setProperties({
          email: state.email,
          firstName: state.name,
        });
        window.fcWidget.user.setMeta({
          clientId: state.clientId,
        });
      }
    },
    updateHotjarInitialized: (state) => {
      state.hotjarInitialized = true;
      const companyName = state.email.slice(state.email.indexOf('@') + 1,
        state.email.lastIndexOf('.'));
      if (state.userInitialized) {
        Hotjar.identify(state.email, {
          email: state.email,
          source: 'kyc_dashboard',
          company: companyName,
        });
      }
    },
    updateUserInitialized: (state, action) => {
      const { email, name, clientId } = action.payload;
      state.userInitialized = true;
      state.email = email;
      state.name = name;
      state.clientId = clientId;
      if (state.freshchatInitialized) {
        window.fcWidget.user.setProperties({
          email,
          firstName: name,
        });
        window.fcWidget.user.setMeta({
          clientId,
        });
      }
      const companyName = email.slice(email.indexOf('@') + 1, email.lastIndexOf('.'));
      if (state.hotjarInitialized) {
        Hotjar.identify(email, {
          email,
          source: 'kyc_dashboard',
          company: companyName,
        });
      }
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  updateFreshChatInitialised, updateHotjarInitialized, updateUserInitialized,
} = initializationSlice.actions;
export default initializationSlice.reducer;
