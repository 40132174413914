import React, { useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import PropTypes from 'prop-types';
import { ToolTip } from 'clm-components';
import ForwardButtonIcon from '../../../../assests/icons/forward_button.svg';
import BackwardButtonIcon from '../../../../assests/icons/backward_button.svg';
import PlayButtonIcon from '../../../../assests/icons/play_button.svg';
import PauseButtonIcon from '../../../../assests/icons/pause_button.svg';
import { timeStringToSeconds } from '../../../../utils/helpers';
import PrettoSlider from '../../../Common/Video/PrettoSlider';
import getIdForStatement from '../VideoCardUtils';

const VideoPlayer = ({
  videoSrc,
  setIsError,
  setErrorMessage,
  videoPlayerRef,
  statements,
  journeyId,
  moduleId,
  activeAttemptIndex,
  attemptIndex,
}) => {
  const [playbackRate, setPlaybackRate] = useState(1);
  const [videoState, setVideoState] = useState({
    playing: false,
    muted: false,
    volume: 0.5,
    played: 0,
    seeking: false,
    Buffer: true,
  });

  useEffect(() => {
    if (activeAttemptIndex !== attemptIndex && videoState.playing) {
      setVideoState({ ...videoState, playing: false });
    }
  }, [activeAttemptIndex]);

  const playPauseHandler = () => {
    setVideoState({ ...videoState, playing: !videoState.playing });
  };

  const rewindHandler = () => {
    videoPlayerRef.current.seekTo(videoPlayerRef.current.getCurrentTime() - 10);
  };

  const fastFowardHandler = () => {
    videoPlayerRef.current.seekTo(videoPlayerRef.current.getCurrentTime() + 10);
  };

  const onPlaybackError = () => {
    setIsError(true);
    setErrorMessage('Error Playing Video');
  };

  const getTime = (seconds) => {
    if (seconds === null || seconds === undefined || String(seconds) === 'Infinity') return '00:00';
    return new Date(seconds * 1000).toISOString().slice(14, 19);
  };

  const progressHandler = (state) => {
    if (!videoState.seeking) {
      setVideoState({ ...videoState, ...state });
    }
  };

  const seekMouseUpHandler = (e, evalue, sid) => {
    setVideoState({ ...videoState, seeking: false });
    videoPlayerRef.current.seekTo(evalue / 100);
    document.getElementById(sid).scrollIntoView({ behavior: 'smooth' });
  };

  const seekHandler = (e, evalue, sid) => {
    setVideoState({ ...videoState, played: parseFloat(evalue) / 100, seeking: true });
    seekMouseUpHandler(e, evalue, sid);
  };

  const changePlaybackRate = () => setPlaybackRate((prev) => (prev === 1 ? 2 : 1));

  const getMinMaxValuesForSlider = (val) => {
    const totalDuration = videoPlayerRef?.current?.getDuration();
    return parseInt((val / totalDuration) * 100, 10);
  };

  return (

    <>
      <p id="video_details_col_title">VIDEO RECORDING</p>
      <div data-hj-suppress aria-hidden onClick={playPauseHandler} id="video_player">
        <ReactPlayer
          ref={videoPlayerRef}
          playing={videoState.playing}
          loop
          width="100%"
          height="585px"
          onError={onPlaybackError}
          url={videoSrc}
          playbackRate={playbackRate}
          onProgress={progressHandler}
          // This is currently being done due to lack of metadata on the videos with webm format due
          // Due to this lack of information, the duration comes out to be Infinity
          // We're tricking the player into loading the entire video so we know the exact duration
          // This is a hack which needs to be fixed in future.
          onDuration={(duration) => {
            if (duration === Infinity) {
              videoPlayerRef?.current?.seekTo(100000000);
              setTimeout(() => {
                videoPlayerRef?.current?.seekTo(0);
              }, 2000);
            }
          }}
        />
      </div>
      <div id="video_details_controls">
        <div id="video_details_controls_slider_div">
          {statements && statements.map((statement, statementIndex) => (
            <ToolTip
              key={statement.statementId}
              title={
                    (
                      <>
                        <span>
                          Statement
                          {' '}
                          {statementIndex + 1}
                        </span>
                      </>
                    )
                  }
              placement="top-start"
            >
              <PrettoSlider
                onChange={(event) => seekHandler(
                  event,
                  event.target.value,
                  getIdForStatement({
                    journeyId,
                    moduleId,
                    attemptIndex,
                    statementId: statement.statementId,
                    statementIndex,
                  }),
                )}
                min={getMinMaxValuesForSlider(timeStringToSeconds(statement.startTimestamp))}
                max={getMinMaxValuesForSlider(timeStringToSeconds(statement.endTimestamp))}
                value={videoState.played * 100}
              />
            </ToolTip>
          ))}
        </div>
        <div id="video_details_controls_div">
          <p id="video_details_controls_div_info">
            {getTime(videoPlayerRef?.current?.getCurrentTime())}
            {' '}
            /
            {' '}
            {getTime(videoPlayerRef?.current?.getSecondsLoaded())}
          </p>
          <div id="video_details_controls_div_parent">
            <div aria-hidden onClick={rewindHandler} id="video_details_controls_div_icon">
              <img src={BackwardButtonIcon} alt="Rewind Button" />
            </div>
            {videoState.playing ? (
              <div aria-hidden onClick={playPauseHandler} id="video_details_controls_div_icon">
                <img src={PauseButtonIcon} alt="Pause Button" />
              </div>
            ) : (
              <div aria-hidden onClick={playPauseHandler} id="video_details_controls_div_icon">
                <img src={PlayButtonIcon} alt="Play Button" />
              </div>
            )}
            <div aria-hidden onClick={fastFowardHandler} id="video_details_controls_div_icon">
              <img src={ForwardButtonIcon} alt="Forward Button" />
            </div>
          </div>
          <div id="video_details_controls_div_icon_playback">
            <p aria-hidden onClick={changePlaybackRate} id="video_details_controls_div_icon_playback_speed">
              {playbackRate}
              x
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

VideoPlayer.propTypes = {
  videoSrc: PropTypes.string.isRequired,
  setIsError: PropTypes.func.isRequired,
  setErrorMessage: PropTypes.func.isRequired,
  videoPlayerRef: PropTypes.func.isRequired,
  statements: PropTypes.array.isRequired,
  journeyId: PropTypes.string.isRequired,
  moduleId: PropTypes.string.isRequired,
  activeAttemptIndex: PropTypes.number.isRequired,
  attemptIndex: PropTypes.number.isRequired,
};

export default VideoPlayer;
