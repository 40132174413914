import React, { useState, useEffect } from 'react';
import { PermissionWrapper, PermissionFunctionWrapper } from 'storybook-ui-components';
import { useSelector } from 'react-redux';
import { Button } from '@mui/material';
import { ReactComponent as LinkIcon } from '../../../assests/icons/link.svg';
import NewApplicationModal from './NewApplicationModal';
import rudderstackEvents from '../../../constants/rudderstackEventNames';
import { useFormatAnalyticsData } from '../../../utils/lib';
import useGetUserPermissions from '../../../Permissions/hooks';
import getPermission from '../../../Permissions/mapping';

function NewApplication() {
  const [isModalOpen, setIsModelOpen] = useState(false);
  const userPermissions = useGetUserPermissions();
  const formatAnalyticsData = useFormatAnalyticsData();

  const email = useSelector((state) => state.user.email);
  const clientId = useSelector((state) => state.user.clientId);
  const AppInput = useSelector(
    (state) => state.focussedInputs.AppInput,
  );

  const openModel = () => {
    formatAnalyticsData(email, clientId, rudderstackEvents.DASHBOARD_APPLICATIONSTABLE_NEWAPPBUTTON_CLICK, 'Home');
    setIsModelOpen(true);
  };

  const closeModel = () => {
    openModel();
    setIsModelOpen(false);
  };

  const openModalOnKeyPress = PermissionFunctionWrapper((event) => {
    if (event.keyCode === 78 && !AppInput) {
      setTimeout(() => {
        openModel();
      }, 10);
    }
  }, getPermission('newApplication'), userPermissions);

  useEffect(() => {
    document.addEventListener('keydown', openModalOnKeyPress);
    // Remove listener on component unmount
    return () => {
      document.removeEventListener('keydown', openModalOnKeyPress);
    };
  }, [AppInput]);

  return (
    <>
      <NewApplicationModal isModalOpen={isModalOpen} closeModel={closeModel} />
      <Button
        className="application__subheading_button"
        onClick={openModel}
        variant="outlined"
        startIcon={<LinkIcon />}
        endIcon={<p className="application__subheading_button_end">N</p>}
      >
        New application
      </Button>
    </>
  );
}

export default PermissionWrapper(NewApplication, useGetUserPermissions, getPermission('newApplication'));
