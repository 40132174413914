import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import TableRow from './TableRow';

function Table({ item }) {
  const tableMetaData = get(item, 'endpointModule.table', {});

  const { title, rows } = tableMetaData;

  if (!rows) {
    return <></>;
  }

  return (
    <div className="generic_common_card__tab__body__table__container">
      <h1>{title}</h1>
      <div style={{ maxHeight: '500px' }} className="generic_common_card__tab__body__table">
        <table>
          {rows.map(({ path, label, persist }) => (
            <TableRow
              key={path}
              value={get(item?.extractedData, path)}
              path={path}
              label={label}
              persist={persist}
            />
          ))}
        </table>
      </div>
    </div>
  );
}

Table.propTypes = {
  item: PropTypes.object.isRequired,
};

export default Table;
