import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import './Common.scss';

function ToastNotification({ imageComponentRenderer, message }) {
  const openAlert = useSelector((state) => state.alert.showNotification) || false;

  return (
    <div id="custom_toast_container" className="toast_container">
      <div className={`toast_notification ${openAlert}`}>
        {imageComponentRenderer ? imageComponentRenderer() : null}
        <p>{message}</p>
      </div>
    </div>
  );
}

ToastNotification.defaultProps = {
  imageComponentRenderer: null,
};

ToastNotification.propTypes = {
  imageComponentRenderer: PropTypes.func,
  message: PropTypes.string.isRequired,
};

export default ToastNotification;
