import React from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import { get, isEmpty } from 'lodash';
import { useSelector } from 'react-redux';
import * as Sentry from '@sentry/react';
import CustomButton from '../../../../../../Common/Buttons/CustomButton';
import useShowErrorAlert, {
  useShowCustomAlert,
} from '../../../../../../../utils/lib';
import errorCode from '../../../../../../../constants/errorCode';

function HeaderButton({ icon, buttonConfig, availableData }) {
  const showErrorAlert = useShowErrorAlert();
  const showCustomAlert = useShowCustomAlert();
  const currentCredential = useSelector((state) => state.appDetails.currentCredential) || {};

  if (isEmpty(buttonConfig)) {
    return null;
  }

  const { url, label, type = 'download' } = buttonConfig;

  const onClick = async () => {
    try {
      const { data } = await axios.post(url, {
        transactionId: get(availableData, 'transactionData.transactionId', ''),
        requestId: get(availableData, 'recordData.requestId', ''),
      },
      {
        headers: { ...currentCredential },
      });
      const successMessage = get(data, 'result.message', 'Success');
      showCustomAlert({ alertSeverity: 'success', message: successMessage });
      if (type === 'download') {
        const link = get(data, 'result.url');
        if (!isEmpty(link)) {
          window.open(link, '_blank');
        } else {
          showErrorAlert({ message: 'Server Error' });
        }
      }
    } catch (error) {
      const errorMessage = get(error, 'res.data.result.error', 'Server Error');
      showErrorAlert({ error, message: errorMessage });
      Sentry.captureException(`${errorCode.APPLICATION_DYNAMIC_CARD_API_ERROR} - ${error}`, {
        extra: {
          errorMessage,
        },
      });
    }
  };

  return (
    <CustomButton
      className="card_type_five__content__header__actions__button"
      onClick={onClick}
      IconLeft={icon}
    >
      {label}
    </CustomButton>
  );
}

HeaderButton.defaultProps = {
  icon: '',
};

HeaderButton.propTypes = {
  icon: PropTypes.element,
  buttonConfig: PropTypes.object.isRequired,
  availableData: PropTypes.object.isRequired,
};

export default HeaderButton;
