import { first, get } from 'lodash';
import { evaluateCondition } from '../../../../RightPanel/ReviewTagsCard/utils';

const getBodyLayout = (data, bodyLayouts) => {
  const matchingLayout = bodyLayouts
    .find(({ condition }) => evaluateCondition({ availableData: data }, condition));
  if (!matchingLayout) return first(bodyLayouts)?.layout;
  return get(matchingLayout, 'layout', {});
};

export const getFilesFromRecord = (record) => {
  const fileUrls = get(record, 'fileUrls', []);
  return fileUrls.reduce(
    (obj, item) => Object.assign(obj, { [item.type]: item.url }),
    {},
  );
};

export default getBodyLayout;
