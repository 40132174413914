import { get } from 'lodash';
import applicationDetailsPagePermissions from './pages/appDetails';
import auditPagePermissions from './pages/auditPage';
import credentialPagePermissions from './pages/credentialPage';
import homePagePermissions from './pages/homePage';
import reviewPagePermissions from './pages/reviewPage';
import usagePagePermissions from './pages/usagePage';

const requiredPermissionsMapping = {
  ...applicationDetailsPagePermissions,
  ...auditPagePermissions,
  ...credentialPagePermissions,
  ...homePagePermissions,
  ...reviewPagePermissions,
  ...usagePagePermissions,
};

const getPermission = (id) => get(
  requiredPermissionsMapping, id, {},
);

export default getPermission;
