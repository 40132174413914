import React from 'react';
import PropTypes from 'prop-types';
import { join } from 'lodash';

function Text({ size, classes, label }) {
  return (
    <div className={`card-text ${join(classes, ' ')} ${size}`}>{label}</div>
  );
}

Text.defaultProps = {
  size: 'h1',
  classes: [],
};

Text.propTypes = {
  size: PropTypes.string,
  classes: PropTypes.array,
  label: PropTypes.string.isRequired,
};

export default Text;
