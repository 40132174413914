import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import LeftArrowIcon from '../../../assests/icons/leftArrow.svg';
import MultiSelect from '../Inputs/MultiSelect';
import './Cards.scss';

function MultiSelectCard({
  id,
  onFilterAdd,
  placeholder,
  options,
  title,
  handleBack,
  handleClose,
  details,
}) {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [onRadio, setOnRadio] = useState(true);
  const handleSelect = (value, label) => {
    setSelectedOptions([...selectedOptions, {
      value, label,
    }]);
  };

  const handleRemove = (valueToRemove) => {
    setSelectedOptions((oldList) => oldList.filter(({ value }) => value !== valueToRemove));
  };

  const onButtonClick = () => {
    if (!selectedOptions.length) return;
    handleClose();
    onFilterAdd({ ...details, selectedOptions, contains: onRadio });
  };

  const radioOnChange = (bool) => {
    setOnRadio(bool);
  };

  useEffect(() => {
    if (details.selectedOptions) setSelectedOptions(details.selectedOptions);
    if (details.contains !== undefined) setOnRadio(details.contains);
  }, []);

  return (
    <div id={id} className="common_card_container">
      <div className="common_card_header">
        <img className="common_card_header_back_button" src={LeftArrowIcon} alt="" onClick={handleBack || handleClose} aria-hidden />
        <div className="common_card_header_title">{title}</div>
      </div>
      <div className="common_card_filter_container">
        <div className="common_card_radio_container">
          <div className="common_card_radio_item">
            <div className={`${onRadio && 'active'}`} onClick={() => radioOnChange(true)} aria-hidden />
            <span>is one of these</span>
          </div>
          {onRadio && (
            <div className="common_card_filter">
              <MultiSelect
                availableOptions={options}
                placeholder={placeholder}
                handleSelect={handleSelect}
                handleRemove={handleRemove}
                selectedOptions={selectedOptions}
              />
            </div>
          )}
          <div className="common_card_radio_item">
            <div className={`${!onRadio && 'active'}`} onClick={() => radioOnChange(false)} aria-hidden />
            <span>is none of these</span>
          </div>
          {!onRadio && (
            <div className="common_card_filter">
              <MultiSelect
                availableOptions={options}
                placeholder={placeholder}
                handleSelect={handleSelect}
                handleRemove={handleRemove}
                selectedOptions={selectedOptions}
              />
            </div>
          )}
        </div>
      </div>
      <div className="common_card_submit_container">
        <button
          type="button"
          className={`common_card_submit_button ${selectedOptions && selectedOptions.length ? '' : 'disabled'}`}
          onClick={onButtonClick}
        >
          Apply
        </button>
      </div>
    </div>
  );
}

MultiSelectCard.defaultProps = {
  id: '',
  placeholder: 'Select options',
  title: 'Filter',
  handleBack: null,
};

MultiSelectCard.propTypes = {
  id: PropTypes.string,
  onFilterAdd: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  options: PropTypes.object.isRequired,
  title: PropTypes.string,
  handleBack: PropTypes.func,
  handleClose: PropTypes.func.isRequired,
  details: PropTypes.object.isRequired,
};

export default MultiSelectCard;
