import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import FaceMatchPanel from '../Panels/FaceMatchPanel';
import '../../Details.scss';
import CommonHeader from './CommonHeader';
import CustomTabs from '../../../Common/Utils/CustomTabs';

function FaceMatch({ moduleName, subType, records }) {
  const [dbCheckDetailArray, setDbCheckDetailArray] = useState([]);
  const [value, setValue] = useState(0);
  useEffect(() => {
    setDbCheckDetailArray(records);
    setValue(0);
  }, [records]);

  return (
    <div id="details__container">
      <CommonHeader moduleName={moduleName} subType={subType} />
      <CustomTabs
        id="details__body_tabs"
        tabIndex={value}
        setTabIndex={setValue}
        tabArray={dbCheckDetailArray}
      />
      {dbCheckDetailArray.map((item, index) => (
        <FaceMatchPanel
          key={item.id}
          value={value}
          index={index}
          item={item}
          createdAt={item.createdAt}
          status={item.apiStatus}
        />
      ))}
    </div>
  );
}

FaceMatch.propTypes = {
  moduleName: PropTypes.string.isRequired,
  subType: PropTypes.string.isRequired,
  records: PropTypes.arrayOf(PropTypes.any.isRequired).isRequired,
};
export default FaceMatch;
