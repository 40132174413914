import React, { useState } from 'react';
import PropTypes from 'prop-types';
import FilterCard from './FilterCard';
import './Filter.scss';
import CustomPopupContainer from '../Popup/CustomPopupContainer';
import AddFilterButton from './AddFilterButton';

function AddFilter({
  onFilterAdd, availableFilterList, selectedFilterList,
}) {
  const [currentSelectedFilter, setCurrentSelectedFilter] = useState('');
  return (
    <CustomPopupContainer
      popupTrigger={<AddFilterButton selectedFilter={currentSelectedFilter} />}
      popupComponent={(
        <FilterCard
          onFilterAdd={onFilterAdd}
          availableFilterList={availableFilterList}
          selectedFilterList={selectedFilterList}
          setCurrentSelectedFilter={setCurrentSelectedFilter}
        />
      )}
    />
  );
}

AddFilter.propTypes = {
  onFilterAdd: PropTypes.func.isRequired,
  availableFilterList: PropTypes.array.isRequired,
  selectedFilterList: PropTypes.array.isRequired,
};

export default AddFilter;
