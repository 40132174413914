import React, { useState, useEffect } from 'react';
import { first, get, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import './Card.scss';
import '../../../Details.scss';
import { useDispatch } from 'react-redux';
import Header from './Header';
import CustomTabs from '../../../../Common/Utils/CustomTabs';
import ContentError from './Content/ContentError';
import ContentWrapper from './Content/ContentWrapper';
import { updateAvailableData } from '../../../../../reducers/appDetails';
import { getFilesFromRecord } from './Content/utils';
import { MissingRecordComponent } from '../Common';

function Card({
  moduleId, moduleName, subType, transactionData, records,
}) {
  const [currentRecord, setCurrentRecord] = useState({});
  const [currentRecordIndex, setCurrentRecordIndex] = useState(0);
  const [moduleConfig, setModuleConfig] = useState({});

  const dispatch = useDispatch();

  useEffect(() => {
    if (!isEmpty(records)) {
      setCurrentRecord(first(records));
    }
  }, [records]);

  const isMissing = currentRecord?.isMissing || false;

  useEffect(() => {
    if (!currentRecord.isMissing) {
      const fileData = getFilesFromRecord(currentRecord);
      dispatch(updateAvailableData({
        moduleId,
        data: {
          transactionData,
          recordData: currentRecord,
          fileData,
        },
      }));
      setModuleConfig(get(currentRecord, 'endpointModule', {}));
    } else {
      setModuleConfig({});
    }
  }, [currentRecord]);

  useEffect(() => {
    setCurrentRecord(records[currentRecordIndex]);
  }, [currentRecordIndex]);

  const { contentConfig = {}, hitsConfig = {} } = moduleConfig;

  return (
    <div className="card_type_five__container">
      <Header moduleName={moduleName} subType={subType} />
      <CustomTabs
        className="card_type_five__tabs"
        tabIndex={currentRecordIndex}
        setTabIndex={setCurrentRecordIndex}
        tabArray={records}
      />
      {!isMissing
      && !isEmpty(contentConfig) ? (
        <ContentWrapper
          contentConfig={contentConfig}
          hitsConfig={hitsConfig}
          moduleId={moduleId}
        />
        ) : (
          !isMissing && <ContentError />
        )}
      {isMissing && <MissingRecordComponent />}
    </div>
  );
}

Card.propTypes = {
  moduleId: PropTypes.string.isRequired,
  moduleName: PropTypes.string.isRequired,
  subType: PropTypes.string.isRequired,
  transactionData: PropTypes.object.isRequired,
  records: PropTypes.array.isRequired,
};

export default Card;
