import React from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useLocation } from 'react-router-dom';
import { get } from 'lodash';
import Drawer from '../components/Main/Drawer';
import './Container.scss';
import TermAndConditionsClickwrap from '../components/Common/TermAndConditionsClickwrap';
import CustomAlert from '../components/Common/CustomAlert';
import navMapping from '../utils/navMapping';

function Main() {
  const location = useLocation();
  const { pathname } = location;
  const { navKey, subNavKey } = get(navMapping, pathname, {});

  const userEmail = useSelector((state) => state.user.email);
  const isNdaAgreed = useSelector((state) => state.user.isNdaAgreed);
  const shouldShowNDA = useSelector((state) => state.user.shouldShowNDA);

  return (
    <div id="main__container">
      <div id="main__container__navbar">
        <Drawer activeNav={navKey} activeSubNav={subNavKey} pathname={pathname} />
      </div>
      <div id="main__container__body">
        {!isNdaAgreed && !!userEmail && shouldShowNDA !== false && (
        <TermAndConditionsClickwrap userEmail={userEmail} />
        )}
        <Outlet />
      </div>
      <CustomAlert />
    </div>
  );
}

export default Main;
