import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

function ShakeContainer({ isError, children }) {
  const [className, setClassName] = useState('onboard-container');

  useEffect(() => {
    let timeoutRef;
    if (isError) {
      setClassName('onboard-container shake-container');
      timeoutRef = setTimeout(() => {
        setClassName('onboard-container');
      }, 200);
    }
    return () => clearTimeout(timeoutRef);
  }, [isError]);

  return <div className={className}>{children}</div>;
}

ShakeContainer.propTypes = {
  isError: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

export default ShakeContainer;
