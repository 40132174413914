import React from 'react';
import PropTypes from 'prop-types';
import sdkErrorIcon from '../../../../assests/icons/sdkErrorIcon.svg';

function SdkError({ errorMsg }) {
  return (
    <div id="right_panel__review_card__error">
      <h4>Error from SDK</h4>
      <div id="right_panel__review_card__error__message">
        <img src={sdkErrorIcon} alt="message" />
        {errorMsg}
      </div>
    </div>
  );
}

SdkError.propTypes = {
  errorMsg: PropTypes.object.isRequired,
};

export default SdkError;
