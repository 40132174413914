import React from 'react';
import PropTypes from 'prop-types';
import { Spinner } from 'clm-components';

function SubmitButton({
  isValid,
  isLoading,
  handleSubmit,
  buttonText,
}) {
  const getClassName = () => {
    if (!isLoading && isValid) return 'static-button interactive-button';
    if (isLoading) return 'static-button loading-button';
    return 'static-button';
  };

  const handleClickFn = !isValid ? () => {} : handleSubmit;

  return (
    <button
      type="submit"
      className={getClassName()}
      onClick={handleClickFn}
    >
      {!isLoading ? (
        buttonText
      ) : (
        <div className="display-center">
          <Spinner />
        </div>
      )}
    </button>
  );
}

SubmitButton.defaultProps = {
  buttonText: 'Submit',
};

SubmitButton.propTypes = {
  isValid: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  buttonText: PropTypes.string,
};

export default SubmitButton;
