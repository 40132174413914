import { set } from 'lodash';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { TextField, Button } from '@mui/material';
import './NewApplication.scss';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import * as Sentry from '@sentry/react';
import useShowErrorAlert, { useShowCustomAlert, useFormatAnalyticsData, useFormatPerformanceAnalyticsData } from '../../../utils/lib';
import { StyledModal, Backdrop } from '../../Common/StyledModal';
import AlertCode from '../../../constants/alertCodes';
import { ReactComponent as CloseIcon } from '../../../assests/icons/close.svg';
import rudderstackEvents from '../../../constants/rudderstackEventNames';
import SingleSearchSelect from '../../Common/Inputs/SingleSearchSelect';
import ApplicationInput from './ApplicationInput';
import linkKycStart from '../../../api/linkKyc';
import errorCode from '../../../constants/errorCode';
import { PERFORMANCE_METRIC_EVENTS } from '../../../config';
import { isNumber } from '../../../utils/helpers';

function NewApplicationModal({ isModalOpen, closeModel }) {
  const showErrorAlert = useShowErrorAlert();
  const formatAnalyticsData = useFormatAnalyticsData();
  const formatPerformanceAnalyticsData = useFormatPerformanceAnalyticsData();
  const email = useSelector((state) => state.user.email);
  const clientId = useSelector((state) => state.user.clientId);
  const [transactionId, setTransactionId] = useState('');
  const [generatedLink, setGeneratedLink] = useState('');
  const [inputs, setInputs] = useState({});
  const [idHasInvalidChar, setIdHasInvalidChar] = useState(false);
  const [currentWorkflowId, setCurrentWorkflowId] = useState(null);
  const [currentWorkflowName, setCurrentWorkflowName] = useState('');
  const [loading, setLoading] = useState(false);
  const workflowList = useSelector((state) => state.user.workflowList);
  const currentAppId = useSelector((state) => state.user.credState?.current?.appId);
  const showAlert = useShowCustomAlert();
  const handleChange = (event) => {
    const obtainedValue = event.target.value;
    if (obtainedValue.indexOf(' ') !== -1) {
      setIdHasInvalidChar(true);
      return;
    }
    setIdHasInvalidChar(false);
    setTransactionId(event.target.value);
  };

  const handleInputChange = (event, input) => {
    setInputs({ ...inputs, [input]: event.target.value });
  };

  const generateLink = async () => {
    setLoading(true);
    const eventObj = {
      APIEndPoint: 'api/v1/user/link-kyc/start',
      metaData: {
        appId: currentAppId,
        workflowId: currentWorkflowId,
        transactionId,
      },
    };
    const generateLinkStartTime = performance.now();
    if (workflowList && workflowList.length) {
      formatAnalyticsData(email, clientId, rudderstackEvents.DASHBOARD_APP_OVERLAY_GENLINK_CLICK, 'New Application');
      if (currentWorkflowId && transactionId) {
        try {
          const newLink = await linkKycStart(
            currentAppId, currentWorkflowId, transactionId, inputs,
          );
          setGeneratedLink(newLink);
          setTransactionId('');
          set(eventObj, 'statusCode', 200);
        } catch (error) {
          const { response } = error;
          if (isNumber(response?.statusCode)) set(eventObj, 'statusCode', response?.statusCode);
          if (response && response.status && response.status === 400) {
            Sentry.captureException(`${errorCode.APPLICATION_DUPLICATE_TRANSACTION_ID} - ${error}`, {
              extra: {
                errorMessage: AlertCode.DUPLICATE_TRANSACTIONID_LINK,
              },
            });
            showErrorAlert({ error, message: AlertCode.DUPLICATE_TRANSACTIONID_LINK });
          } else if (response && response?.status === 422) {
            // error - transactionId matches the inverted pattern: /[$&+,;=?@#|'<>.^*(){}\\\\~%!`]/
            if (response?.data?.error?.includes(`"transactionId" with value "${transactionId}" matches the inverted pattern`)) {
              Sentry.captureException(`${errorCode.APPLICATION_INVLAID_TRANSACTION_ID} - ${error}`, {
                extra: {
                  errorMessage: AlertCode.DUPLICATE_TRANSACTIONID_LINK,
                },
              });
              showErrorAlert({ error, message: AlertCode.INVALID_TRANSACTION_ID });
            }
          } else {
            Sentry.captureException(`${errorCode.APPLICATION_GENERATE_LINK_ERROR} - ${error}`, {
              extra: {
                errorMessage: AlertCode.ERROR_GENERATING_LINK,
              },
            });
            showErrorAlert({ error, message: AlertCode.ERROR_GENERATING_LINK });
          }
        }
      } else {
        showAlert({ alertSeverity: 'error', message: 'No AppId Selected' });
      }
    } else {
      Sentry.captureException(`${errorCode.APPLICATION_NO_WORKFLOWS} ${AlertCode.NO_WORKFLOWS}`, {
        extra: {
          errorMessage: AlertCode.NO_WORKFLOWS,
        },
      });
      showErrorAlert({ alertSeverity: 'error', message: AlertCode.NO_WORKFLOWS });
    }
    const processingTime = performance.now() - generateLinkStartTime;
    set(eventObj, 'processingTime', processingTime);
    const eventName = PERFORMANCE_METRIC_EVENTS.APPLICATION_PAGE_GENERATE_APPLICATION_LINK;
    formatPerformanceAnalyticsData(eventObj, eventName);
    setLoading(false);
  };

  const generateNewLink = () => {
    setGeneratedLink('');
  };

  const setDefaultInputs = (workflowInputs) => {
    const inputObj = {};
    if (workflowInputs) workflowInputs.forEach((input) => { inputObj[input] = ''; });
    setInputs(inputObj);
  };

  useEffect(() => {
    if (!isModalOpen) {
      setGeneratedLink('');
      setTransactionId('');
    }
  }, [isModalOpen, workflowList, currentWorkflowId]);

  const handleWorkflowSelect = (workflowId) => {
    const currentWorkflow = workflowList.filter((workflow) => workflow.id === workflowId);
    setCurrentWorkflowId(workflowId);
    setDefaultInputs(currentWorkflow[0].inputs);
    setCurrentWorkflowName(currentWorkflow[0].name || currentWorkflow[0].id);
  };

  const getDisabledState = () => transactionId === '' || idHasInvalidChar || loading;

  return (
    <>
      <StyledModal
        open={isModalOpen}
        onClose={closeModel}
        components={
          { Backdrop }
        }
        onBackdropClick={closeModel}
      >
        <div id="new_application_modal__container">
          <div id="new_application_modal__header_container">
            <p id="new_application_modal__header_container__heading">  Create link to verify a new user </p>
            <Button id="new_application_modal__header_container__button" onClick={closeModel}>
              <CloseIcon />
            </Button>
          </div>
          <div id="new_application_modal__body_container">
            {generatedLink.length === 0 ? (
              <>
                <div id="new_application_modal__body_container__workflow_select_container">
                  <p>Workflow Selected:</p>
                  <SingleSearchSelect
                    value={currentWorkflowName}
                    onValueChange={handleWorkflowSelect}
                    optionList={workflowList}
                    placeholder="Search for workflows..."
                    emptyText="No workflow found"
                    optionValueKey="id"
                    optionNameKey="name"
                  />
                </div>
                <p id="new_application_modal__body_container__subheading"> Generate a link and share it with a customer to verify them</p>
                <p id="new_application_modal__body_container__input_label">Enter a Transaction ID</p>
                <TextField
                  data-hj-suppress
                  id="new_application_modal__body_container__input"
                  size="small"
                  color="secondary"
                  placeholder="Unique identifier of the user, Eg: User ID, Customer ID"
                  autoFocus
                  onChange={handleChange}
                />
                {idHasInvalidChar && <p id="new_application_modal__body_container__subheading_error">Transaction ID should not contain spaces</p>}
                {
                  Object.keys(inputs).map((input) => (
                    <ApplicationInput
                      key={input}
                      input={input}
                      handleInputChange={handleInputChange}
                    />
                  ))
                }
              </>
            ) : (
              <>
                <p id="new_application_modal__body_container__subheading"> Share this link with your user to onboard them seamlessly</p>
                <div id="new_application_modal__body_container__link_container">
                  <a
                    id="new_application_modal__body_container__link"
                    href={generatedLink}
                    target="_blank"
                    rel="noreferrer"
                    onClick={() => {
                      formatAnalyticsData(email, clientId, rudderstackEvents.DASHBOARD_APP_NEW_APP_LINK_OPEN, 'New Application');
                    }}
                  >
                    {
                      generatedLink.substring(0, 33)
                    }
                    ...
                  </a>
                  <CopyToClipboard
                    text={generatedLink}
                    onCopy={() => {
                      showAlert({ message: AlertCode.COPIED_TO_CLIPBOARD });
                    }}
                  >
                    <Button id="new_application_modal__body_container__link_copy" title="Copy">
                      Copy Link
                    </Button>
                  </CopyToClipboard>
                </div>
              </>
            )}
          </div>
          <div id="new_application_modal__footer_container">
            {generatedLink.length === 0 ? (
              <div id="new_application_modal__footer_container__generate_button_wrapper" className={`${getDisabledState() && 'disabled'}`}>
                <Button id="new_application_modal__footer_container__generate_button" className={`${getDisabledState() && 'disabled'}`} onClick={generateLink}>
                  {!loading ? 'Generate link' : 'Loading..'}
                </Button>
              </div>
            ) : (
              <Button id="new_application_modal__footer_container__generate_new_button" onClick={generateNewLink}>
                Generate another link
              </Button>
            )}
          </div>
        </div>
      </StyledModal>
    </>
  );
}

NewApplicationModal.propTypes = {
  isModalOpen: PropTypes.bool.isRequired,
  closeModel: PropTypes.func.isRequired,
};

export default NewApplicationModal;
