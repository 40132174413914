import {
  first, get, isEmpty, keys,
} from 'lodash';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateCredState } from '../../../reducers/user';
import CommonSelect from '../Inputs/CommonSelect';

const label = 'Choose a UseCase';

function UseCaseDropdown() {
  const credMapping = useSelector((state) => state.user.credState?.credMapping);
  const currentClientId = useSelector((state) => state.user.credState?.current?.clientId);
  const currentUseCase = useSelector((state) => state.user.credState?.current?.useCase);

  const dispatch = useDispatch();

  const currentUseCaseMapping = get(credMapping, currentClientId, {});

  if (isEmpty(currentUseCaseMapping) || keys(currentUseCaseMapping).length <= 1) {
    return null;
  }

  const useCaseOptions = keys(currentUseCaseMapping).map((key) => ({
    label: key,
    value: key,
  }));

  const handleUseCaseChange = (value) => {
    const appIdObject = first(get(credMapping, [currentClientId, value]));
    const { appId, type } = appIdObject || {};
    if (currentUseCase !== value) {
      dispatch(updateCredState({ useCase: value, appId, type }));
    }
  };

  const displayComponentRenderer = () => (
    <>
      <>
        for |
        {' '}
      </>
      {currentUseCase}
    </>
  );

  return (
    <CommonSelect
      options={useCaseOptions}
      label={label}
      handleChange={handleUseCaseChange}
      initValue={currentUseCase}
      displayComponentRenderer={displayComponentRenderer}
    />
  );
}

export default UseCaseDropdown;
