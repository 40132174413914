import React from 'react';
import * as Sentry from '@sentry/react';
import {
  get, isObject, flattenDeep, isArray,
} from 'lodash';
import { formatDateForApplicationDetailsSummary } from '../../../utils/dateFormats';
import Environment from './MetaComponents/Environment';
import Workflow from './MetaComponents/Workflow';
import { getFileExtentionFromUrl } from '../../../utils/helpers';

export const temp = '';

const commonSummaryIdKey = 'summary__header__details';
const commonSummaryMetaKey = 'summary__meta__details';

const summaryHeaderFieldsConfig = {
  createdAt: {
    id: `${commonSummaryIdKey}_created_at`,
    label: 'Created At',
    formatValueFunc: formatDateForApplicationDetailsSummary,
  },
  workflowId: {
    id: `${commonSummaryIdKey}_workflow_id`,
    label: 'Workflow',
    componentRenderer: ({ value }) => (
      <Workflow value={value} />
    ),
  },
  transactionId: {
    id: `${commonSummaryIdKey}_transaction_id`,
    label: 'Transaction ID',
  },
};

export const getSummaryHeaderData = (transactionData) => Object.entries(
  summaryHeaderFieldsConfig,
).map(
  ([field, {
    id, label, formatValueFunc, componentRenderer,
  }]) => {
    let value = get(transactionData, field) || '-';
    if (formatValueFunc) {
      value = formatValueFunc(value);
    }
    return {
      id,
      label,
      value,
      componentRenderer,
    };
  },
);

const summaryMetaFields = {
  platform: {
    id: `${commonSummaryMetaKey}_platform`,
    label: 'Platform',
  },
  ipAddress: {
    id: `${commonSummaryMetaKey}_ip_address`,
    label: 'IP address',
  },
  browser: {
    id: `${commonSummaryMetaKey}_browser`,
    label: 'Browser',
  },
  sdkVersion: {
    id: `${commonSummaryMetaKey}_sdk_version`,
    label: 'SDK Version',
  },
  env: {
    id: `${commonSummaryMetaKey}_environment`,
    label: 'Environment',
    componentRenderer: () => <Environment />,
  },
};

export const getSummaryMetaData = (transactionData) => Object.entries(
  summaryMetaFields,
).map(
  ([field, {
    id, label, formatValueFunc, componentRenderer,
  }]) => {
    let value = get(transactionData, field) || '-';
    if (formatValueFunc) {
      value = formatValueFunc(value);
    }
    return {
      id,
      label,
      value,
      componentRenderer,
    };
  },
);

export const getSummaryUserDetailsData = (transactionData) => {
  if (transactionData && transactionData.userDetails) {
    return Object
      .keys(transactionData.userDetails)
      .map((key) => ({ label: key, value: transactionData.userDetails[key] }));
  }

  return [];
};

const convertReqDataToObject = (reqData) => {
  const modifiedReqData = {};
  reqData.forEach((reqSection) => {
    reqSection.forEach(({ key, value }) => {
      modifiedReqData[key] = value;
    });
  });
  return modifiedReqData;
};

export const getImageTypesBasedOnConfig = (imageTypes, requestData, multiple = true) => {
  let finalImageTypes = multiple ? [] : null;
  if (imageTypes) {
    Object.entries(imageTypes).forEach(([imageType, { condition }]) => {
      try {
        /* eslint-disable no-new-func */
        if (new Function('request', `return ${condition}`)(requestData)) {
          if (multiple) {
            finalImageTypes.push(imageType);
          } else {
            finalImageTypes = imageType;
            return false;
          }
        }
      } catch (error) {
        Sentry.captureException(error);
      }
      return true;
    });
  }
  return finalImageTypes;
};

export const getVideoTypesBasedOnConfig = (videoTypes, requestData, multiple = true) => {
  let finalVideoTypes = multiple ? [] : null;
  if (videoTypes) {
    Object.entries(videoTypes).forEach(([videoType, { condition }]) => {
      try {
        /* eslint-disable no-new-func */
        if (new Function('request', `return ${condition}`)(requestData)) {
          if (multiple) {
            finalVideoTypes.push(videoType);
          } else {
            finalVideoTypes = videoType;
            return false;
          }
        }
      } catch (error) {
        Sentry.captureException(error);
      }
      return true;
    });
  }
  return finalVideoTypes;
};

export const collectMedia = (details) => {
  // Example endpointModule
  // item.endpointModule = {
  //   showImageInSummary: true,
  //   summaryImageTypes: {
  //     image: {
  //       condition: 'request.maskAadhaar === "no"',
  //       label: 'selfie'
  //     },
  //     cropped: {
  //       condition: 'request.maskAadhaar === "yes" && request.maskAadhaar === "yes"',
  //       label: 'id'
  //     },
  //   },
  // };
  const mediaCollectedByEndpoint = {};
  const sortedDetails = [...details].sort((a, b) => a.createdAt - b.createdAt);
  sortedDetails.forEach((item) => {
    const {
      endpointModule,
      fileUrls,
      createdAt,
      originalUrl,
      moduleId: itemModuleId,
      extractedReqData,
      hideOnPortal,
    } = item;
    if (!isObject(endpointModule)) return;
    if (hideOnPortal && hideOnPortal === 'yes') return;
    const {
      endpoint, showImageInSummary, summaryImageTypes,
    } = endpointModule;
    if (showImageInSummary) {
      let requestData = get(item, 'extractedReqData', []);
      // If data is not parsed in the backend, it returns an object. No need to convert in that case
      if (isArray(requestData)) {
        requestData = convertReqDataToObject(requestData);
      }
      const imageTypesToShow = getImageTypesBasedOnConfig(summaryImageTypes, requestData);
      const mediaCollected = [];
      imageTypesToShow.forEach((imageType) => {
        let image = fileUrls.find(({ type }) => type === imageType);
        // Logic to get base64String if present
        if (!image) {
          const base64String = get(flattenDeep(extractedReqData).find(({ key }) => key === imageType), 'value');
          image = base64String ? { url: `data:image/jpeg;base64,${base64String}` } : null;
        }
        const { label } = summaryImageTypes[imageType];
        if (image) {
          const extension = getFileExtentionFromUrl(image.url);
          mediaCollected.push({ label, src: image.url, type: extension === 'pdf' ? 'pdf' : 'image' });
        }
      });
      // Handle cases where different moduleIds use same endpoint
      const moduleId = itemModuleId || originalUrl;
      let keyForMedia = `${moduleId}_${endpoint}`;
      // For front,back of readID
      const endpointDocSide = get(endpointModule, 'conditions[0].expectedDocumentSide', null);
      if (endpointDocSide) {
        keyForMedia = `${keyForMedia}_${endpointDocSide}`;
      }
      // Save only latest image for endpoint
      if (keyForMedia in mediaCollectedByEndpoint) {
        if (mediaCollectedByEndpoint[keyForMedia].createdAt < createdAt) {
          mediaCollectedByEndpoint[keyForMedia] = {
            createdAt, mediaCollected,
          };
        }
      } else {
        mediaCollectedByEndpoint[keyForMedia] = {
          createdAt, mediaCollected,
        };
      }
    }
  });
  const finalImagesCollected = [];
  const finalPDFsCollected = [];

  Object.values(mediaCollectedByEndpoint).forEach(({ mediaCollected }) => {
    mediaCollected.forEach((mediaItem) => {
      if (mediaItem.type === 'pdf') {
        finalPDFsCollected.push(mediaItem);
      } else {
        finalImagesCollected.push(mediaItem);
      }
    });
  });
  return { imagesCollected: finalImagesCollected, pdfsCollected: finalPDFsCollected };
};

export const collectVideos = (details) => {
  const videosCollectedByEndpoint = {};
  const sortedDetails = [...details].sort((a, b) => a.createdAt > b.createdAt);
  sortedDetails.forEach((item) => {
    const {
      endpointModule,
      fileUrls,
      createdAt,
      originalUrl,
      moduleId: itemModuleId,
      hideOnPortal,
    } = item;
    if (!isObject(endpointModule)) return;
    if (hideOnPortal && hideOnPortal === 'yes') return;
    const {
      endpoint, showVideoInSummary, summaryVideoTypes,
    } = endpointModule;
    if (showVideoInSummary) {
      let requestData = get(item, 'extractedReqData', []);
      // If data is not parsed in the backend, it returns an object. No need to convert in that case
      if (isArray(requestData)) {
        requestData = convertReqDataToObject(requestData);
      }
      const videoTypesToShow = getVideoTypesBasedOnConfig(summaryVideoTypes, requestData);
      const videosCollected = [];
      videoTypesToShow.forEach((videoType) => {
        const video = fileUrls.find(({ type }) => type === videoType);
        const { label } = summaryVideoTypes[videoType];
        if (video) {
          videosCollected.push({ label, src: video.url });
        }
      });

      // Handle cases where different moduleIds use same endpoint
      const moduleId = itemModuleId || originalUrl;
      const keyForVideo = `${moduleId}_${endpoint}`;
      // Save only latest image for endpoint
      if (keyForVideo in videosCollectedByEndpoint) {
        if (videosCollectedByEndpoint[keyForVideo].createdAt < createdAt) {
          videosCollectedByEndpoint[keyForVideo] = {
            createdAt, videosCollected,
          };
        }
      } else {
        videosCollectedByEndpoint[keyForVideo] = {
          createdAt, videosCollected,
        };
      }
    }
  });
  const finalVideosCollected = [];
  Object.values(videosCollectedByEndpoint).forEach(({ videosCollected }) => {
    finalVideosCollected.push(...videosCollected);
  });
  return finalVideosCollected;
};
