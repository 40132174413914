import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty, toLower } from 'lodash';
import showApiStatus from '../../../constants/showApiStatus';
import showApplicationStatus from '../../../constants/showApplicationStatus';

import './Tags.scss';

function StatusTag({ type, status, className }) {
  if (isEmpty(status)) {
    return null;
  }

  const translateStatusFunc = type === 'api' ? showApiStatus : showApplicationStatus;
  const { status: translatedStatus } = translateStatusFunc(status);

  return (
    <span
      className={`${className} status_tag ${toLower(status)}`}
    >
      {translatedStatus}
    </span>
  );
}

StatusTag.defaultProps = {
  type: 'api',
  status: '',
  className: '',
};

StatusTag.propTypes = {
  type: PropTypes.string,
  status: PropTypes.string,
  className: PropTypes.string,
};

export default StatusTag;
