const navMapping = {
  '/home': {
    navKey: 'home',
  },
  '/applications': {
    navKey: 'applications',
  },
  '/review': {
    navKey: 'applications',
  },
  '/credentials': {
    navKey: 'devHub',
    subNavKey: 'credentials',
  },
  '/usage': {
    navKey: 'account',
    subNavKey: 'usage',
  },
  '/pricing': {
    navKey: 'account',
    subNavKey: 'pricingInfo',
  },
};

export default navMapping;
