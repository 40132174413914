const getIdForStatement = ({
  journeyId,
  moduleId,
  attemptIndex,
  statementId,
  statementIndex,
}) => {
  if (journeyId) {
    return journeyId + statementId;
  } if (moduleId) {
    return moduleId + attemptIndex + statementId + statementIndex;
  }
  return statementId;
};

export default getIdForStatement;
