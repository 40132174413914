/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = [];

export const dataSlice = createSlice({
  name: 'download',
  initialState,
  reducers: {
    startDownload: (state, action) => [...state, action.payload],
    downloadInProgress: (state, action) => state.map((item) => {
      if (item.type === action.payload) {
        return { ...item, inProgress: true };
      } return item;
    }),
    finishDownload: (state, action) => state.filter(({ type }) => type !== action.payload),
  },
});

// Action creators are generated for each case reducer function
export const {
  startDownload,
  downloadInProgress,
  finishDownload,
} = dataSlice.actions;
export default dataSlice.reducer;
