/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import {
  entries,
  first, get, groupBy, keys,
} from 'lodash';
import * as Sentry from '@sentry/react';
import useShowErrorAlert, { useLogoutAction } from './lib';
import AlertCode from '../constants/alertCodes';
import {
  updateUserInfo, updateIsAuthenticated,
  updateCredMapping, updateCredState,
} from '../reducers/user';
import { updateUserInitialized } from '../reducers/initialize';
import errorCode from '../constants/errorCode';

const RedirectPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate('/');
  }, []);
};

const PrivateRoute = () => {
  const dispatch = useDispatch();
  const showErrorAlert = useShowErrorAlert();
  const auth = useSelector((state) => state.user.auth);
  const permissions = useSelector((state) => state.user.permissions);
  const currentAppId = useSelector((state) => state.user.credState?.current?.appId);
  const logout = useLogoutAction();
  const queryParams = useMemo(
    () => new URLSearchParams(window.location.search),
    [],
  );

  const { pathname } = useLocation();

  const validateAuth = async () => {
    try {
      await axios({
        method: 'GET',
        url: `${process.env.REACT_APP_SERVER_URL}/api/v1/user/authenticate`,
      });
      dispatch(updateIsAuthenticated(true));
    } catch (error) {
      Sentry.captureException(`${errorCode.AUTH_EXPIRY_TOKEN} - ${error}`, {
        extra: {
          errorMessage: AlertCode.LOGIN,
        },
      });
      dispatch(updateIsAuthenticated(false));
      showErrorAlert({ error, message: AlertCode.LOGIN });
    }
  };

  const fetchUser = async () => {
    try {
      const userRes = await axios({
        method: 'GET',
        url: `${process.env.REACT_APP_SERVER_URL}/api/v1/user`,
      });
      const { appIds } = userRes.data.result;
      // Combined type TESTING and STAGING into STAGING
      const updateAppIds = {};
      entries(appIds).forEach(([key, value]) => {
        updateAppIds[key] = {
          ...value,
          type: value.type === 'TESTING' ? 'STAGING' : value.type,
        };
      });

      const appIdUpdate = entries(updateAppIds).map(([key, value]) => ({ appId: key, ...value }));
      const clientIdGroupedAppIds = groupBy(appIdUpdate, ({ clientId }) => clientId || 'default');
      entries(clientIdGroupedAppIds).forEach(([key, value]) => {
        clientIdGroupedAppIds[key] = groupBy(value,
          ({ useCase }) => useCase);
      });
      dispatch(updateUserInfo(userRes.data.result));
      dispatch(updateCredMapping(clientIdGroupedAppIds));
      dispatch(
        updateUserInitialized({
          email: userRes.data.result.email,
          clientId: userRes.data.result.clientId,
          name: userRes.data.result.name,
        }),
      );

      if (queryParams.get('appId') && queryParams.get('appId') in updateAppIds) {
        const initAppId = queryParams.get('appId');
        const payload = {
          appId: initAppId,
          clientId: updateAppIds[initAppId].clientId,
          useCase: updateAppIds[initAppId].useCase,
          type: updateAppIds[initAppId].type,
        };
        dispatch(updateCredState(payload));
      } else if (currentAppId) {
        const { clientId, useCase, type } = updateAppIds[currentAppId];
        dispatch(updateCredState({
          clientId, useCase, type, appId: currentAppId,
        }));
      } else {
        const clientId = first(keys(clientIdGroupedAppIds));
        const useCase = first(keys(get(clientIdGroupedAppIds, [clientId])));
        const appIdObject = first(get(clientIdGroupedAppIds, [clientId, useCase]));
        const { appId, type } = appIdObject || {};
        dispatch(updateCredState({
          clientId, useCase, appId, type,
        }));
      }
      await validateAuth();
    } catch (error) {
      Sentry.captureException(`${errorCode.AUTH_ERROR_FETCHING_USER} - ${error}`, {
        extra: {
          errorMessage: AlertCode.ERROR_FETCHING_USER,
        },
      });
      await logout();
      showErrorAlert({ error, message: AlertCode.ERROR_FETCHING_USER, onLoginError: true });
      dispatch(updateIsAuthenticated(false));
    }
  };

  useEffect(() => {
    if (auth) fetchUser();
  }, [auth]);

  useEffect(() => {
    validateAuth();
  }, [pathname]);

  if (!auth) return <RedirectPage />;

  // Wait for permissions to be set
  return permissions ? <Outlet /> : null;
};

export default PrivateRoute;
