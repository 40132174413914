/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import {
  isObject,
  isString,
  isEmpty,
} from 'lodash';
import Text from './Text';
import TemplateText from './TemplateText';

function Title({ title, defaultTitleSize, availableData }) {
  if (isString(title) && !isEmpty(title)) { return <Text size={defaultTitleSize} label={title} />; }
  if (isObject(title)) {
    if (title.type === 'templateText') return <TemplateText {...title} availableData={availableData} />;
    return <Text {...title} />;
  }
  return null;
}

Title.defaultProps = {
  defaultTitleSize: 'h1',
};

Title.propTypes = {
  defaultTitleSize: PropTypes.string,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]).isRequired,
  availableData: PropTypes.object.isRequired,
};

export default Title;
