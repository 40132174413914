import { useLocation } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import CustomAlert from '../components/Common/CustomAlert';
import ResponseModal from '../components/Demo/ResponseModal';
import clap from '../assests/images/clap.png';
import magnifyglass from '../assests/images/magnifyglass.png';
import redFlag from '../assests/images/redFlag.png';
import AlertCode from '../constants/alertCodes';
import LandingModal from '../components/Demo/LandingModal';
import useShowErrorAlert, { useCloseAlertAction, useFormatAnalyticsData } from '../utils/lib';
import rudderstackEvents from '../constants/rudderstackEventNames';

import './Container.scss';

function Verify({ isDemo }) {
  const showErrorAlert = useShowErrorAlert();
  const formatAnalyticsData = useFormatAnalyticsData();
  const { search } = useLocation();
  const email = useSelector((state) => state.user.email);
  const clientId = useSelector((state) => state.user.clientId);
  const transactionId = new URLSearchParams(search).get('id');
  const authToken = new URLSearchParams(search).get('authToken');

  const responseScreenConfigs = {
    success: {
      heading: 'Congratulations!',
      subHeading: 'Thanks for verifying your identity you can close this window now',
      icon: clap,
    },
    underReview: {
      heading: 'Under Review',
      subHeading: 'Your documents are under review. Once your identity is verified you will be updated',
      icon: magnifyglass,
    },
    failure: {
      heading: "Couldn't Verify Identity",
      subHeading: 'The information you provided is insufficient to complete your KYC. Please reach out to us on support',
      icon: redFlag,
    },
  };

  const [openLandingPage, setLandingPage] = useState(true);
  const [openResponseModal, setOpenResponseModal] = useState(false);
  const [responseModalConfig, setResponseModalConfig] = useState(responseScreenConfigs.success);
  const [applicationPresent, setApplicationPresent] = useState(false);

  const callback = (HyperKycResult) => {
    if (HyperKycResult.Success) {
      let isManualReview = false;
      const { data } = HyperKycResult.Success;
      const { docListData, faceData, faceMatchData } = data;
      const apiResponseObjs = [...docListData, faceData, faceMatchData];
      apiResponseObjs.forEach((obj) => {
        if (obj.action === 'manualReview') {
          isManualReview = true;
        }
      });
      if (isManualReview) {
        setResponseModalConfig(responseScreenConfigs.underReview);
        formatAnalyticsData(email, clientId, rudderstackEvents.WEB_DEMO_RESULTS_LAUNCH, 'web demo', 'under Review');
      } else {
        formatAnalyticsData(email, clientId, rudderstackEvents.WEB_DEMO_RESULTS_LAUNCH, 'web demo', 'Success');
        setResponseModalConfig(responseScreenConfigs.success);
      }
    } else {
      formatAnalyticsData(email, clientId, rudderstackEvents.WEB_DEMO_RESULTS_LAUNCH, 'web demo', 'Failure');
      setResponseModalConfig(responseScreenConfigs.failure);
    }

    if (HyperKycResult.Cancelled && HyperKycResult.Cancelled.data.docListData.length === 0) {
      setApplicationPresent(false);
    } else setApplicationPresent(true);

    setOpenResponseModal(true);
  };

  // start demo
  const startKYC = () => {
    const config = new window.HyperKycConfig(
      authToken,
      ['DOCUMENT', 'FACE'],
      transactionId,
    );
    window.HyperKYCModule.launch(config, callback);
  };

  const startDemo = () => {
    formatAnalyticsData(email, clientId, rudderstackEvents.WEB_DEMO_LANDING_GETSTARTED_CLICK, 'web demo');

    if (!transactionId || !authToken) {
      showErrorAlert({ message: AlertCode.INVALID_DEMO_URL });
    } else {
      setLandingPage(false);
      startKYC();
    }
  };

  const closeAlertAction = useCloseAlertAction();
  useEffect(() => {
    document.title = isDemo ? 'Demo - HyperVerge' : 'Identity Verification';
    closeAlertAction();
  }, []);

  return (
    <div id="demo__container">
      <LandingModal
        open={openLandingPage}
        buttonClick={startDemo}
      />
      <ResponseModal
        config={responseModalConfig}
        open={openResponseModal}
        applicationPresent={applicationPresent}
        isDemo={isDemo}
      />
      <CustomAlert />
    </div>
  );
}

export default Verify;

Verify.propTypes = {
  isDemo: PropTypes.bool.isRequired,
};
