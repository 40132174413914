import React from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { constants } from '../../config';
import { updateData as updateReviewData, updateTableMeta } from '../../reducers/review';
import {
  updateData, updatePage,
} from '../../reducers/data';
import Record from './Record';

function RecordWrapper() {
  // Review Data
  const statusCode = useSelector((s) => s.review.filterData.statusCode);
  const product = useSelector((s) => s.review.filterData.product);
  const reviewStartTime = useSelector((s) => s.review.filterData.timePeriod.startTime);
  const reviewEndTime = useSelector((s) => s.review.filterData.timePeriod.endTime);
  const reviewData = useSelector((s) => s.review.data);
  const reviewPage = useSelector((s) => s.review.tableMeta.page);

  // Audit Data
  const status = useSelector((s) => s.data.status.value);
  const workflowId = useSelector((s) => s.data.metaData.workflowId.value);
  const startTime = useSelector((s) => s.data.application.value.startTime);
  const endTime = useSelector((s) => s.data.application.value.endTime);
  const auditData = useSelector((s) => s.data.value);
  const auditPage = useSelector((s) => s.data.page);
  const sortBy = useSelector((state) => state.data.sortBy);

  const dispatch = useDispatch();

  const { search, state, pathname } = useLocation();

  let pageConfig = {};
  const requestId = new URLSearchParams(search).get('requestId');
  const transactionId = new URLSearchParams(search).get('transactionId');
  const currentElementFromSearchParams = parseInt(new URLSearchParams(search).get('currentElement'), 10);
  const searchIdFromSearchParams = new URLSearchParams(search).get('searchValue') || '';
  const recordId = requestId || transactionId;

  const currentElement = state?.currentElement || currentElementFromSearchParams || 0;
  const searchId = state?.searchValue || searchIdFromSearchParams || '';

  if (pathname === '/record') {
    pageConfig = {
      pathname,
      recordId,
      urlRoute: 'audit',
      recordPage: 'record',
      recordIdData: { transactionId: recordId },
      redirectPage: '/applications',
      recordType: 'transactionId',
      filters: {
        status,
        workflowId,
        searchValue: searchId,
        startTime,
        endTime,
        rows: constants.maxRecordsInTable,
      },
      sortBy,
      searchId,
      pagination: {
        currentElement,
        page: auditPage,
      },
      recordNavigationUpdateData: (data, page) => {
        dispatch(updateData(data));
        dispatch(updatePage(page));
      },
      tableData: auditData,
    };
  } else {
    const { transactionId: tnxID } = reviewData.filter((data) => data.requestId === recordId)[0];
    pageConfig = {
      pathname,
      recordId,
      transactionId: tnxID,
      urlRoute: 'review',
      recordPage: 'view',
      recordIdData: { requestId: recordId },
      redirectPage: '/review',
      recordType: 'requestId',
      filters: {
        product,
        statusCode,
        startTime: reviewStartTime,
        endTime: reviewEndTime,
        rows: constants.maxRecordsInTable,
      },
      searchId,
      pagination: {
        currentElement,
        page: reviewPage,
      },
      recordNavigationUpdateData: (data, page) => {
        dispatch(updateReviewData({
          data,
        }));
        dispatch(updateTableMeta({
          page,
        }));
      },
      tableData: reviewData,
    };
  }

  return (
    <Record pageConfig={pageConfig} />
  );
}

export default RecordWrapper;
