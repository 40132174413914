import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import SearchIcon from '../../../assests/icons/search.svg';
import CrossIcon from '../../../assests/icons/cross.svg';
import { updateFocussedInputs } from '../../../reducers/focussedInputs';
import './Inputs.scss';

function MultiInput({
  id,
  handleAdd,
  placeholder,
  onClick,
  inputArray,
  handleRemove,
}) {
  const [isSearchFocussed, setIsSearchFocussed] = useState(false);
  const [value, setValue] = useState('');
  const dispatch = useDispatch();
  const handleFocus = (isFocussed) => {
    if (onClick) onClick();
    setIsSearchFocussed(isFocussed);
    dispatch(updateFocussedInputs({ inputName: 'AppInput', isFocussed }));
  };

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && value !== '') {
      handleAdd(value);
      setValue('');
    }
  };

  useEffect(() => {
    dispatch(updateFocussedInputs({ inputName: 'AppInput', isSearchFocussed }));
    return () => {
      dispatch(updateFocussedInputs({ inputName: 'AppInput', isFocussed: false }));
    };
  }, []);

  return (
    <div id={id} className={`common__search_select ${isSearchFocussed ? 'focus' : ''}`}>
      <div className="common__search_select_options">
        {inputArray && inputArray.map((option, idx) => (
          <div key={option.id} className="common__search_select_options_option">
            <img src={CrossIcon} alt="" onClick={() => handleRemove(idx)} aria-hidden />
            <span>
              {option}
            </span>
          </div>
        ))}
      </div>
      <div className="common__search_select_search_box">
        <img src={SearchIcon} alt="s" />
        <input
          data-hj-suppress
          value={value}
          placeholder={placeholder}
          onFocus={() => handleFocus(true)}
          onBlur={() => handleFocus(false)}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
        />
      </div>
    </div>
  );
}

MultiInput.defaultProps = {
  id: '',
  placeholder: 'Type and Enter',
  onClick: null,
};

MultiInput.propTypes = {
  id: PropTypes.string,
  handleAdd: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  onClick: PropTypes.func,
  inputArray: PropTypes.func.isRequired,
  handleRemove: PropTypes.func.isRequired,
};

export default MultiInput;
