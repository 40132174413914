import React from 'react';
import PropTypes from 'prop-types';
import PlusIcon from '../../../assests/icons/plus.svg';

function AddFilterButton({
  handleClick, isActive, selectedFilter,
}) {
  return (
    <button
      type="button"
      id="add_filter_button"
      onClick={handleClick}
      className={`${isActive ? 'active' : ''}`}
    >
      <img src={PlusIcon} alt="+" />
      <span>{isActive ? (selectedFilter || 'Exit Filter') : 'Add Filter'}</span>
    </button>
  );
}

AddFilterButton.propTypes = {
  handleClick: PropTypes.func.isRequired,
  isActive: PropTypes.bool.isRequired,
  selectedFilter: PropTypes.string.isRequired,
};

export default AddFilterButton;
