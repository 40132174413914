/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import Image from './Image';
import Table from './Table/TableDataContainer';
import Title from './Title';

function TableImage({
  title, image, table, availableData, order,
}) {
  if (isEmpty(table) && isEmpty(image)) {
    return '';
  }
  return (
    <div className="card_table_image__container">
      <Title title={title} availableData={availableData} />
      <div className={`card_table_image__body ${order}`}>
        <Image {...image} availableData={availableData} />
        <Table {...table} availableData={availableData} />
      </div>
    </div>

  );
}

TableImage.defaultProps = {
  title: '',
  image: {},
  table: {},
  order: '',
};

TableImage.propTypes = {
  title: PropTypes.oneOf([PropTypes.string, PropTypes.object]),
  image: PropTypes.object,
  table: PropTypes.object,
  availableData: PropTypes.object.isRequired,
  order: PropTypes.string,
};

export default TableImage;
