import React from 'react';
import PropTypes from 'prop-types';
import tickIcon from '../../../assests/icons/tick-icon.svg';
import crossIcon from '../../../assests/icons/cross-icon.svg';

const PasswordValidationRow = ({ isValid, text }) => (
  <div className="row-display">
    <img src={isValid ? tickIcon : crossIcon} alt="" />
    <p data-hj-suppress className={`password-modal-font ${isValid ? 'valid-text' : 'invalid-text'}`}>{text}</p>
  </div>
);

PasswordValidationRow.propTypes = {
  isValid: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired,
};

export default PasswordValidationRow;
