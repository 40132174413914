import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import PropTypes from 'prop-types';
import showApplicationStatus from '../../../constants/showApplicationStatus';
import { formatDateForApplicationTable } from '../../../utils/dateFormats';
import './Audit.scss';

function ApplicationPreview({
  createdAt, status, count, extractedData,
}) {
  const [selfieImg, setSelfieImg] = useState('');
  const [idImg, setIdImg] = useState('');
  const [namePreview, setName] = useState('');
  const [dobPreview, setDob] = useState('');
  const [loading, setLoading] = useState(false);

  const cleanup = () => {
    setName('');
    setDob('');
    setSelfieImg('');
    setIdImg('');
  };

  useEffect(() => {
    setLoading(true);
    const {
      selfie, name, fileUrl, dob,
    } = extractedData;
    setSelfieImg(selfie);
    setIdImg(fileUrl);
    setName(name);
    setDob(dob);
    setLoading(false);

    return () => cleanup();
  }, []);

  return (
    <div id="application__container_top">
      {count <= 7
        ? (
          <div id="application__preview__container">
            {loading
              ? (
                <Grid container id="application__preview__loading_container">
                  <Grid item xs={4.5}>
                    <div id="application__preview__line" />
                    <div id="application__preview__line" />
                    <div id="application__preview__box" />
                  </Grid>
                  <Grid item xs={1} />
                  <Grid item xs={6.5}>
                    <div id="application__preview__line" />
                    <div id="application__preview__line" />
                    <div id="application__preview__box" />
                  </Grid>
                </Grid>
              )
              : (
                <Grid container id="application__preview__loaded_container">
                  <Grid>
                    { namePreview && dobPreview
                      ? (
                        <div id="application__preview__details">
                          <div id="application__preview__detail_right">
                            <p id="application__preview__title">Name: </p>
                            <p data-hj-suppress id="application__preview__value">{namePreview}</p>
                          </div>
                          <div id="application__preview__detail_left">
                            <p id="application__preview__title">Date of Birth: </p>
                            <p data-hj-suppress id="application__preview__value">{dobPreview}</p>
                          </div>
                        </div>
                      ) : null}
                    <div id="application__preview__details">
                      <div id="application__preview__detail_right">
                        <p id="application__preview__title">Status: </p>
                        <p id={`application__status__${showApplicationStatus(status).style}`}>
                          {showApplicationStatus(status).status}
                        </p>
                      </div>
                      <div id="application__preview__detail_left">
                        <p id="application__preview__title">Created at: </p>
                        <p id="application__preview__value">{formatDateForApplicationTable(createdAt)}</p>
                      </div>
                    </div>
                  </Grid>
                  <div data-hj-suppress id="application__preview__image_container">
                    {selfieImg
                      ? (
                        <div id="application__preview__selfie_container">
                          <img src={selfieImg} alt="selfie" id="application__preview__selfie__image" />
                        </div>
                      )
                      : null}
                    {idImg
                      ? (
                        <div id="application__preview__id_container">
                          <img src={idImg} alt="ID" id="application__preview__image" />
                        </div>
                      )
                      : null}
                  </div>
                </Grid>
              )}
          </div>
        )
        : (
          <div id="application__preview__container_down">
            {loading
              ? (
                <Grid container id="application__preview__loading_container">
                  <Grid item xs={4.5}>
                    <div id="application__preview__line" />
                    <div id="application__preview__line" />
                    <div id="application__preview__box" />
                  </Grid>
                  <Grid item xs={1} />
                  <Grid item xs={6.5}>
                    <div id="application__preview__line" />
                    <div id="application__preview__line" />
                    <div id="application__preview__box" />
                  </Grid>
                </Grid>
              )
              : (
                <Grid container id="application__preview__loaded_container">
                  <Grid>
                    { namePreview && dobPreview
                      ? (
                        <div id="application__preview__details">
                          <div id="application__preview__detail_right">
                            <p id="application__preview__title">Name: </p>
                            <p data-hj-suppress id="application__preview__value">{namePreview}</p>
                          </div>
                          <div id="application__preview__detail_left">
                            <p id="application__preview__title">Date of Birth: </p>
                            <p data-hj-suppress id="application__preview__value">{dobPreview}</p>
                          </div>
                        </div>
                      ) : null}
                    <div id="application__preview__details">
                      <div id="application__preview__detail_right">
                        <p id="application__preview__title">Status: </p>
                        <p id={`application__status__${showApplicationStatus(status).style}`}>
                          {showApplicationStatus(status).status}
                        </p>
                      </div>
                      <div id="application__preview__detail_left">
                        <p id="application__preview__title">Created at: </p>
                        <p id="application__preview__value">{formatDateForApplicationTable(createdAt)}</p>
                      </div>
                    </div>
                  </Grid>
                  <div data-hj-suppress id="application__preview__image_container">
                    {selfieImg
                      ? (
                        <div id="application__preview__selfie_container">
                          <img src={selfieImg} alt="selfie" id="application__preview__selfie__image" />
                        </div>
                      )
                      : null}
                    {idImg
                      ? (
                        <div id="application__preview__id_container">
                          <img src={idImg} alt="ID" id="application__preview__image" />
                        </div>
                      )
                      : null}
                  </div>
                </Grid>
              )}
          </div>
        )}
    </div>
  );
}

ApplicationPreview.propTypes = {
  count: PropTypes.number.isRequired,
  createdAt: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  extractedData: PropTypes.object.isRequired,
};

export default ApplicationPreview;
