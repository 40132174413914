import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';
import Button from '@mui/material/Button';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { updateShowAmlDetails } from '../../../../reducers/data';
import AMLSourceDetailsPanel from './AMLSourceDetailsPanel';
import { ReactComponent as CloseIcon } from '../../../../assests/icons/closeaml.svg';
import amlSource from '../../../../constants/amlSources';

function AMLCheckSidePanel() {
  const containerRef = useRef(null);
  const dispatch = useDispatch();
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const showAmlDetailsData = useSelector((s) => s.data.showAmlDetailsData);
  const showAmlDetails = useSelector((reduxState) => reduxState.data.showAmlDetails);

  const handleMouseClick = (event) => {
    if (containerRef.current && event.target !== containerRef.current
      && !containerRef.current.contains(event.target)) {
      dispatch(updateShowAmlDetails(false));
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleMouseClick);
    return () => {
      document.removeEventListener('mousedown', handleMouseClick);
    };
  }, []);

  useEffect(() => {
    setValue(0);
  }, [showAmlDetailsData]);

  if (isEmpty(showAmlDetailsData) || !showAmlDetails) {
    return '';
  }

  return (
    <div id="details__tab__details__aml_panel" ref={containerRef}>
      <div id="details__tab__details__aml_panel_inner_div">
        <Button id="details__tab__details__aml_close" onClick={() => dispatch(updateShowAmlDetails(false))}>
          <CloseIcon />
        </Button>
        <div data-hj-suppress id="details__tab__details__aml_transactionId">{showAmlDetailsData.name}</div>
        <div data-hj-suppress id="details__tab__details__aml_date">
          {showAmlDetailsData.sources && showAmlDetailsData.sources.length > 0
        && showAmlDetailsData.sources[0].hits.length > 0
        && showAmlDetailsData.sources[0].hits[0].dob
        && showAmlDetailsData.sources[0].hits[0].dob.length > 0 ? showAmlDetailsData.sources[0].hits[0].dob[0] : ''}
        </div>
        <br />
        <div id="details__tab__details__body__amlDetail_container">
          <table id="details__tab__details__body__amlDetail_container__table">
            <tr id="details__tab__details__body__amlDetail_container__tr">
              <td id="details__tab__details__body__amlDetail_container__tableHeading">Key Information</td>
              <td aria-hidden id="details__tab__details__body__amlDetail_container__tableHeading" />
              <td aria-hidden id="details__tab__details__body__amlDetail_container__tableHeading" />
            </tr>
            <tr id="details__tab__details__body__amlDetail_container__tr">
              <td id="details__tab__details__body__amlDetail_container__t">Full Name</td>
              <td data-hj-suppress id="details__tab__details__body__amlDetail_container__t">{showAmlDetailsData.name}</td>
            </tr>
            { showAmlDetailsData.sources && showAmlDetailsData.sources.length > 0
        && showAmlDetailsData.sources[0].hits.length > 0
        && showAmlDetailsData.sources[0].hits[0].dob
        && showAmlDetailsData.sources[0].hits[0].dob.length > 0
              ? (
                <tr id="details__tab__details__body__amlDetail_container__tr">
                  <td id="details__tab__details__body__amlDetail_container__t">Date Of Birth</td>
                  <td data-hj-suppress id="details__tab__details__body__amlDetail_container__t">
                    {showAmlDetailsData.sources[0].hits[0].dob[0]}

                  </td>
                </tr>
              ) : ''}
            {showAmlDetailsData.countries && showAmlDetailsData.countries !== '' ? (
              <tr id="details__tab__details__body__amlDetail_container__tr">
                <td id="details__tab__details__body__amlDetail_container__t">Countries</td>
                <td data-hj-suppress id="details__tab__details__body__amlDetail_container__t">
                  {' '}
                  {showAmlDetailsData.countries}
                </td>
              </tr>
            ) : ''}

          </table>
        </div>
        {showAmlDetailsData.sources && showAmlDetailsData.sources.length > 0 ? (
          <div id="details__tab__details__body__tabs">
            <Tabs
              value={value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons={false}
              aria-label="basic tabs example"
            >
              {showAmlDetailsData.sources.map((item, index) => (
                <Tab
                  key={item.id}
                  label={`${amlSource(item.type)}`}
                  id={`simple-tab-${index}`}
                  aria-controls={`simple-tabpanel-${index}`}
                  className={`${value === index && item.hits && item.hits.length > 0 ? 'tabActive' : ''}`}
                />
              ))}
            </Tabs>

          </div>
        ) : ''}
        { showAmlDetailsData.sources && showAmlDetailsData.sources.length > 0
          ? (showAmlDetailsData.sources.map((item, index) => (
            <AMLSourceDetailsPanel
              key={item.id}
              value={value}
              index={index}
              item={item.hits}
              createdAt={item.createdAt}
              status={item.apiStatus}
            />
          ))) : ''}
      </div>
    </div>
  );
}

export default AMLCheckSidePanel;
