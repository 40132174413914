import * as Sentry from '@sentry/react';
import axios from 'axios';

const axiosRequest = axios.create({
  baseURL: `${process.env.REACT_APP_SERVER_URL}/api/v1/analytics`,
  withCredentials: true,
});

const getBillingBreakup = async ({
  appIdList, monthStartDate, monthEndDate, workflowId,
}) => {
  try {
    const res = await axiosRequest.post(
      '/billing/breakup',
      {
        appIdList,
        workflowId,
        monthEndDate,
        monthStartDate,
      },
    );
    return res?.data?.result;
  } catch (err) {
    Sentry.captureException(err);
    return { apiError: true };
  }
};

export default getBillingBreakup;
