import {
  get, isString, keys, last, split, startsWith,
} from 'lodash';
import { getFileExtentionFromUrl, getProtocolFromURL, isValidURL } from '../../../../utils/helpers';

const base64MimeTypeSignatureFileExtensionMapping = {
  JVBERi0: 'application/pdf',
  R0lGODdh: 'image/gif',
  R0lGODlh: 'image/gif',
  iVBORw0KGgo: 'image/png',
  '/9j/': 'image/jpg',
};

function detectMimeType(base64String) {
  if (!base64String || !isString(base64String)) {
    return null;
  }

  const matchedSignature = keys(base64MimeTypeSignatureFileExtensionMapping).find(
    (base64StringSignature) => startsWith(base64String, base64StringSignature),
  );
  return get(base64MimeTypeSignatureFileExtensionMapping, matchedSignature);
}

const getFileExtensionFromMimeType = (mimeType) => {
  if (startsWith(mimeType, 'image/')) {
    return last(split(mimeType, '/'));
  }

  if (mimeType === 'application/pdf') {
    return 'pdf';
  }

  return null;
};

export const getProcessedSrcAndFileExtension = (src) => {
  if (isValidURL(src)) {
    const urlProtocol = getProtocolFromURL(src);

    // Handle data URLs
    if (urlProtocol === 'data:') {
      const base64MimeTypeExtractRegExp = new RegExp(/data:(.*);base64/gi);
      const mimeType = get(base64MimeTypeExtractRegExp.exec(src), 1);
      return { src, fileExtension: getFileExtensionFromMimeType(mimeType) };
    }

    return { src, fileExtension: getFileExtentionFromUrl(src) };
  }

  // If not valid URL Assume base64 string and try to find correct mimetype/fileExtension
  const mimeType = detectMimeType(src);
  const updatedSrc = `data:${mimeType};base64,${src}`;
  return { src: updatedSrc, fileExtension: getFileExtensionFromMimeType(mimeType) };
};

const seperatePDFsAndImages = (mediaList) => {
  const images = [];
  const pdfs = [];
  mediaList.forEach(({ src, label }) => {
    const { src: updatedSrc, fileExtension } = getProcessedSrcAndFileExtension(src);
    if (fileExtension === 'pdf') {
      pdfs.push({ src: updatedSrc, label });
    } else {
      images.push({ src: updatedSrc, label });
    }
  });
  return {
    images,
    pdfs,
  };
};

export default seperatePDFsAndImages;
