import { get } from 'lodash';

// const amlSource = (val) => {
//   let amlSources = val;
//   switch (val) {
//     case 'sanctions':
//       amlSources = 'Sanctions';
//       break;
//     case 'pep':
//       amlSources = 'PEP';
//       break;
//     case 'adverseMedia':
//       amlSources = 'Adverse Media';
//       break;
//     case 'name':
//       amlSources = 'Name';
//       break;
//     case 'dob':
//       amlSources = 'Date Of Birth';
//       break;
//     case 'countries':
//       amlSources = 'Countries';
//       break;
//     case 'url':
//       amlSources = 'Url';
//       break;
//     case 'relatedUrl':
//       amlSources = 'Related Urls';
//       break;
//     default:
//       amlSources = val;
//       break;
//   }
//   return amlSources;
// };

const amlSourceLabelMap = {
  sanction: 'Sanctions',
  pop: 'PEP',
  'adverse-media': 'Adverse Media',
  warning: 'Warning',
  'fitness-probity': 'Fitness Probity',
};

const amlSource = (source) => get(amlSourceLabelMap, source, source);

export default amlSource;
