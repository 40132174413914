/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initTransaction = {
  data: [],
  totalCost: 0,
  appIdWiseUsageData: [],
};

const initModuleUsage = {
  data: [],
  totalCost: 0,
  appIdWiseUsageData: [],
};

export const pricingSlice = createSlice({
  name: 'pricing',
  initialState: {
    moduleUsage: {
      data: initModuleUsage.data,
      totalCost: initModuleUsage.totalCost,
      appIdWiseUsageData: initModuleUsage.appIdWiseUsageData,
    },
    transactionUsage: {
      data: initTransaction.data,
      totalCost: initTransaction.totalCost,
      appIdWiseUsageData: initTransaction.appIdWiseUsageData,
    },
  },
  reducers: {
    updateModuleUsage: (state, action) => {
      const { data, totalCost, appIdWiseUsageData } = action.payload;
      return {
        ...state,
        moduleUsage: {
          ...state.moduleUsage,
          data: data || initModuleUsage.data,
          totalCost: totalCost || initModuleUsage.totalCost,
          appIdWiseUsageData: appIdWiseUsageData || initModuleUsage.appIdWiseUsageData,
        },
      };
    },
    updateTransactionUsage: (state, action) => {
      const { data, totalCost, appIdWiseUsageData } = action.payload;
      return {
        ...state,
        transactionUsage: {
          ...state.transactionUsage,
          data: data || initTransaction.data,
          totalCost: totalCost || initTransaction.totalCost,
          appIdWiseUsageData: appIdWiseUsageData || initTransaction.appIdWiseUsageData,
        },
      };
    },
  },
});

export const {
  updateModuleUsage, updateTransactionUsage,
} = pricingSlice.actions;
export default pricingSlice.reducer;
