/* eslint-disable no-case-declarations */
import React from 'react';
import PropTypes from 'prop-types';

function Section({
  children, id, elementKey, componentStyles, gridRow, gridCol,
}) {
  if (!children) return null;

  const customStyles = {
    ...componentStyles,
    ...(gridCol && gridRow ? { gridArea: `${gridRow} / ${gridCol}` } : {}),
  };

  return (
    <div data-hj-suppress style={customStyles} key={elementKey} id={id} className="generic_common_card__section">
      {children}
    </div>
  );
}

Section.defaultProps = {
  componentStyles: {},
  gridRow: undefined,
  gridCol: undefined,
};

Section.propTypes = {
  children: PropTypes.array.isRequired,
  id: PropTypes.string.isRequired,
  elementKey: PropTypes.string.isRequired,
  componentStyles: PropTypes.object,
  gridRow: PropTypes.string,
  gridCol: PropTypes.string,
};

export default Section;
