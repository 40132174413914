import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { useDispatch } from 'react-redux';
import CreatedAtAndStatusAml from '../../CreatedAtAndStatusAml';
import { updateShowAmlDetails, updateShowAmlDetailsData } from '../../../../reducers/data';
import { constants } from '../../../../config';
import amlSource from '../../../../constants/amlSources';
import amlRelavance from '../../../../constants/amlRelavance';
import { MissingRecordComponent } from '../Cards/Common';

function AMLCheckPanel({
  value, index, item, createdAt,
}) {
  const isMissing = item.isMissing || false;
  const rowsPerPage = constants.maxRecordInAMLTable;
  const [hitsDetails, setHitsDetails] = useState([]);
  const [paginatedData, setPaginatedData] = useState([]);
  const [paginatedIndex, setPaginatedIndex] = useState(0);
  useEffect(() => {
    item.extractedData.forEach((element) => {
      element.forEach((hits) => {
        setHitsDetails(hits.hitsInfo);
      });
    });
  }, [item]);

  useEffect(() => {
    if (hitsDetails) {
      setPaginatedData(hitsDetails.slice(paginatedIndex, paginatedIndex + rowsPerPage));
    }
  }, [hitsDetails, paginatedIndex]);

  const dispatch = useDispatch();
  const handlePageChange = (event, newPage) => {
    setPaginatedIndex((newPage - 1) * rowsPerPage);
  };
  const handleTabOpen = (ele) => {
    dispatch(updateShowAmlDetails(true));
    dispatch(updateShowAmlDetailsData(ele));
  };

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      <div id="details__tab__container">
        {!isMissing ? (
          <>
            <CreatedAtAndStatusAml createdAt={createdAt} />
            <div id="details__tab__details__container">
              <div id="details__tab__details__body">

                {/* To be changed later to item.inputTitle */}
                <h1>Search Information</h1>
                <br />
                {item.extractedReqData.map((ele) => (
                  <div id="table__body" key={ele.key}>
                    <div id="details__tab__details__body__table_container" key={ele.key}>
                      <table>
                        {ele.map((elem) => (
                          <tr key={elem.value}>
                            {elem.key === 'Error' ? <td className="err">{elem.value}</td> : ''}
                            <td className="left aml">{(elem.key)}</td>
                            <td data-hj-suppress className="right aml">{elem.key !== 'Error' && elem.value ? elem.value.toString() : ''}</td>
                          </tr>
                        ))}
                      </table>
                    </div>
                    <br />
                  </div>
                ))}
              </div>
            </div>
            <br />
            <br />
            {item.isSuccess ? (
              <div id="details__tab__details__container">

                <div id="details__tab__details__body">
                  <div>
                    {' '}
                    <h1>Match Information</h1>
                    <span id="details__tab__details__hitsfound">
                      {item.extractedData[0][0].hitsInfo.length}
                      {' '}
                      {' '}
                      hits found
                    </span>
                    <div id="details__tab__details__verticalLine" />
                  </div>
                  <div id="details__tab__details__body__amltable_container">
                    <table>
                      <tr>
                        <td id="details__tab__details__body__amltable_container__tableHeading">Name</td>
                        <td id="details__tab__details__body__amltable_container__tableHeading">Types of Hits</td>
                        <td id="details__tab__details__body__amltable_container__tableHeading">Countries</td>
                        <td id="details__tab__details__body__amltable_container__tableHeading">Relevance</td>
                      </tr>
                      {paginatedData.map((ele) => (
                        <tr data-hj-suppress id="details__tab__details__body__tr" key={ele.key} onClick={() => { handleTabOpen(ele); }}>
                          <td id="details__tab__details__body__amltable_container__t">
                            {ele.name ? ele.name : ele.name}
                            {' '}
                            <br />
                            {(ele.sources && ele.sources.length > 0) ? (
                              <span id="details__tab__details__body__amltable_container__dob">
                                {ele.sources[0].hits && ele.sources[0].hits.length > 0
                      && ele.sources[0].hits[0].dob && ele.sources[0].hits[0].dob.length > 0 ? ele.sources[0].hits[0].dob[0] : ''}
                              </span>
                            )
                              : ''}
                          </td>
                          <td id="details__tab__details__body__amltable_container__t">
                            {ele.typeOfHits && ele.typeOfHits.length > 0
                              ? (ele.typeOfHits.map((hit, idx) => (
                                <>
                                  {amlSource(hit)}
                                  {idx < ele.typeOfHits.length - 1 ? ', ' : ''}
                                </>
                              )))
                              : ''}

                          </td>

                          <td id="details__tab__details__body__amltable_container__t">
                            {ele.countries}
                          </td>
                          <td id="details__tab__details__body__amltable_container__t">
                            {ele.relevance && ele.relevance.length > 0
                              ? (ele.relevance.map((rel, idx) => (
                                <>
                                  {idx !== 0 ? ' | ' : ''}
                                  {amlRelavance(rel)}
                                </>
                              )))
                              : ''}
                          </td>
                        </tr>
                      ))}
                    </table>
                  </div>

                  <br />
                  <Stack direction="row" justifyContent="left" id="pagination-container">
                    <Pagination
                      count={Math.ceil(item.extractedData[0][0].hitsInfo.length / rowsPerPage)}
                      page={paginatedIndex / rowsPerPage + 1}
                      onChange={handlePageChange}
                    />
                  </Stack>
                </div>
              </div>
            ) : '' }
          </>
        ) : (
          <MissingRecordComponent />
        )}
      </div>
    </div>
  );
}

AMLCheckPanel.defaultProps = {
  createdAt: '',
};

AMLCheckPanel.propTypes = {
  item: PropTypes.object.isRequired,
  value: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  createdAt: PropTypes.string,
};

export default AMLCheckPanel;
