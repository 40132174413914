import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { useSelector } from 'react-redux';
import DecisionButtonGroup from './DecisionButtonGroup';

function Review({
  availableData, moduleId,
}) {
  const hitsReviewTags = useSelector((state) => state.appDetails.hitsReviewTags);
  const currentRequestId = get(availableData, 'recordData.requestId');
  const currentHitId = get(availableData, 'hitData.hitId');
  const currentModuleHitsReviewTag = get(hitsReviewTags, [moduleId, currentRequestId]);

  if (!currentModuleHitsReviewTag) return null;

  const decisionButtonGroups = currentModuleHitsReviewTag.tagGroups;

  return (
    <div className="card_type_five__footer__review__container">
      {decisionButtonGroups.map(
        ({
          key, title, selectedValue, items, conditionData,
        }) => (
          <DecisionButtonGroup
            key={key}
            tagGroupKey={key}
            hitId={currentHitId}
            title={title}
            items={items}
            selectedValue={selectedValue[currentHitId]}
            moduleId={moduleId}
            requestId={currentRequestId}
            conditionData={conditionData}
          />
        ),
      )}
    </div>
  );
}

Review.propTypes = {
  availableData: PropTypes.object.isRequired,
  moduleId: PropTypes.string.isRequired,
};

export default Review;
