import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './Summary.scss';
import SummaryHeader from './SummaryHeader';
import SummaryBody from './SummaryBody';
import {
  getSummaryHeaderData,
  getSummaryMetaData,
  getSummaryUserDetailsData,
  collectVideos,
  collectMedia,
} from './utils';
import { summaryModuleId } from '../RecordUtils';

function SummaryTab({
  transactionData,
  recordData,
}) {
  const [summaryImages, setSummaryImages] = useState([]);
  const [summaryPDFs, setSummaryPDFs] = useState([]);
  const [summaryVideos, setSummaryVideos] = useState([]);
  const [summaryHeaderData, setSummaryHeaderData] = useState([]);
  const [summaryMetaData, setSummaryMetaData] = useState([]);
  const [summaryUserDetails, setSummaryUserDetails] = useState([]);

  useEffect(() => {
    const { imagesCollected, pdfsCollected } = collectMedia(recordData);
    setSummaryImages(imagesCollected);
    setSummaryPDFs(pdfsCollected);
    setSummaryVideos(collectVideos(recordData));
  }, [recordData]);

  useEffect(() => {
    setSummaryHeaderData(getSummaryHeaderData(transactionData));
    setSummaryMetaData(getSummaryMetaData(transactionData));
    setSummaryUserDetails(getSummaryUserDetailsData(transactionData));
  }, [transactionData]);

  return (
    <div id={summaryModuleId}>
      <SummaryHeader data={summaryHeaderData} />
      <SummaryBody
        images={summaryImages}
        videos={summaryVideos}
        pdfs={summaryPDFs}
        metaData={summaryMetaData}
        userDetails={summaryUserDetails}
      />
    </div>
  );
}

SummaryTab.propTypes = {
  transactionData: PropTypes.object.isRequired,
  recordData: PropTypes.array.isRequired,
};

export default SummaryTab;
