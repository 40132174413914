/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import { DateRangePicker, createStaticRanges, defaultStaticRanges } from 'react-date-range';
import {
  startOfDay, endOfDay, addDays, startOfMonth,
} from 'date-fns';

function DatePicker({
  startTime, endTime, handlePick, handleClose, hideCustomRanges,
}) {
  const handleSelect = (ranges) => {
    let { selection: { startDate, endDate } } = ranges;
    startDate = startOfDay(new Date(startDate)).toISOString();
    endDate = endOfDay(new Date(endDate)).toISOString();
    handlePick(startDate, endDate);
  };

  const customRanges = createStaticRanges([
    defaultStaticRanges.find((item) => item.label === 'Today'),
    defaultStaticRanges.find((item) => item.label === 'Yesterday'),
    {
      label: 'Last 7 Days',
      range: () => ({
        startDate: startOfDay(addDays(new Date(), -7)),
        endDate: endOfDay(new Date()),
      }),
    },
    {
      label: 'Last 14 Days',
      range: () => ({
        startDate: startOfDay(addDays(new Date(), -14)),
        endDate: endOfDay(new Date()),
      }),
    },
    {
      label: 'Last 30 Days',
      range: () => ({
        startDate: startOfDay(addDays(new Date(), -30)),
        endDate: endOfDay(new Date()),
      }),
    },
    {
      label: 'This Month',
      range: () => ({
        startDate: startOfMonth(new Date()),
        endDate: endOfDay(new Date()),
      }),
    },
    defaultStaticRanges.find((item) => item.label === 'Last Month'),
  ]);

  const selectionRange = {
    startDate: new Date(startTime),
    endDate: new Date(endTime),
    key: 'selection',
  };

  return (
    <DateRangePicker
      ranges={[selectionRange]}
      onChange={(ranges) => handleSelect(ranges)}
      maxDate={new Date()}
      staticRanges={!hideCustomRanges ? customRanges : []}
      inputRanges={[]}
      showSelectionPreview
    />
  );
}

DatePicker.defaultProps = {
  hideCustomRanges: false,
};

DatePicker.propTypes = {
  startTime: PropTypes.string.isRequired,
  endTime: PropTypes.string.isRequired,
  handlePick: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  hideCustomRanges: PropTypes.bool,
};

export default DatePicker;
