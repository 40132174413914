import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import FlagsV1 from './FlagsV1';
import FlagsV2 from './FlagsV2';

function Flags({
  flags,
  v2Flags,
  selectedModule,
  handleModuleChange,
}) {
  const findIsActive = (moduleId) => (selectedModule === moduleId ? 'active' : '');

  const onModuleClick = (moduleId) => {
    handleModuleChange(moduleId);
  };

  const getFlagsComponents = () => {
    let flagsVersion = 'default';
    if (v2Flags?.present) {
      if (!isEmpty(v2Flags.flags)) flagsVersion = 'v2';
    } else if (!isEmpty(flags)) flagsVersion = 'v1';

    switch (flagsVersion) {
      case 'v1': return <FlagsV1 flags={flags} findIsActive={findIsActive} onModuleClick={onModuleClick} />;
      case 'v2': return <FlagsV2 v2Flags={v2Flags?.flags} findIsActive={findIsActive} onModuleClick={onModuleClick} />;
      default: return (
        <div id="right_panel__review_card__flags__empty">
          Nothing suspicious was detected. Hence, no flags were found.
        </div>
      );
    }
  };

  return (
    <div id="right_panel__review_card__flags">
      <h4>Flags From Workflow</h4>
      { getFlagsComponents() }
    </div>
  );
}

Flags.propTypes = {
  flags: PropTypes.object.isRequired,
  v2Flags: PropTypes.object.isRequired,
  selectedModule: PropTypes.string.isRequired,
  handleModuleChange: PropTypes.func.isRequired,
};

export default Flags;
