import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { get, set, startCase } from 'lodash';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import * as Sentry from '@sentry/react';

import { Spinner } from 'clm-components';
import HvLogo from '../assests/images/logoPlain.png';
import ToggleDropdown from '../components/BUSelection/ToggleDropdown';
import productRedirectFunctions from '../utils/productRedirectFunctions';
import { updateIsAuthenticated, updateUserInfo } from '../reducers/user';
import useShowErrorAlert, { useLogoutAction } from '../utils/lib';
import errorCode from '../constants/errorCode';
import AlertCode from '../constants/alertCodes';
import { isNullOrUndefined } from '../utils/helpers';

function BUSelection() {
  const dispatch = useDispatch();
  const userEmail = useSelector((state) => state.user.email);
  const userAppIds = useSelector((state) => state.user.appIds);
  const [selectedBUIndex, setSelectedUIndex] = useState(null);
  const [selectedEnvironment, setSelectedEnvironment] = useState(null);
  const logout = useLogoutAction();
  const showErrorAlert = useShowErrorAlert();
  const [areAppIdsLoading, setAreAppIdsLoading] = useState(true);

  const userBUs = useMemo(() => {
    const BUEnvMapping = {};
    const appIdList = Object.keys(userAppIds);

    if (appIdList.length === 1) {
      const autoSelectedAppId = appIdList[0];
      const { type } = userAppIds[autoSelectedAppId];
      const appIdEnvironment = type === 'PRODUCTION' ? 'Production' : 'Staging';
      productRedirectFunctions.VKYCPortal(null, appIdEnvironment, autoSelectedAppId);
    }

    appIdList.forEach((appId) => {
      const { type, useCase } = userAppIds[appId];

      const appIdEnvironment = type === 'PRODUCTION' ? 'Production' : 'Staging';

      if (!get(BUEnvMapping, `${useCase}.${appIdEnvironment}`)) {
        set(BUEnvMapping, `${useCase}.${appIdEnvironment}`, []);
      }

      BUEnvMapping[useCase][appIdEnvironment].push(appId);
    });

    return Object.keys(BUEnvMapping).map((businessUnit) => ({
      businessUnit: startCase(businessUnit),
      environments: BUEnvMapping[businessUnit],
    }));
  }, [userAppIds]);

  const handleBusinessUnitTitleClick = (event, newIndex) => {
    event.preventDefault();
    event.stopPropagation();

    setSelectedUIndex((previousIndex) => {
      if (previousIndex === newIndex) return null;
      return newIndex;
    });
  };

  const handleBusinessUnitEnvironmentClick = useCallback((event, environment) => {
    event.preventDefault();
    event.stopPropagation();

    const selectedBUAndEnvAppIds = userBUs[selectedBUIndex].environments[environment];

    if (selectedBUAndEnvAppIds.length === 1) {
      productRedirectFunctions.VKYCPortal(null, environment, selectedBUAndEnvAppIds[0]);
    } else {
      setSelectedEnvironment(environment);
    }
  }, [userBUs, selectedBUIndex]);

  const handleAppIdClick = useCallback((event, appId) => {
    event.preventDefault();
    event.stopPropagation();

    productRedirectFunctions.VKYCPortal(null, selectedEnvironment, appId);
  }, [selectedEnvironment]);

  const handleGoBackClick = () => {
    setSelectedEnvironment(null);
  };

  const fetchUser = async () => {
    try {
      const userRes = await axios({
        method: 'GET',
        url: `${process.env.REACT_APP_SERVER_URL}/api/v1/user`,
      });
      dispatch(updateUserInfo(userRes.data.result));
      setAreAppIdsLoading(false);
    } catch (error) {
      Sentry.captureException(`${errorCode.AUTH_ERROR_FETCHING_USER} - ${error}`, {
        extra: {
          errorMessage: AlertCode.ERROR_FETCHING_USER,
        },
      });
      await logout();
      showErrorAlert({ error, message: AlertCode.ERROR_FETCHING_USER, onLoginError: true });
      dispatch(updateIsAuthenticated(false));
    }
  };

  useEffect(() => {
    if (Object.keys(userAppIds).length === 0 && userEmail.length === 0) {
      fetchUser();
    } else {
      setAreAppIdsLoading(false);
    }
  }, [userAppIds, userEmail]);

  return (
    <div id="BU-selection-page">
      <img src={HvLogo} alt="logo" className="hv-logo" />
      <div id="BU-selection-page__container">
        <div id="BU-selection-page__BU-list-container">
          <div id="BU-selection-page__BU-list-header">
            {selectedBUIndex && selectedEnvironment ? 'Select AppID' : 'Select Business Unit'}
          </div>
          {areAppIdsLoading && (
            <div id="BU-selection-page__spinner">
              <Spinner size="big" />
            </div>
          )}
          {!areAppIdsLoading && (
            <div id="BU-selection-page__BU-list">
              {
              !isNullOrUndefined(selectedBUIndex) && selectedEnvironment ? (
                <ToggleDropdown
                  styleOverrides={{ width: '316px' }}
                  title={userBUs[selectedBUIndex].businessUnit}
                  subtitle={selectedEnvironment}
                  options={userBUs[selectedBUIndex].environments[selectedEnvironment]}
                  onItemClick={handleAppIdClick}
                  isCollapsed={false}
                  showArrow={false}
                />
              ) : (
                userBUs.map(({ businessUnit, environments }, index) => (
                  <ToggleDropdown
                    styleOverrides={{ width: '316px' }}
                    title={businessUnit}
                    options={Object.keys(environments)}
                    key={businessUnit}
                    onTitleClick={(event) => handleBusinessUnitTitleClick(event, index)}
                    onItemClick={handleBusinessUnitEnvironmentClick}
                    isCollapsed={selectedBUIndex !== index}
                  />
                ))
              )
            }
            </div>
          )}

          {!isNullOrUndefined(selectedBUIndex) && selectedEnvironment && (
            <div aria-hidden id="BU-selection-page__go-back-container" onClick={handleGoBackClick}>
              Go Back
            </div>
          )}
        </div>
        <div id="BU-selection-page__email-container">
          Logging in as
          {' '}
          <span id="BU-selection-page__email-container-highlighted-text">
            {userEmail}
          </span>
        </div>
      </div>
    </div>
  );
}

export default BUSelection;
