/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';
import { HeaderInfoCell } from 'clm-components';
import AppKeyCell from './AppKeyCell';
import AppIdCell from './AppIdCell';

export const tableHeaders = {
  type: {
    componentRenderer: () => (
      <HeaderInfoCell text="Environment" />),
    width: '30%',
  },
  appId: {
    componentRenderer: () => (
      <HeaderInfoCell text="App ID" infoText="AppId" />),
    width: '25%',
  },
  appKey: {
    componentRenderer: () => (
      <HeaderInfoCell text="App Key" infoText="AppKey" />),
    width: '45%',
  },
};

export const tableDataGen = (data, updateAlert) => {
  const prodExists = data.some(({ type }) => type === 'PRODUCTION');
  const tabData = data.map(({ appId, appKey, type }) => ({
    type: {
      componentRenderer: () => (
        <>{type === 'PRODUCTION' ? 'Production' : 'Staging'}</>
      ),
    },
    appId: {
      componentRenderer: (props) => (
        <AppIdCell
          column="appId"
          value={appId}
          updateAlert={updateAlert}
          {...props}
        />
      ),
    },
    appKey: {
      componentRenderer: (props) => (
        <AppKeyCell
          column="appKey"
          value={appKey}
          updateAlert={updateAlert}
          {...props}
        />
      ),
    },
  }));
  if (!prodExists) {
    tabData.push({
      type: {
        componentRenderer: () => (
          <>Production</>
        ),
      },
      appId: {
        componentRenderer: (props) => (
          <AppIdCell
            column="appId"
            updateAlert={updateAlert}
            {...props}
          />
        ),
      },
      appKey: {
        componentRenderer: (props) => (
          <AppKeyCell
            column="appKey"
            updateAlert={updateAlert}
            {...props}
          />
        ),
      },
    });
  }
  return tabData;
};
