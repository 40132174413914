import './DefaultOnboard.scss';
import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { isEqual, set } from 'lodash';
import logo from '../../assests/images/logoPlain.png';
import AlertCode from '../../constants/alertCodes';
import alert from '../../assests/icons/alert-v2.svg';
import authenticateUserAndHandleRedirect, { redirectFunction } from './authHelper';
import SubmitButton from '../Common/Buttons/SubmitButton';
import PasswordInput from '../Common/Inputs/PasswordInput';
import ShakeContainer from './ShakeContainer';
import errorCode from '../../constants/errorCode';
import { useFormatPerformanceAnalyticsData } from '../../utils/lib';
import { PERFORMANCE_METRIC_EVENTS } from '../../config';
import { isNumber } from '../../utils/helpers';

export default function CreatePassword() {
  const dispatch = useDispatch();
  const queryParams = new URLSearchParams(window.location.search);

  const emailInLocalStorage = localStorage.getItem('password_reset_email');
  const [password, setPassword] = useState('');
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [confirmedPassword, setConfirmedPassword] = useState('');
  const [openInputAlert, setOpenInputAlert] = useState(false);
  const [errorMessage, setErrorMessage] = useState('Something went wrong');
  const [isLoading, setIsLoading] = useState(false);
  const formatPerformanceAnalyticsData = useFormatPerformanceAnalyticsData();

  const initCondition = password !== '' && confirmedPassword !== '';
  const auth = useSelector((state) => state.user.auth);
  const isValid = initCondition;

  const navigate = useNavigate();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const token = query.get('token');

  const handleSubmit = async (event) => {
    const isPasswordSame = isEqual(password, confirmedPassword);
    if (!isPasswordSame) {
      setOpenInputAlert(!isPasswordSame);
      setErrorMessage('Entered passwords don’t match');
      return;
    }
    if (!isPasswordValid) {
      setOpenInputAlert(!isPasswordValid);
      setErrorMessage('Password criteria does not match');
      return;
    }
    const startTime = performance.now();
    const eventName = PERFORMANCE_METRIC_EVENTS.CREATE_PASSWORD;
    const eventObj = {
      APIEndPoint: 'api/user/passwordReset',
    };
    setIsLoading(true);
    setOpenInputAlert(false);
    event.preventDefault();
    try {
      const response = await axios({
        method: 'POST',
        url: `${process.env.REACT_APP_SELF_STAGING_URL}/api/user/passwordResetConfirm`,
        data: {
          token,
          password,
        },
      });
      if (isNumber(response?.status)) set(eventObj, 'statusCode', response?.status);
      const message = response?.data?.message || '';
      let email = emailInLocalStorage;
      if (!emailInLocalStorage) email = message.split('-')[1].trim();
      const processingTime = performance.now() - startTime;
      set(eventObj, 'processingTime', processingTime);
      formatPerformanceAnalyticsData(eventObj, eventName);
      // instead of redirecting to /password/success, directly redirect to home page
      await authenticateUserAndHandleRedirect(
        dispatch, navigate, setErrorMessage,
        setOpenInputAlert, email, password, queryParams, formatPerformanceAnalyticsData,
      );
      setIsLoading(false);
    } catch (error) {
      if (isNumber(error?.response?.statusCode)) set(eventObj, 'statusCode', error?.response?.statusCode);
      const processingTime = performance.now() - startTime;
      set(eventObj, 'processingTime', processingTime);
      formatPerformanceAnalyticsData(eventObj, eventName);
      setIsLoading(false);
      setOpenInputAlert(true);
      let errorMsg = AlertCode.PASSWORD_CREATION_FAILED;
      if (error.response && error.response.data) {
        errorMsg = error.response.data.message;
        setErrorMessage(errorMsg);
      } else {
        setErrorMessage(AlertCode.PASSWORD_CREATION_FAILED);
      }
      Sentry.captureException(`${errorCode.ONBOARD_CREATE_PASSWORD_ERROR} - ${error}`, {
        extra: {
          errorMessage,
        },
      });
    }
  };

  const handleEnterPress = (event) => {
    if (event.key === 'Enter') {
      handleSubmit(event);
    }
  };

  const handlePasswordChange = (event) => setPassword(event.target.value);

  const handleConfirmPasswordChange = (event) => setConfirmedPassword(event.target.value);

  useEffect(() => {
    if (auth && !isLoading) redirectFunction(dispatch, navigate, auth, queryParams);
  }, [auth]);

  return (
    <div className="onboard-page">
      <img src={logo} alt="logo" className="hv-logo" />
      <ShakeContainer isError={openInputAlert}>
        <h2 className="forget-pass-font">Choose a new Password</h2>
        <p className="recovery-mail-text">
          You are setting a new password :
        </p>
        <p data-hj-suppress className="email-text">{emailInLocalStorage}</p>
        <div className="column-display margin-20">
          <PasswordInput
            id="input-box"
            className="margin-top-20"
            handlePasswordChange={handlePasswordChange}
            placeholder="Enter new password"
            shouldShownValidationModal
            setPasswordValidity={setIsPasswordValid}
          />
          <PasswordInput
            id="input-box"
            handlePasswordChange={handleConfirmPasswordChange}
            placeholder="Confirm new password"
            onKeyDown={handleEnterPress}
            showEyeIcon
          />
        </div>
        <SubmitButton
          isValid={isValid}
          isLoading={isLoading}
          handleSubmit={handleSubmit}
          buttonText="Continue"
        />
        {openInputAlert && (
        <div className="invalid-field display-center">
          <img className="alert-icon" src={alert} alt="!" />
          <span>{errorMessage}</span>
        </div>
        )}
      </ShakeContainer>
    </div>
  );
}
