import { styled } from '@mui/system';
import Modal from '@mui/material/Modal';

export const StyledModal = styled(Modal)`
position: fixed;
z-index: 1300;
right: 0;
bottom: 0;
top: 0;
left: 0;
display: flex;
align-items: center;
justify-content: center;
`;

export const Backdrop = styled('div')`
z-index: -1;
position: fixed;
right: 0;
bottom: 0;
top: 0;
left: 0;
background-color: rgba(7, 8, 23, 0.85) ;
-webkit-tap-highlight-color: transparent;
`;
