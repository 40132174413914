import React, { useRef, useState } from 'react';

import PropTypes from 'prop-types';

import { get } from 'lodash';
import ReactPlayer from 'react-player';
import ForwardButtonIcon from '../../../../../../../assests/icons/forward_button.svg';
import BackwardButtonIcon from '../../../../../../../assests/icons/backward_button.svg';
import PlayButtonIcon from '../../../../../../../assests/icons/play_button.svg';
import PauseButtonIcon from '../../../../../../../assests/icons/pause_button.svg';
import PrettoSlider from '../../../../../../Common/Video/PrettoSlider';

function Video({
  videoPath,
  id,
  elementKey,
  dataToUse,
  componentStyles,
  gridRow,
  gridCol,
}) {
  const videoPlayerRef = useRef();
  const [playbackRate, setPlaybackRate] = useState(1);
  const [videoState, setVideoState] = useState({
    playing: false,
    muted: false,
    volume: 0.5,
    played: 0,
    seeking: false,
    Buffer: true,
  });

  const playPauseHandler = (event) => {
    event.stopPropagation();
    setVideoState({ ...videoState, playing: !videoState.playing });
  };

  const rewindHandler = (event) => {
    event.stopPropagation();
    videoPlayerRef.current.seekTo(videoPlayerRef.current.getCurrentTime() - 10);
  };

  const fastFowardHandler = (event) => {
    event.stopPropagation();
    videoPlayerRef.current.seekTo(videoPlayerRef.current.getCurrentTime() + 10);
  };

  const getTime = (seconds) => {
    if (seconds === null || seconds === undefined || String(seconds) === 'Infinity') return '00:00';
    return new Date(seconds * 1000).toISOString().slice(14, 19);
  };

  const progressHandler = (state) => {
    if (!videoState.seeking) {
      setVideoState({ ...videoState, ...state });
    }
  };

  const seekMouseUpHandler = (event, evalue) => {
    event.stopPropagation();
    setVideoState({ ...videoState, seeking: false });
    videoPlayerRef.current.seekTo(evalue / 100);
  };

  const seekHandler = (event, evalue) => {
    event.stopPropagation();
    setVideoState({ ...videoState, played: parseFloat(evalue) / 100, seeking: true });
    seekMouseUpHandler(event, evalue);
  };

  const changePlaybackRate = (event) => {
    event.stopPropagation();
    setPlaybackRate((prev) => (prev === 1 ? 2 : 1));
  };

  const videoSrc = get(dataToUse, [videoPath]);

  if (!videoSrc) return null;

  const customStyles = {
    ...componentStyles,
    ...(gridRow && gridCol ? { gridArea: `${gridRow} / ${gridCol}` } : {}),
  };

  return (
    <div
      id={id}
      key={elementKey}
      style={customStyles}
      data-hj-suppress
      aria-hidden
      className="generic_card_v2__video_player_container"
    >
      <div id="video_player__player">
        <ReactPlayer
          playing={videoState.playing}
          ref={videoPlayerRef}
          url={videoSrc}
          height="100%"
          width="100%"
          loop
          onProgress={progressHandler}
          playbackRate={playbackRate}
          onDuration={(duration) => {
            if (duration === Infinity) {
              videoPlayerRef?.current?.seekTo(10000000);
              setTimeout(() => {
                videoPlayerRef?.current?.seekTo(0);
              }, 2000);
            }
          }}
        />
      </div>
      <div className="generic_card_v2__video_player__controls">
        <PrettoSlider
          onChange={seekHandler}
          onChangeCommitted={seekMouseUpHandler}
          min={0}
          max={100}
          value={videoState.played * 100}
        />
        <div id="video_details_controls_div">
          <p id="video_details_controls_div_info">
            {getTime(videoPlayerRef?.current?.getCurrentTime())}
            {' '}
            /
            {' '}
            {getTime(videoPlayerRef?.current?.getSecondsLoaded())}
          </p>
          <div id="video_details_controls_div_parent">
            <div aria-hidden onClick={rewindHandler} id="video_details_controls_div_icon">
              <img src={BackwardButtonIcon} alt="Rewind Button" />
            </div>
            {videoState.playing ? (
              <div aria-hidden onClick={playPauseHandler} id="video_details_controls_div_icon">
                <img src={PauseButtonIcon} alt="Pause Button" />
              </div>
            ) : (
              <div aria-hidden onClick={playPauseHandler} id="video_details_controls_div_icon">
                <img src={PlayButtonIcon} alt="Play Button" />
              </div>
            )}
            <div aria-hidden onClick={fastFowardHandler} id="video_details_controls_div_icon">
              <img src={ForwardButtonIcon} alt="Forward Button" />
            </div>
          </div>
          <div id="video_details_controls_div_icon_playback">
            <p aria-hidden onClick={changePlaybackRate} id="video_details_controls_div_icon_playback_speed">
              {playbackRate}
              x
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

Video.defaultProps = {
  gridRow: undefined,
  gridCol: undefined,
};

Video.propTypes = {
  videoPath: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  elementKey: PropTypes.string.isRequired,
  dataToUse: PropTypes.object.isRequired,
  componentStyles: PropTypes.object.isRequired,
  gridRow: PropTypes.string,
  gridCol: PropTypes.string,
};

export default Video;
