import React from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/react';
import { PermissionWrapper } from 'storybook-ui-components';
import { useSelector, useDispatch } from 'react-redux';
import AlertCode from '../../../../constants/alertCodes';
import { auditDataDownloadType } from '../../../../containers/Common/Download/utils';
import { startDownload } from '../../../../reducers/download';
import useShowErrorAlert, { useFormatAnalyticsData, useFormatPerformanceAnalyticsData } from '../../../../utils/lib';
import XLSXIcon from '../../../../assests/icons/xlsx.svg';
import useGetUserPermissions from '../../../../Permissions/hooks';
import getPermission from '../../../../Permissions/mapping';
import rudderstackEvents from '../../../../constants/rudderstackEventNames';
import screenNames from '../../../../constants/screenNames';
import errorCode from '../../../../constants/errorCode';
import { PERFORMANCE_METRIC_EVENTS } from '../../../../config';

function DownloadCSV({ searchValue }) {
  const showErrorAlert = useShowErrorAlert();
  const email = useSelector((state) => state.user.email);
  const clientId = useSelector((state) => state.user.clientId);
  const status = useSelector((state) => state.data.status);
  const metaData = useSelector((state) => state.data.metaData);
  const application = useSelector((state) => state.data.application);
  const currentAppId = useSelector((state) => state.user.credState?.current?.appId);
  const { startTime, endTime } = application.value;
  const downloadList = useSelector((state) => state.download);
  const dispatch = useDispatch();
  const formatAnalyticsData = useFormatAnalyticsData();
  const formatPerformanceAnalyticsData = useFormatPerformanceAnalyticsData();

  const downloadAuditData = async () => {
    const downloadDataStartTime = performance.now();
    if (downloadList && downloadList.find(({ type }) => type === auditDataDownloadType)) {
      Sentry.captureException(`${errorCode.APPLICATION_DUPLICATE_DOWNLOAD} - ${AlertCode.auditDataDownload.DUPLICATE_REQUEST}`, {
        extra: {
          errorMessage: AlertCode.auditDataDownload.DUPLICATE_REQUEST,
        },
      });
      showErrorAlert({ message: AlertCode.auditDataDownload.DUPLICATE_REQUEST });
    } else {
      dispatch(startDownload({
        type: auditDataDownloadType,
        apiData: {
          searchValue, startTime, endTime, metaData, currentAppId, status: status.value,
        },
      }));
      const processingTime = performance.now() - downloadDataStartTime;
      const eventName = PERFORMANCE_METRIC_EVENTS.APPLICATION_PAGE_DOWNLOAD_CSV;
      const eventObj = {
        processingTime,
        metaData: {
          appId: currentAppId,
        },
      };
      formatPerformanceAnalyticsData(eventObj, eventName);
      formatAnalyticsData(
        email,
        clientId,
        rudderstackEvents.DASHBOARD_APPLICATIONS_CSV_DOWNLOAD,
        screenNames.APPLICATIONS,
      );
    }
  };
  return (
    <button type="button" className="table_action_item" onClick={downloadAuditData}>
      <img src={XLSXIcon} alt="csv" />
      <div>Download as CSV</div>
    </button>
  );
}

DownloadCSV.propTypes = {
  searchValue: PropTypes.string.isRequired,
};

export default PermissionWrapper(DownloadCSV, useGetUserPermissions, getPermission('auditDownloadCSV'));
