import {
  get, isEmpty, isObject, isString,
} from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';

function TableHeader({ header }) {
  if (isEmpty(header)) {
    return null;
  }

  if (isString(header)) {
    return <div className="card_table__row title_row">{header}</div>;
  }

  if (isObject(header)) {
    const { type } = header;
    if (type === 'link') {
      const { link, label } = header;
      return (
        <a className="card_table__row title_row" href={link} target="_blank" rel="noreferrer">{label}</a>
      );
    }
    return get(header, 'label', '');
  }

  return null;
}

TableHeader.defaultProps = {
  header: '',
};

TableHeader.propTypes = {
  header: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default TableHeader;
