import React from 'react';
import PropTypes from 'prop-types';

import getIconForSubType from '../../../LeftPanel/utils';

function Header({ moduleName, subType }) {
  return (
    <div className="card_type_five__header__container">
      <div className="card_type_five__header__image">
        {React.cloneElement(getIconForSubType(subType))}
      </div>
      <div className="card_type_five__header__title">{moduleName}</div>
    </div>
  );
}

Header.propTypes = {
  moduleName: PropTypes.string.isRequired,
  subType: PropTypes.string.isRequired,
};

export default Header;
