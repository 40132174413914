import React from 'react';

import PropTypes from 'prop-types';

import './Toggle.scss';

function Toggle({
  value, name, label, handleToggle, disabled = false,
}) {
  const handleToggleClick = (event) => {
    event.preventDefault();
    handleToggle({ target: { name, value: !value } });
  };

  return (
    <div id="toggle">
      <div id="toggle__row">
        <p>{label}</p>
        <div
          aria-hidden
          onClick={!disabled ? handleToggleClick : () => {}}
          id={`toggle_button${value ? '_on' : ''}`}
          className={`${disabled ? '_disable' : ''}`}
        >
          <div
            id={`toggle_button_switch${
              value ? '_on' : ''
            }`}
          />
        </div>
      </div>
    </div>
  );
}

Toggle.propTypes = {
  value: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  handleToggle: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default Toggle;
