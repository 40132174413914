import React from 'react';
import * as Sentry from '@sentry/react';
import PropTypes from 'prop-types';
import ErrorCard from '../RecordDetails/Details/ErrorCard';
import errorCode from '../../../constants/errorCode';

class DetailsCardErrorBoundary extends React.Component {
  static getDerivedStateFromError() {
    return { hasError: true };
  }

  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, errorInfo) {
    console.log(error, errorInfo);
    Sentry.captureException(`${errorCode.UNHANDLED_ERROR} - ${error}`);
  }

  render() {
    const { hasError } = this.state;
    const { children, moduleId, moduleName } = this.props;
    if (hasError) {
      return <ErrorCard moduleId={moduleId} moduleName={moduleName} />;
    }
    return children;
  }
}
DetailsCardErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
  moduleId: PropTypes.string.isRequired,
  moduleName: PropTypes.string.isRequired,
};

export default DetailsCardErrorBoundary;
