import React from 'react';
import PropTypes from 'prop-types';
import { PermissionFilterListWrapper } from 'storybook-ui-components';
import ActionsIcon from '../../../assests/icons/actions.svg';
import ShareIcon from '../../../assests/icons/share-application.svg';
import CommonSelect from '../../Common/Inputs/CommonSelect';

import '../Details.scss';
import ActionOption from './ActionOption';
import useGetUserPermissions from '../../../Permissions/hooks';
import getPermission from '../../../Permissions/mapping';

const actionsLabel = 'Actions';

function AuditActions({
  handleChange,
}) {
  const userPermissions = useGetUserPermissions();

  const actionOptions = [
    {
      value: 'share_application',
      permissionObject: getPermission('shareApplication'),
      componentRenderer: () => (
        <ActionOption icon={ShareIcon} label="Share Application" />
      ),
    },
  ];

  const actionsDisplay = (value) => (
    <>
      {value === actionsLabel && (
        <>
          <img className="actions_icon" src={ActionsIcon} alt="" />
        </>
      )}
      {value}
    </>
  );

  return (
    <div id="header__right_actions">
      <CommonSelect
        id="header__right_actions_select"
        options={PermissionFilterListWrapper(actionOptions, userPermissions)}
        label={actionsLabel}
        handleChange={handleChange}
        displayComponentRenderer={actionsDisplay}
        isDropdown
      />
    </div>
  );
}

AuditActions.propTypes = {
  handleChange: PropTypes.func.isRequired,
};

export default AuditActions;
