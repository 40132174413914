/* eslint-disable no-bitwise */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import avatarColors from '../../constants/avatarColors';

function Avatar({ name }) {
  const colorToUse = useMemo(() => {
    if (!name) return avatarColors[0];

    const hash = [...name].reduce((acc, char) => char.charCodeAt(0) + ((acc << 5) - acc), 0);
    const index = Math.abs(hash) % avatarColors.length;
    const assignedColor = avatarColors[index];
    return assignedColor;
  }, [name]);

  const abbreviatedName = useMemo(() => {
    if (!name) return '';

    const words = name.trim().split(/\s+/);

    if (words.length === 1 || words.length > 2) {
      return words[0][0].toUpperCase();
    }
    return words.map((word) => word[0].toUpperCase()).join('');
  }, [name]);

  return (
    <div className="avatar" style={{ backgroundColor: colorToUse }}>
      {abbreviatedName}
    </div>
  );
}

Avatar.propTypes = {
  name: PropTypes.string.isRequired,
};

export default Avatar;
