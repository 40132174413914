/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import LeftArrowIcon from '../../../assests/icons/leftArrow08.svg';
import AuditActions from './AuditActions';
import { constants } from '../../../config';

import './Header.scss';
import Toggle from '../../Common/Toggle';

function RecordHeader({
  loading,
  pageConfig,
  recordTitle,
  loadPrevRecord,
  loadNextRecord,
  currentPage,
  onActionSelect,
  searchId,
  showTimelineView,
  setShowTimelineView,
}) {
  const totalRows = useSelector((state) => state.data.totalElements);
  const handleChange = (value) => {
    onActionSelect(value);
  };

  const stateToApplication = { page: currentPage };
  if (searchId) {
    stateToApplication.searchId = searchId;
  }

  const getCurrentApplicationIndex = () => {
    const { currentElement } = pageConfig.pagination;
    return (currentElement + 1) + ((currentPage) * constants.maxRecordsInTable);
  };

  if (loading) {
    return '';
  }

  const onToggleClick = (event) => {
    const { value } = event.target;
    setShowTimelineView(value);
  };

  return (
    <div id="record__header">
      <div id="header__left">
        <Link
          id="header__back"
          to={pageConfig.redirectPage}
          state={stateToApplication}
        >
          <img src={LeftArrowIcon} alt="" />
        </Link>
        <p data-hj-suppress id="header__title">
          {recordTitle}
        </p>
      </div>
      <div id="header__right">
        <Toggle value={showTimelineView} name="timelineViewToggle" label="Timeline View" handleToggle={onToggleClick} />
        <AuditActions handleChange={handleChange} />
        <div id="header__right_nav">
          <button type="button" id="header__right_nav_prev_button" onClick={loadPrevRecord}>
            <img src={LeftArrowIcon} alt="" />
          </button>
          <span>{`${getCurrentApplicationIndex()} of ${totalRows} applications`}</span>
          <button type="button" id="header__right_nav_next_button" onClick={loadNextRecord}>
            <img src={LeftArrowIcon} alt="" />
          </button>
        </div>
      </div>
    </div>
  );
}

RecordHeader.propTypes = {
  loading: PropTypes.bool.isRequired,
  pageConfig: PropTypes.object.isRequired,
  recordTitle: PropTypes.string.isRequired,
  loadPrevRecord: PropTypes.func.isRequired,
  loadNextRecord: PropTypes.func.isRequired,
  currentPage: PropTypes.number.isRequired,
  onActionSelect: PropTypes.func.isRequired,
  searchId: PropTypes.string.isRequired,
  showTimelineView: PropTypes.bool.isRequired,
  setShowTimelineView: PropTypes.func.isRequired,
};

export default RecordHeader;
