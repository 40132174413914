import React from 'react';
import PropTypes from 'prop-types';
import { Spinner } from 'clm-components';
import CustomButton from './CustomButton';

function CustomLoaderButton({
  isLoading, onClick, Icon, buttonText, className, fontClassName,
}) {
  const handleClick = !isLoading ? onClick : undefined;

  return (
    <CustomButton
      className={`custom-loader-button ${className}`}
      onClick={handleClick}
      IconLeft={!isLoading && Icon}
      disabled={isLoading}
    >
      {isLoading ? (
        <Spinner />
      ) : (
        <span className={`custom-loader-button-font ${fontClassName}`}>{buttonText}</span>
      )}
    </CustomButton>
  );
}

CustomLoaderButton.defaultProps = {
  className: '',
  fontClassName: '',
};

CustomLoaderButton.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  Icon: PropTypes.func.isRequired,
  buttonText: PropTypes.string.isRequired,
  className: PropTypes.string,
  fontClassName: PropTypes.string,
};

export default CustomLoaderButton;
