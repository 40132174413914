import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import SummaryMedia from './SummaryMedia';
import { updateMetaDataOpenState, updateUserDetailsOpenState } from '../../../reducers/summary';
import Collapsible from './SummaryCollapsible';

function SummaryBody({
  images,
  videos,
  pdfs,
  metaData,
  userDetails,
}) {
  const dispatch = useDispatch();
  const isMetaDataOpen = useSelector((state) => state.summary.isMetaDataOpen);
  const toggleMetaDataOpenState = () => {
    dispatch(updateMetaDataOpenState(!isMetaDataOpen));
  };

  const isUserDetailsOpen = useSelector((state) => state.summary.isUserDetailsOpen);
  const toggleUserDetailsOpenState = () => {
    dispatch(updateUserDetailsOpenState(!isUserDetailsOpen));
  };
  return (
    <div id="summary__body_container">
      <SummaryMedia images={images} videos={videos} pdfs={pdfs} />
      <Collapsible
        title="Metadata Collected"
        isExpanded={isMetaDataOpen}
        toggleExpandedState={toggleMetaDataOpenState}
        data={metaData}
      />
      <Collapsible
        title="User Details"
        isExpanded={isUserDetailsOpen}
        toggleExpandedState={toggleUserDetailsOpenState}
        data={userDetails}
      />
    </div>
  );
}

SummaryBody.defaultProps = {
  images: [],
  metaData: [],
  userDetails: [],
  videos: [],
  pdfs: [],
};

SummaryBody.propTypes = {
  images: PropTypes.array,
  videos: PropTypes.array,
  pdfs: PropTypes.array,
  metaData: PropTypes.array,
  userDetails: PropTypes.array,
};

export default SummaryBody;
