import React from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import ImageZoom from '../../../../Common/Image/ImageZoom';
import ImagePlaceholder from '../../../../../assests/images/imagePlaceholder.png';
import Title from './Title';

import './Index.scss';

const DEFAULT_IMAGE_HEIGHT = '240px';
const DEFAULT_IMAGE_ALIGN = 'center';

function Image({
  title,
  src,
  alt,
  enableZoom,
  showDefaultImage,
  height,
  align,
  pathToData,
  availableData,
}) {
  const imageSrc = src || get(availableData, pathToData, '');

  if (!imageSrc && !showDefaultImage) return '';

  const onImageLoadError = (e) => {
    e.target.src = ImagePlaceholder;
  };

  return (
    <div className="card_image__container">
      <Title title={title} availableData={availableData} />
      {enableZoom ? (
        <ImageZoom
          src={imageSrc}
          alt={alt}
          className={`card_image ${align}`}
          height={height}
        />
      ) : (
        <div className={`card_image ${align}`} style={{ height }}>
          <img src={imageSrc} alt={alt} onError={onImageLoadError} />
        </div>
      )}
    </div>
  );
}

Image.defaultProps = {
  title: '',
  src: '',
  alt: '',
  enableZoom: true,
  showDefaultImage: true,
  height: DEFAULT_IMAGE_HEIGHT,
  // Allowed Values - start | center | end
  align: DEFAULT_IMAGE_ALIGN,
};

Image.propTypes = {
  title: PropTypes.oneOf([PropTypes.string, PropTypes.object]),
  src: PropTypes.string,
  alt: PropTypes.string,
  enableZoom: PropTypes.bool,
  showDefaultImage: PropTypes.bool,
  height: PropTypes.string,
  align: PropTypes.string,
  pathToData: PropTypes.string.isRequired,
  availableData: PropTypes.object.isRequired,
};

export default Image;
