/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import {
  get, isArray, isBoolean, isEmpty, isNumber, isString,
} from 'lodash';
import {
  Collapse,
  ColumnLayout,
  Column,
  Gap,
  Image,
  Media,
  PDF,
  Section,
  Table,
  TableImage,
  Text,
  TemplateText,
} from '../../../Common';

function Generator({ bodyItem, availableData }) {
  if (
    isString(bodyItem)
    || isNumber(bodyItem)
    || isBoolean(bodyItem)
    || !bodyItem
  ) {
    return bodyItem;
  }

  if (isEmpty(bodyItem)) {
    return null;
  }

  if (isArray(bodyItem)) {
    return bodyItem.map((item, index) => (
      <Generator
        // eslint-disable-next-line react/no-array-index-key
        key={index}
        bodyItem={item}
        availableData={availableData}
      />
    ));
  }

  const { type } = bodyItem;

  if (type === 'text') {
    const { label, size, classes } = bodyItem;
    return <Text label={label} classes={classes} size={size} />;
  }

  if (type === 'templateText') {
    const { label, size, classes } = bodyItem;
    return (
      <TemplateText
        label={label}
        size={size}
        classes={classes}
        availableData={availableData}
      />
    );
  }

  if (type === 'section') {
    const { title, maxHeight, children } = bodyItem;
    return (
      <Section title={title} maxHeight={maxHeight} availableData={availableData}>
        {children
          && children.map((i) => (
            <Generator
              key={bodyItem.key}
              bodyItem={i}
              availableData={availableData}
            />
          ))}
      </Section>
    );
  }

  if (type === 'table') {
    const {
      title, maxHeight, whitelistedFields, pathToData,
    } = bodyItem;
    return (
      <Table
        title={title}
        maxHeight={maxHeight}
        whitelistedFields={whitelistedFields}
        pathToData={pathToData}
        availableData={availableData}
      />
    );
  }

  if (type === 'image') {
    const {
      title, src, alt, enableZoom, showDefaultImage, height, align, pathToData,
    } = bodyItem;
    return (
      <Image
        title={title}
        src={src}
        alt={alt}
        enableZoom={enableZoom}
        showDefaultImage={showDefaultImage}
        height={height}
        align={align}
        pathToData={pathToData}
        availableData={availableData}
      />
    );
  }

  if (type === 'tableImage') {
    const {
      title, image, table, order,
    } = bodyItem;
    return (
      <TableImage
        title={title}
        image={image}
        table={table}
        availableData={availableData}
        order={order}
      />
    );
  }

  if (type === 'dynamicGroup') {
    const {
      childrenType, childrenProps, data, pathToData,
    } = bodyItem;
    const groupData = get(availableData, pathToData, data || []);
    return (
      <>
        {groupData.map((key, index) => {
          const dynamicbodyItemData = {
            type: childrenType,
            // Share pathToData[index] for dynamic data
            // Share data[index] for static data
            pathToData: `${pathToData}[${index}]`,
            ...get(data, index, {}),
            ...childrenProps,
          };
          return (
            <Generator
              key={key}
              bodyItem={dynamicbodyItemData}
              availableData={availableData}
            />
          );
        })}
      </>
    );
  }

  if (type === 'collapse') {
    const { title, maxHeight, children } = bodyItem;
    return (
      <Collapse title={title} maxHeight={maxHeight}>
        {children
          && children.map((i) => (
            <Generator
              key={i.key}
              bodyItem={i}
              availableData={availableData}
            />
          ))}
      </Collapse>
    );
  }

  if (type === 'column') {
    const {
      maxHeight, flex, classes, children,
    } = bodyItem;
    return (
      <Column maxHeight={maxHeight} flex={flex} classes={classes}>
        {children
          && children.map((i) => (
            <Generator
              key={bodyItem.key}
              bodyItem={i}
              availableData={availableData}
            />
          ))}
      </Column>
    );
  }

  if (type === 'columnLayout') {
    const { columnGap, rowGap, children } = bodyItem;
    return (
      <ColumnLayout columnGap={columnGap} rowGap={rowGap}>
        {children
          && children.map((i) => (
            <Generator
              key={bodyItem.key}
              bodyItem={i}
              availableData={availableData}
            />
          ))}
      </ColumnLayout>
    );
  }

  if (type === 'gap') {
    const { height, width } = bodyItem;
    return <Gap height={height} width={width} />;
  }

  if (type === 'media') {
    const {
      src, imageProps, pdfProps, pathToData,
    } = bodyItem;
    return (
      <Media
        src={src}
        imageProps={imageProps}
        pdfProps={pdfProps}
        pathToData={pathToData}
        availableData={availableData}
      />
    );
  }

  if (type === 'pdf') {
    const {
      title, src, height, align, pathToData,
    } = bodyItem;
    return (
      <PDF
        title={title}
        src={src}
        height={height}
        align={align}
        pathToData={pathToData}
        availableData={availableData}
      />
    );
  }

  return null;
}

Generator.propTypes = {
  bodyItem: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ]).isRequired,
  availableData: PropTypes.object.isRequired,
};

export default Generator;
