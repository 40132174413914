import React from 'react';
import PropTypes from 'prop-types';
import CrossIcon from '../../../assests/icons/cross.svg';

function EditFilterButton({
  handleClick, label, onClose, isCancellable, isActive,
}) {
  return (
    <div id="edit_filter_button_container">
      <button type="button" id="edit_filter_button" onClick={handleClick} className={`${isActive ? 'active' : ''}`}>
        <span id="edit_filter_button_label">{label}</span>
      </button>
      <div id="edit_filter_close">
        {isCancellable && <img src={CrossIcon} alt="x" onClick={onClose} aria-hidden /> }
      </div>
    </div>
  );
}

EditFilterButton.propTypes = {
  isActive: PropTypes.bool.isRequired,
  handleClick: PropTypes.func.isRequired,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]).isRequired,
  onClose: PropTypes.func.isRequired,
  isCancellable: PropTypes.bool.isRequired,
};

export default EditFilterButton;
