import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as SendIconDisable } from '../../../../assests/icons/sendIconDisable.svg';
import { ReactComponent as SendIconEnable } from '../../../../assests/icons/sendIconEnable.svg';
import './Comment.scss';

function CommentTextBox({ placeholder, sendHandler }) {
  const [textBoxFocused, setTextBoxFocused] = useState(false);
  const [commentMsg, setCommentMsg] = useState('');

  const sendComment = () => {
    sendHandler(commentMsg);
    setCommentMsg('');
    setTextBoxFocused(false);
  };

  const focusTextBox = () => setTextBoxFocused(true);

  const blurTextBox = () => setTextBoxFocused(false);

  return (
    <div
      id="comment_text_textbox"
      className={textBoxFocused ? 'focus' : ''}
      onFocus={focusTextBox}
      onBlur={blurTextBox}
    >
      <textarea
        data-hj-suppress
        value={commentMsg}
        onChange={(event) => setCommentMsg(event.target.value)}
        placeholder={placeholder}
      />
      <button type="button">
        { textBoxFocused
          ? <SendIconEnable onMouseDown={sendComment} />
          : <SendIconDisable />}
      </button>
    </div>
  );
}

CommentTextBox.defaultProps = {
  placeholder: 'Add your comments here...',
};

CommentTextBox.propTypes = {
  placeholder: PropTypes.string,
  sendHandler: PropTypes.func.isRequired,
};
export default CommentTextBox;
