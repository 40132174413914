const COOKIE_DOMAIN = process.env.REACT_APP_COOKIE_DOMAIN || '.hyperverge.co';

export const getCookie = (key) => {
  const cookie = `; ${document.cookie}`;
  const parts = cookie.split(`; ${key}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
  return null;
};

export const setCookie = (name, value, expiryInMs, domain = COOKIE_DOMAIN) => {
  let expires = '';
  if (expiryInMs) {
    const date = new Date();
    date.setTime(date.getTime() + expiryInMs);
    expires = `; expires=${date.toUTCString()}`;
  }
  document.cookie = `${name}=${value}${expires}; path=/; domain=${domain}`;
};

export const deleteCookie = (name) => {
  document.cookie = `${name}=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
};
