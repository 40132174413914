import PropTypes from 'prop-types';
import React from 'react';

function Header({ headerText, id, paragraphId }) {
  return (
    <div id={id}>
      <div id="client_pricing_info__font">
        <p id={paragraphId}>{headerText}</p>
      </div>
    </div>
  );
}

Header.defaultProps = {
  id: 'client_pricing_info__display',
  paragraphId: '',
};

Header.propTypes = {
  headerText: PropTypes.string.isRequired,
  id: PropTypes.string,
  paragraphId: PropTypes.string,
};

export default Header;
