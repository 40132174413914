import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import Title from './Title';
import PDFRender from '../../../../Common/PDF/PDFRender';

import './Index.scss';
import PDFViewerOpenWrapper from '../../../../Common/PDF/PDFViewer/PDFViewerOpenWrapper';

const DEFAULT_PDF_HEIGHT = '240px';
const DEFAULT_PDF_ALIGN = 'center';

function PDF({
  title,
  src,
  height,
  align,
  pathToData,
  availableData,
  showControls,
}) {
  const pdfSrc = src || get(availableData, pathToData, '');
  const [isPDFViewerOpen, setIsPDFViewerOpen] = useState(false);

  const handlePDFClick = () => {
    setIsPDFViewerOpen(true);
  };

  const handlePDFViewerClose = () => {
    setIsPDFViewerOpen(false);
  };

  if (!pdfSrc) return '';

  return (
    <>
      <PDFViewerOpenWrapper
        open={isPDFViewerOpen}
        pdf={{ src: pdfSrc }}
        closeViewerHandler={handlePDFViewerClose}
      />
      <div className="card_pdf__container">
        <Title title={title} availableData={availableData} />
        <PDFRender
          src={pdfSrc}
          className={`card_pdf ${align}`}
          height={height}
          showControls={showControls}
          onClick={handlePDFClick}
        />
      </div>
    </>
  );
}

PDF.defaultProps = {
  title: '',
  src: '',
  showControls: false,
  height: DEFAULT_PDF_HEIGHT,
  // Allowed Values - start | center | end
  align: DEFAULT_PDF_ALIGN,
};

PDF.propTypes = {
  title: PropTypes.oneOf([PropTypes.string, PropTypes.object]),
  src: PropTypes.string,
  height: PropTypes.string,
  align: PropTypes.string,
  pathToData: PropTypes.string.isRequired,
  availableData: PropTypes.object.isRequired,
  showControls: PropTypes.bool,
};

export default PDF;
