import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { PermissionWrapper } from 'storybook-ui-components';
import CommentTextBox from './CommentTextBox';
import CommentList from './CommentList';
import useGetUserPermissions from '../../../../Permissions/hooks';
import getPermission from '../../../../Permissions/mapping';

import './Comment.scss';

function CommentBody({
  comments,
  addNewComment,
}) {
  const [commentViewMode, setCommentViewMode] = useState(false);

  useEffect(() => {
    if (comments.length) setCommentViewMode(true);
  }, [comments]);

  return (
    <div id="comment_body_container">
      <div id="comment_body_top_panel">
        {!commentViewMode ? (
          <>
            <p id="comment_body_heading">COMMENTS</p>
            {comments.length ? (
              <button type="button" id="comment_body_view" onClick={() => setCommentViewMode(true)}>
                view comments (
                {comments.length}
                )
              </button>
            ) : null}
          </>
        ) : <CommentList setViewMode={setCommentViewMode} comments={comments} />}
      </div>
      <CommentTextBox sendHandler={addNewComment} />
    </div>
  );
}

CommentBody.propTypes = {
  comments: PropTypes.arrayOf(PropTypes.any).isRequired,
  addNewComment: PropTypes.func.isRequired,
};
export default PermissionWrapper(CommentBody, useGetUserPermissions, getPermission('commentsCard'));
