/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';
import LeftArrowIcon from '../../../assests/icons/leftArrow08.svg';
import PlayButton from '../../../assests/icons/play_button.svg';
import RightArrowIcon from '../../../assests/icons/rightArrow.svg';
import ImagePlaceholder from '../../../assests/images/imagePlaceholder.png';
import { smoothHorizontalScrolling } from '../../../utils/scrollUtils';
import PDFRender from '../PDF/PDFRender';

import './Image.scss';

const defaultScrollAmount = 250;
const defaultScrollTime = 250; // ms

function MediaCarousel({
  id,
  scrollAmount,
  scrollTime,
  images,
  onImageClick,
  height,
  onVideoClick,
  videos,
  pdfs,
  onPDFClick,
}) {
  const imageScrollRef = useRef(null);
  const imageLoadCounter = useRef(0);
  const pdfLoadCounter = useRef(0);
  const [showLeftScroll, setShowLeftScroll] = useState(false);
  const [showRightScroll, setShowRightScroll] = useState(false);

  const scrollButtonsVisibilityHandler = (currentScrollLeft, offsetWidth, scrollWidth) => {
    if (imageScrollRef.current) {
      setShowLeftScroll(currentScrollLeft > 0);
      setShowRightScroll(offsetWidth + currentScrollLeft < scrollWidth);
    }
  };

  const getScrollPositions = () => {
    const currentScrollLeft = imageScrollRef.current.scrollLeft;
    const { offsetWidth, scrollWidth } = imageScrollRef.current;
    return { currentScrollLeft, offsetWidth, scrollWidth };
  };

  const scrollImages = (currentScrollLeft, newScrollLeft, offsetWidth, scrollWidth, direction) => {
    // Modified Logic for useless Safari :(
    // https://developer.apple.com/forums/thread/703294
    // imageScrollRef.current.scrollTo({
    //   left: newScrollLeft,
    //   behavior: 'smooth',
    // });
    smoothHorizontalScrolling(
      imageScrollRef.current,
      scrollTime,
      scrollAmount,
      currentScrollLeft,
      direction,
    );
    scrollButtonsVisibilityHandler(newScrollLeft, offsetWidth, scrollWidth);
  };

  const scrollLeft = () => {
    const { currentScrollLeft, offsetWidth, scrollWidth } = getScrollPositions();
    const newScrollLeft = currentScrollLeft - scrollAmount;
    scrollImages(currentScrollLeft, newScrollLeft, offsetWidth, scrollWidth, 'left');
  };

  const scrollRight = () => {
    const { currentScrollLeft, offsetWidth, scrollWidth } = getScrollPositions();
    const newScrollLeft = currentScrollLeft + scrollAmount;
    scrollImages(currentScrollLeft, newScrollLeft, offsetWidth, scrollWidth, 'right');
  };

  const windowResizeHandler = () => {
    if (imageScrollRef.current) {
      const { currentScrollLeft, offsetWidth, scrollWidth } = getScrollPositions();
      scrollButtonsVisibilityHandler(currentScrollLeft, offsetWidth, scrollWidth);
    }
  };

  const checkHasChildrenLoaded = () => {
    if (imageLoadCounter.current >= images.length || pdfLoadCounter.current >= pdfs.length) {
      windowResizeHandler();
    }
  };

  const onImageLoad = () => {
    imageLoadCounter.current += 1;
    checkHasChildrenLoaded();
  };

  const onImageLoadError = (e) => {
    e.target.src = ImagePlaceholder;
    imageLoadCounter.current += 1;
    checkHasChildrenLoaded();
  };

  const onPdfLoad = () => {
    pdfLoadCounter.current += 1;
    checkHasChildrenLoaded();
  };

  useEffect(() => {
    window.addEventListener('resize', windowResizeHandler);
    return () => {
      window.removeEventListener('resize', windowResizeHandler);
    };
  }, [imageScrollRef.current]);

  if ((!images || images.length === 0)
  && (!pdfs || pdfs.length === 0)
  && (!videos || videos.length === 0)) {
    return (
      <div id={id} className="common_carousal__container empty">No media collected</div>
    );
  }

  return (
    <div data-hj-suppress id={id} className="common_carousal__container" style={{ height }}>
      {showLeftScroll && (
        <button type="button" className="left" onClick={scrollLeft}>
          <img src={LeftArrowIcon} alt="" />
        </button>
      )}
      <div className="common_carousal__images" ref={imageScrollRef}>
        {images.map(({ label, src }, idx) => (
          <div key={src} className="common_carousal__image" onClick={() => onImageClick(idx)} aria-hidden>
            <img src={src} alt="" onLoad={onImageLoad} onError={onImageLoadError} />
            <span>{label}</span>
          </div>
        ))}
        {videos.map(({ label, src }, idx) => (
          <div onClick={() => onVideoClick(idx)} key={src} className="common_carousal__video" aria-hidden>
            <ReactPlayer height="100%" width="362px" url={src} />
            <span>{label}</span>
            <div className="common_carousal__video_play_button">
              <img src={PlayButton} alt="Play Button" />
            </div>
          </div>
        ))}
        {pdfs.map(({ label, src }, idx) => (
          <div key={src} className="common_carousal__image" aria-hidden>
            <PDFRender
              src={src}
              onClick={() => onPDFClick(idx)}
              className="common_carousal__pdf"
              showControls={false}
              onDocumentLoadComplete={onPdfLoad}
            />
            <span>{label}</span>
          </div>
        ))}
      </div>
      {
    showRightScroll && (
      <button type="button" className="right" onClick={scrollRight}>
        <img src={RightArrowIcon} alt="" />
      </button>
    )
  }
    </div>
  );
}

MediaCarousel.defaultProps = {
  id: '',
  scrollAmount: defaultScrollAmount,
  scrollTime: defaultScrollTime,
  height: null,
};

MediaCarousel.propTypes = {
  id: PropTypes.string,
  scrollAmount: PropTypes.number,
  scrollTime: PropTypes.number,
  images: PropTypes.array.isRequired,
  videos: PropTypes.array.isRequired,
  pdfs: PropTypes.array.isRequired,
  onImageClick: PropTypes.func.isRequired,
  onVideoClick: PropTypes.func.isRequired,
  onPDFClick: PropTypes.func.isRequired,
  height: PropTypes.string,
};

export default MediaCarousel;
