import React from 'react';
import { Navigate } from 'react-router-dom';

const usagePageComponentPermissions = {
  requiredPermissions: {
    viewPermissions: ['usagePage.view'],
    interactPermissions: ['usagePage.interact'],
  },
  redirect: () => <Navigate to="/home" replace />,
};

const usagePagePermissions = {
  usagePage: usagePageComponentPermissions,
};

export default usagePagePermissions;
