import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import CustomTabs from '../../../Common/Utils/CustomTabs';
import '../../Details.scss';
import VideoCardPanel from '../Panels/VideoCardPanel';
import CommonHeader from './CommonHeader';

// We should be getting the subType from parent component
// once we have cleaned up the logic for this flow
const VideoCard = ({ moduleName, records }) => {
  const [tabIndex, setTabIndex] = useState(0);
  const [videoRecordingsArray, setVideoRecordingsArray] = useState([]);

  useEffect(() => {
    setTabIndex(0);
    setVideoRecordingsArray(records);
  }, [records]);

  return (
    <div id="video_details__container">
      <CommonHeader moduleName={moduleName} subType="videoCardType1" />
      <div id="video_details__container__section">
        <CustomTabs
          id="details__body_tabs"
          tabArray={videoRecordingsArray}
          tabIndex={tabIndex}
          setTabIndex={setTabIndex}
        />
        {videoRecordingsArray.map((item, index) => (
          <VideoCardPanel key={item?.id} item={item} index={index} value={tabIndex} />
        ))}
      </div>
    </div>
  );
};

VideoCard.propTypes = {
  moduleName: PropTypes.string.isRequired,
  records: PropTypes.arrayOf(PropTypes.any.isRequired).isRequired,
};

export default VideoCard;
