import React, { useEffect, useState } from 'react';

import {
  TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineSeparator,
} from '@mui/lab';
import PropTypes from 'prop-types';
import ImageZoom from '../../../Common/Image/ImageZoom';
import ImageViewerOpenWrapper from '../../../Common/Image/ImageViewer/ImageViewerOpenWrapper';
import { isUrl } from '../../../../utils/lib';

const TimelineCard = ({
  statement,
  index,
  images,
  videoPlayerRef,
  journeyId,
}) => {
  const [slideShowImageIndex, setSlideShowImageIndex] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [slideShowImages, setSlideShowImages] = useState([]);

  // Used to get requestID and filename from signed url
  const getFileDataFromURL = (url) => {
    const splitUrlParts = url.split('/');
    const numberOfParts = splitUrlParts.length;
    if (numberOfParts < 2) return {};
    const requestId = splitUrlParts[numberOfParts - 2]; // requestId will be the second last value
    const fileName = splitUrlParts[numberOfParts - 1]; // fileName will be the last value
    return { requestId, fileName };
  };

  // Function to map image to statement using URL or key
  const getImageForStatement = (key, fileUrls = []) => {
    if (!key) return null;
    if (!isUrl(key)) return images[key] || null;
    const imageUrl = (fileUrls.find(({ url }) => {
      const { pathname: statementURLPathname } = new URL(key);
      const { pathname: currentURLPathname } = new URL(url);
      const { fileName: statementFileName } = getFileDataFromURL(statementURLPathname);
      const { fileName: currentFileName } = getFileDataFromURL(currentURLPathname);
      if (statementFileName === currentFileName) return true;
      return false;
    })?.url);

    return imageUrl || null;
  };

  // Get slideshow images for current statement
  // {
  // key: KEY SPECIFIED IN STATEMENT USED TO GET INDEX,
  // label: LABEL DISPLAYED,
  // src: IMAGE SOURCE
  // }
  const getSlideshowImages = () => {
    const faceMatchImage = getImageForStatement(
      statement?.faceMatch?.image2,
      statement?.faceMatch?.fileUrls,
    );
    const livenessImage = getImageForStatement(
      statement?.liveness?.image,
      statement?.liveness?.fileUrls,
    );

    setSlideShowImages([
      { label: 'Input Image', src: faceMatchImage },
      { label: 'Captured Image', src: livenessImage },
    ]);
  };

  useEffect(() => {
    getSlideshowImages();
  }, []);

  const statementClickHandler = (event, timestamp) => {
    event.stopPropagation();
    event.target.scrollIntoView({ behavior: 'smooth' });
    const [hours, minutes, seconds] = timestamp.split(':');
    const totalSeconds = (parseInt(hours, 10) * 60 * 60)
      + (parseInt(minutes, 10) * 60) + parseInt(seconds, 10);
    videoPlayerRef.current.seekTo(parseFloat(totalSeconds));
  };

  const handleImageClick = (imageIndex) => {
    setSlideShowImageIndex(imageIndex);
    setIsViewerOpen(true);
  };

  const handleViewerClose = () => {
    setIsViewerOpen(false);
  };

  if (!statement) return '';

  return (
    <TimelineItem id={journeyId + statement.statementId}>
      <ImageViewerOpenWrapper
        defaultImageIndex={slideShowImageIndex}
        images={slideShowImages}
        open={isViewerOpen}
        closeViewerHandler={handleViewerClose}
      />
      <TimelineSeparator>
        <TimelineDot onClick={(event) => statementClickHandler(event, statement.startTimestamp)}>
          <p>{statement.startTimestamp}</p>
        </TimelineDot>
        <TimelineConnector />
      </TimelineSeparator>
      <TimelineContent>
        <div id="video_details_col_timeline_card">
          <h5 aria-hidden onClick={(event) => statementClickHandler(event, statement.startTimestamp)} id="video_details_col_timeline_card_title">
            Statement&nbsp;
            {index + 1}
          </h5>
          <p id="video_details_col_timeline_card_subtitle">TEXT MATCHING</p>
          {/* SPEECH OUTPUTS */}
          <div id="video_details_col_timeline_card_speechtext_div">
            <div id="video_details_col_timeline_card_speechtext_row">
              <div id="video_details_col_timeline_card_speechtext_small_col">
                <p>Input Text</p>
              </div>
              <div data-hj-suppress id="video_details_col_timeline_card_speechtext_large_col">
                <p>{statement?.statementText}</p>
              </div>
            </div>
            <div id="video_details_col_timeline_card_speechtext_row">
              <div id="video_details_col_timeline_card_speechtext_small_col">
                <p>Speech To Text</p>
              </div>
              <div data-hj-suppress id="video_details_col_timeline_card_speechtext_large_col">
                <p>{statement?.speechToText}</p>
              </div>
            </div>
            <div id="video_details_col_timeline_card_speechtext_row">
              <div id="video_details_col_timeline_card_speechtext_small_col">
                <p>Lip Reading</p>
              </div>
              <div data-hj-suppress id="video_details_col_timeline_card_speechtext_large_col">
                <p>--</p>
              </div>
            </div>
          </div>
          {/* RESULTS */}
          <div id="video_details_col_timeline_card_result_row" className={statement?.speechToTextMatching?.results?.match === 'no' && 'failed_match'}>
            <div id="video_details_col_timeline_card_result_small_col">
              <p>Match Result</p>
            </div>
            <div data-hj-suppress id="video_details_col_timeline_card_result_large_col">
              {statement?.speechToTextMatching?.results?.match
                ? <p>{statement?.speechToTextMatching?.results?.match}</p> : <p>--</p>}
            </div>
          </div>
          {/* IMAGES */}
          <div id="video_details_col_timeline_card_images_row">
            {statement?.faceMatch?.image2
              && (
              <div id="video_details_col_timeline_card_images_col">
                <p>INPUT IMAGE</p>
                <div id="video_details_col_timeline_card_images_div">
                  <ImageZoom
                    id="video_details_col_timeline_card_image"
                    src={getImageForStatement(
                      statement?.faceMatch?.image2,
                      statement?.faceMatch?.fileUrls,
                    )}
                    onClick={() => handleImageClick(0)}
                  />
                </div>
              </div>
              )}
            {statement?.liveness?.image
              && (
              <div id="video_details_col_timeline_card_images_col">
                <p>FACE CAPTURED</p>
                <div id="video_details_col_timeline_card_images_div">
                  <ImageZoom
                    id="video_details_col_timeline_card_image"
                    src={getImageForStatement(
                      statement?.liveness?.image,
                      statement?.liveness?.fileUrls,
                    )}
                    onClick={() => handleImageClick(1)}
                  />
                </div>
              </div>
              )}
          </div>
          {/* RESULTS */}
          <div id="video_details_col_timeline_card_checks" className={statement?.liveness?.results?.live === 'no' && 'failed_match'}>
            <div id="video_details_col_timeline_card_checks_row">
              <div id="video_details_col_timeline_card_checks_col">
                <p>Liveness</p>
              </div>
              <div data-hj-suppress id="video_details_col_timeline_card_checks_col">
                <p>{statement?.liveness?.results?.live}</p>
              </div>
            </div>
            {
              statement?.faceMatch?.results?.match && (
              <div id="video_details_col_timeline_card_checks_row" className={statement?.faceMatch?.results?.match === 'no' && 'failed_match'}>
                <div id="video_details_col_timeline_card_checks_col">
                  <p>Face Match</p>
                </div>
                <div data-hj-suppress id="video_details_col_timeline_card_checks_col">
                  <p>{statement?.faceMatch?.results?.match}</p>
                </div>
              </div>
              )
            }
          </div>
        </div>
      </TimelineContent>
    </TimelineItem>
  );
};

TimelineCard.propTypes = {
  statement: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  images: PropTypes.object.isRequired,
  videoPlayerRef: PropTypes.object.isRequired,
  journeyId: PropTypes.string.isRequired,
};

export default TimelineCard;
