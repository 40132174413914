import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { PermissionWrapper } from 'storybook-ui-components';
import '../Comment/Comment.scss';
import { useSelector } from 'react-redux';
import { Spinner } from 'clm-components';
import { isEmpty } from 'lodash';
import * as Sentry from '@sentry/react';
import { ReactComponent as DeclineIcon } from '../../../../assests/icons/declineIcon.svg';
import { ReactComponent as ApproveIcon } from '../../../../assests/icons/approveIcon.svg';
import { buildRequestsForReview, makeRequestsForReview } from './utils';
import useShowErrorAlert from '../../../../utils/lib';
import useGetUserPermissions from '../../../../Permissions/hooks';
import getPermission from '../../../../Permissions/mapping';
import AlertCode from '../../../../constants/alertCodes';
import errorCode from '../../../../constants/errorCode';

function ReviewButtons({ updateApplicationStatus, applicationStatus }) {
  const reviewTagsConfig = useSelector((state) => state.appDetails.reviewTags);
  const hitsReviewTagConfig = useSelector((state) => state.appDetails.hitsReviewTags);
  const currentCredential = useSelector((state) => state.appDetails.currentCredential);
  const appState = useSelector((state) => state.appDetails);

  const [isLoading, setIsLoading] = useState(false);

  const showErrorAlert = useShowErrorAlert();

  if (applicationStatus !== 'needs_review') {
    return '';
  }

  const onButtonClick = async (status) => {
    setIsLoading(true);
    try {
      if (!isEmpty(reviewTagsConfig) || !isEmpty(hitsReviewTagConfig)) {
        const { requests, error } = buildRequestsForReview(
          reviewTagsConfig, hitsReviewTagConfig, appState,
        );
        if (error) {
          Sentry.captureException(error);
          showErrorAlert({ message: error.message });
          return;
        }
        const {
          failureResponseList,
        } = await makeRequestsForReview(requests, { ...currentCredential });

        if (failureResponseList.length) {
          const errorMessage = failureResponseList.find(({ message }) => !!message)?.message;
          showErrorAlert({ message: errorMessage });
          return;
        }
      }

      await updateApplicationStatus(status);
    } catch (error) {
      showErrorAlert({ error, message: AlertCode.UPDATE_APPLICATION_STATUS });
      Sentry.captureException(`${errorCode.APPLICATION_STATUS_UPDATE_ERROR} - ${error}`, {
        extra: {
          errorMessage: AlertCode.UPDATE_APPLICATION_STATUS,
        },
      });
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return (
      <div id="review_buttons_container" className="loading">
        <Spinner />
      </div>
    );
  }

  return (
    <div id="review_buttons_container">
      <button
        type="button"
        id="review_buttons_button_decline"
        onClick={() => onButtonClick('manually_declined')}
      >
        <DeclineIcon />
        <p>Decline</p>
      </button>
      <button
        type="button"
        id="review_buttons_button_approve"
        onClick={() => onButtonClick('manually_approved')}
      >
        <ApproveIcon />
        <p>Approve</p>
      </button>
    </div>
  );
}

ReviewButtons.propTypes = {
  updateApplicationStatus: PropTypes.func.isRequired,
  applicationStatus: PropTypes.string.isRequired,
};

export default PermissionWrapper(ReviewButtons, useGetUserPermissions, getPermission('auditButtons'));
