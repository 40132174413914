import { get } from 'lodash';

// const amlRelavance = (val) => {
//   let amlRelavances = val;
//   switch (val) {
//     case 'name_exact':
//       amlRelavances = ' Exact name match';
//       break;
//     case 'aka_exact':
//       amlRelavances = ' Exact AKA match';
//       break;
//     case 'name_fuzzy':
//       amlRelavances = ' Fuzzy name match';
//       break;
//     case 'aka_fuzzy':
//       amlRelavances = ' Fuzzy AKA match';
//       break;
//     case 'phonetic_name':
//       amlRelavances = 'Phonetic name match';
//       break;
//     case 'phonetic_aka':
//       amlRelavances = 'Phonetic AKA match';
//       break;
//     case 'equivalent_name':
//       amlRelavances = 'Name matched against synonym';
//       break;
//     case 'equivalent_aka':
//       amlRelavances = 'AKA matched against synonym';
//       break;
//     case 'unknown':
//       amlRelavances = 'Matched based on an acronym';
//       break;
//     case 'year_of_birth':
//       amlRelavances = 'Year of birth match';
//       break;
//     case 'removed_personal_title':
//       amlRelavances = 'Matched on the basis on a personal title being removed';
//       break;
//     case 'removed_personal_suffix':
//       amlRelavances = 'Matched on the basis on a personal suffix being removed';
//       break;
//     case 'removed_organisation_prefix':
//       amlRelavances = 'Matched on the basis on an organisation prefix being removed';
//       break;
//     case 'removed_organisation_suffix':
//       amlRelavances = 'Matched on the basis on an organisation suffix being removed';
//       break;
//     case 'removed_clerical_mark':
//       amlRelavances = 'Matched on the basis on a clerical mark being removed';
//       break;
//     default:
//       amlRelavances = val;
//       break;
//   }
//   return amlRelavances;
// };

const amlRelavanceLabelMap = {
  nameMatch: 'Name Match',
  yobMatch: 'Year of Birth Match',
  countryMatch: 'Country Match',
};

const amlRelavance = (relevance) => get(amlRelavanceLabelMap, relevance, relevance);

export default amlRelavance;
