import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import AlertCode from '../../../constants/alertCodes';
import { useFormatAnalyticsData } from '../../../utils/lib';
import rudderstackEvents from '../../../constants/rudderstackEventNames';
import screenNames from '../../../constants/screenNames';
import './Credential.scss';

function AppIdCell({
  column, value, updateAlert, isHoveredOn,
}) {
  const email = useSelector((state) => state.user.email);
  const clientId = useSelector((state) => state.user.clientId);
  const formatAnalyticsData = useFormatAnalyticsData();

  const copyText = () => {
    formatAnalyticsData(
      email, clientId, rudderstackEvents.DASHBOARD_CREDENTIAL_COPY, screenNames.CREDENTIAL,
    );
    const payload = {
      message: AlertCode.COPIED_TO_CLIPBOARD,
      alertSeverity: 'success',
    };
    updateAlert(payload);
  };

  if (!value) return '-';
  return (
    <div key={column} id="app_id_cell__text">
      <span data-hj-suppress>
        {value}
      </span>
      <CopyToClipboard
        text={value}
        onCopy={copyText}
      >
        <button type="button" id="app_id_cell__copy" title="Copy" className={!isHoveredOn && 'disabled'}>
          copy
        </button>
      </CopyToClipboard>
    </div>
  );
}

AppIdCell.propTypes = {
  column: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  updateAlert: PropTypes.func.isRequired,
  isHoveredOn: PropTypes.bool.isRequired,
};

export default AppIdCell;
