import React from 'react';
import PropTypes from 'prop-types';
import '../../Details.scss';
import CommonHeader from './CommonHeader';

function ErrorCard({ moduleId, moduleName, subType }) {
  return (
    <div id={moduleId}>
      <div id="details__container">
        <CommonHeader moduleName={moduleName} subType={subType} />
        <div>
          Unexpected Error Occured
        </div>
      </div>
    </div>
  );
}

ErrorCard.propTypes = {
  moduleId: PropTypes.string.isRequired,
  moduleName: PropTypes.string.isRequired,
  subType: PropTypes.string.isRequired,
};
export default ErrorCard;
