import React from 'react';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import './Common.scss';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

function FilterByButton({ onClick }) {
  return (
    <div id="common__filterBy__container">
      <Button
        variant="outlined"
        onClick={onClick}
        id="common__filterBy__button"
        endIcon={<KeyboardArrowDownIcon />}
      >
        <p id="common__filterBy__button_text">
          Filter by
        </p>
      </Button>
    </div>
  );
}

export default FilterByButton;

FilterByButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};
