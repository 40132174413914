import axios from 'axios';

const linkKycStart = async (appId, workflowId, transactionId, inputs) => {
  const linkKycRes = await axios({
    method: 'POST',
    headers: { appId },
    url: `${process.env.REACT_APP_SERVER_URL}/api/v1/user/link-kyc/start`,
    data: {
      workflowId,
      transactionId,
      inputs,
    },
  });
  const newLink = linkKycRes.data.result.startKycUrl;
  return newLink;
};

export default linkKycStart;
