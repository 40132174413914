import React from 'react';
import PropTypes from 'prop-types';
import AppIdDropdown from './AppIdDropdown';
import ClientIdDropdown from './ClientIdDropdown';
import UseCaseDropdown from './UseCaseDropdown';

import './Credentials.scss';

function CredentialSelectWrapper({
  id, className, hideClientDropdown, hideUseCaseDropdown, hideAppIdDropdown,
}) {
  return (
    <div id={id} className={`${className} credentials_select_wrapper__container`}>
      {!hideClientDropdown && <ClientIdDropdown />}
      {!hideUseCaseDropdown && <UseCaseDropdown />}
      {!hideAppIdDropdown && <AppIdDropdown />}
    </div>
  );
}

CredentialSelectWrapper.defaultProps = {
  id: '',
  className: '',
  hideClientDropdown: false,
  hideUseCaseDropdown: false,
  hideAppIdDropdown: false,
};

CredentialSelectWrapper.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  hideClientDropdown: PropTypes.bool,
  hideUseCaseDropdown: PropTypes.bool,
  hideAppIdDropdown: PropTypes.bool,
};

export default CredentialSelectWrapper;
