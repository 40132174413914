import React from 'react';
import PropTypes from 'prop-types';

function ColumnLayout({ columnGap, rowGap, children }) {
  return (
    <div style={{ gap: `${rowGap} ${columnGap}` }} className="column_layout__container">
      {children}
    </div>
  );
}

ColumnLayout.defaultProps = {
  columnGap: '48px',
  rowGap: '24px',
};

ColumnLayout.propTypes = {
  columnGap: PropTypes.string,
  rowGap: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default ColumnLayout;
