import React from 'react';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import './Common.scss';
import { ReactComponent as RefreshIcon } from '../../assests/icons/refresh.svg';

function RefreshButton({ onClick }) {
  return (
    <div id="common__refresh__container">
      <Button
        variant="outlined"
        onClick={onClick}
        id="common__refresh__button"
      >
        <RefreshIcon id="common__refresh__icon" />
      </Button>
    </div>
  );
}

export default RefreshButton;

RefreshButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};
