/* eslint-disable no-case-declarations */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import CommonTabHeader from '../CommonTabHeader';
import GenericComponent, { recursivelyIterateAndClearEmptyNodes } from './utils';
import { MissingRecordComponent } from '../../Common';

function Panel({
  index, moduleId, selectedAttemptIndex, item, createdAt, status,
}) {
  const components = get(item, 'endpointModule.components');
  const responseData = get(item, 'extractedData');
  const additionalMetaData = get(item, 'extractedAdditionalMetaData', {});
  const requestData = get(item, ['extractedReqData', 'body']);
  const maxCardHeight = get(item, ['endpointModule', 'maxCardHeight'], '');
  const fileUrls = get(item, ['fileUrls'], []);

  const panelId = `${moduleId}_generic_card_v2_panel_${index}`;
  const isMissing = item?.isMissing || false;

  useEffect(() => {
    recursivelyIterateAndClearEmptyNodes(panelId);
  }, [panelId]);

  return (
    <>
      <div
        style={{
          maxHeight: maxCardHeight,
        }}
        role="tabpanel"
        hidden={selectedAttemptIndex !== index}
        className="generic_card_v2_panel"
        id={`generic_common_card_${moduleId}_tabpanel-${index}`}
        aria-labelledby={`generic_common_card_${moduleId}_tab-${index}`}
      >
        <div id={panelId} className="generic_common_card__tab__container">
          {isMissing ? <MissingRecordComponent /> : (
            <>
              <CommonTabHeader status={status} createdAt={createdAt} />
              {components.map(
                (component, idx) => (
                  <GenericComponent
                    key={component?.id}
                    componentConfig={component}
                    responseData={responseData}
                    requestData={requestData}
                    additionalMetaData={additionalMetaData}
                    fileUrls={fileUrls}
                    moduleId={moduleId}
                    index={index}
                    elementKey={`${panelId}_component_${idx}`}
                  />
                ),
              )}
            </>
          )}
        </div>
      </div>

    </>
  );
}

Panel.defaultProps = {
  status: '',
  createdAt: '',
};

Panel.propTypes = {
  moduleId: PropTypes.string.isRequired,
  item: PropTypes.object.isRequired,
  selectedAttemptIndex: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  status: PropTypes.string,
  createdAt: PropTypes.string,
};

export default Panel;
