import { keys, values } from 'lodash';
import * as Sentry from '@sentry/react';

export const executeStringFunc = (params, functionAsString) => {
  try {
    // eslint-disable-next-line no-new-func
    return new Function(...keys(params), `${functionAsString}`)(...values(params));
  } catch (error) {
    Sentry.captureException(error);
    console.log(error, 'Error in executing string function');
    return null;
  }
};

export const evaluateCondition = (params, condition) => executeStringFunc(params, `return ${condition}`);

// Evaluate if any conditions pass and return data
export const evaluateConditionData = (params, conditionData) => {
  let evaluatedVal;
  let foundPassingCondition = false;
  conditionData.some(({ condition, value }) => {
    try {
      const funcVal = executeStringFunc(params, condition);
      if (funcVal) {
        evaluatedVal = value;
        foundPassingCondition = true;
        return true;
      }
    } catch (err) {
      Sentry.captureException(err);
      console.log('error Evaluating Condition', err);
    }
    return false;
  });
  return { value: evaluatedVal, foundPassingCondition };
};
